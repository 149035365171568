import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Button, Card, Typography } from '@material-ui/core';
import { setOnfidoId } from './actions';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { YoorzAPI } from '../../../YoorzAPI';
import { makeStyles } from '@material-ui/styles';
import { URLDOCVERIFY } from '../../../api';
import { userEmail } from '../../../UserInfo';
import { getAcuantResult } from '../../../API/page6AcuantAPI';

const useStyles = makeStyles({
  root: {
    marginTop: '50px'
  },
});

const Acuant = (props) => {
  const classes = useStyles();

  const [attempts, setAttempts] = React.useState(3);
  const [startMessage, setStartMessage] = React.useState(false);
  const [incorrect, SetIncorrect] = React.useState(false);

  const sendLink = () => {
    try {
      YoorzAPI().post('/url/sms/send', {
        email: userEmail,
        to: props.phone,
        url: URLDOCVERIFY,
        type: "web"
      });
      setStartMessage(true);
      setAttempts(attempts - 1);
    } catch (err) {
      return err;
    }
  };

  const verifyDocUpdate = () => {
    getAcuantResult()
      .then((res) => {

        if (res.Result === 1 && res.Biographic.Age > 17) {
          const Biographic = res.Biographic;

          const db = Biographic.BirthDate.replace("/Date(", "").replace(")/", ""), fullName = Biographic.FullName;
          const dob = new Date(Number(db));

          const result = props.selectedDate.toUTCString().localeCompare() === dob.toUTCString().localeCompare() && fullName.indexOf(props.lastName.toUpperCase()) !== -1;

          const date = new Date();
          const constDate = `${date.getMonth() + 1}`.length === 1 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}/${date.getDate()}`.length === 1 ? `0${date.getDate()}` : `${date.getDate()}/${date.getFullYear()}`;

          if (result) {
            props.setOnfidoId(true, constDate);
            return;
          }

        };
        SetIncorrect(true);
      })
      .catch((err) => {
        console.log(err);
        SetIncorrect(true);
      });
  };

  const rescanLink = () => {
    setAttempts(2); setStartMessage(false); SetIncorrect(false);
  };

  // resend attempt 3
  return (
    <Card className={classes.root}>
      {incorrect ?
        <Grid
          style={{ maxWidth: '1250px' }}
          container
          lg={6}
          md={12}
          xs={12}
          justify="center"
          alignContent="center"
          direction="column"
        >
          <Grid item style={{ padding: '50px' }}>
            <Typography style={{ paddingBottom: '50px' }} variant="h4">Something Went wrong some of the below information could be incorrect</Typography>
            <Typography variant="h5">- Last Name miss match</Typography>
            <Typography variant="h5">- Date of birth miss match</Typography>
            <Typography variant="h5">- Id didn't pass validatoin</Typography>
          </Grid>
          <Grid item justify="center"
            alignContent="center" style={{ textAlign: 'center' }}>
            <Button onClick={rescanLink} style={{ margin: '30px' }} variant='contained' color='primary'>
              Re-Scan Id
            </Button>
            <Button onClick={() => props.history.push('/signin')} style={{ margin: '30px' }} variant='contained' color='primary'>
              Exit App
            </Button>
          </Grid>
        </Grid> :
        <Grid
          style={{ maxWidth: '1250px' }}
          container
          lg={6}
          md={12}
          xs={12}
          justify="center"
          alignContent="center"
          direction="column"
        >
          {startMessage ?
            <>
              <Grid item style={{ padding: '50px' }}>
                <Typography variant="h4">-- Didn't reveive the code, click the resend and we'll send your another message</Typography>
                <Typography variant="h4">-- If your done with uploading click the done button</Typography>
              </Grid>
              <Grid item justify="center"
                alignContent="center" style={{ textAlign: 'center' }}>
                <Button onClick={sendLink} disabled={attempts <= 0} style={{ margin: '30px' }} variant='contained' color='primary'>
                  RESEND
                </Button>
                <Button onClick={verifyDocUpdate} style={{ margin: '30px' }} variant='contained' color='primary'>
                  DONE
                </Button>
              </Grid>
            </> : <>
              <Grid item style={{ padding: '50px' }}>
                <Typography variant="h4">-- Need to be on a mobile device to verify document</Typography>
                <Typography variant="h4">-- No issue, we can send you a sms to your registed mobile {props.phone} to verify your document</Typography>
              </Grid>
              <Grid item justify="center"
                alignContent="center" style={{ padding: '20px', textAlign: 'center' }}>
                <Button onClick={sendLink} style={{ marginTop: '10px', margin: 'auto' }} variant='contained' color='primary'>
                  SEND LINK
            </Button>
              </Grid>
            </>
          }
          <Grid item style={{ padding: '50px', paddtingTop: '50px' }}>
            <Typography style={{ color: 'red' }} variant="h6">* We do not ask for any payment information during the onboard process.</Typography>
            <Typography style={{ color: 'red' }} variant="h6">Please do not provide any card information</Typography>
          </Grid>
        </Grid>
      }
    </Card >
  );
};

Acuant.propTypes = {
  setOnfidoId: PropTypes.func,
  selectedDate: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  history: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    phone: state.rule.page1.phone,
    selectedDate: state.rule.page2.selectedDate,
    firstName: state.rule.page1.firstName,
    lastName: state.rule.page1.lastName,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setOnfidoId: (id, face) => dispatch(setOnfidoId(id, face))
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Acuant);
