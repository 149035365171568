import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  TextField,
  Typography
} from '@material-ui/core';
import { postBlChangePassword, postBlResetPassword } from '../../../../API/SettingsAPI';


const useStyles = makeStyles(() => ({
  root: {}
}));

const Password = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const [isPassMatching, setIsPassMatching] = React.useState(true);
  const [isPassUpdated, setIsPassUpdated] = React.useState(false);

  const [values, setValues] = useState({
    oldPassword: '',
    password: '',
    confirm: ''
  });

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const onHandleUpdate = async () => {
    setIsPassMatching(true);
    if (values.password !== values.confirm) {
      return setIsPassMatching(false);
    } else {
      const status = props.is_reset_password ? await postBlResetPassword(values.password) : await postBlChangePassword(values.oldPassword, values.password);

      if (props.is_reset_password && status) {
        setIsPassUpdated(true);
        props.updateResetStatus("");
      } else if (status) {
        setIsPassUpdated(true);
        setValues({
          oldPassword: '',
          password: '',
          confirm: ''
        });
      }
    }
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <form>
        <CardHeader subheader='Update password' title='Password' />
        <Divider />
        <CardContent>
          {!props.is_reset_password && <TextField
            fullWidth
            label='oldPassword'
            name='oldPassword'
            onChange={handleChange}
            type='password'
            value={values.oldPassword}
            variant='outlined'
          />}
          <TextField
            fullWidth
            label='Password'
            name='password'
            onChange={handleChange}
            style={{ marginTop: '1rem' }}
            type='password'
            value={values.password}
            variant='outlined'
          />
          <TextField
            fullWidth
            label='Confirm password'
            name='confirm'
            onChange={handleChange}
            style={{ marginTop: '1rem' }}
            type='password'
            value={values.confirm}
            variant='outlined'
          />
          {!isPassMatching && (
            <Typography color='red' variant='h6'>
              Password and Confirm password should be same
            </Typography>
          )}
          {isPassUpdated && (
            <Typography color='primary' variant='h6'>
              Password updated successfully
            </Typography>
          )}
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            disabled={
              props.is_reset_password ? (!values.password || !values.confirm) : (!values.oldPassword || !values.password || !values.confirm)
            }
            color='primary'
            variant='contained'
            onClick={onHandleUpdate}
          >
            Update
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

Password.defaultProps = {
  is_reset_password: false
};

Password.propTypes = {
  className: PropTypes.string,
  is_reset_password: PropTypes.bool,
  updateResetStatus: PropTypes.func
};

export default Password;
