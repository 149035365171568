import React from 'react';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Avatar, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  avatar: {
    width: 60,
    height: 60,
    '&.MuiAvatar-img': {
      width: '50% !important',
    }
  },
  name: {
    marginTop: theme.spacing(1)
  }
}));

const Profile = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  let user = {
    bio: ''
  };

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Avatar
        alt="Person"
        className={classes.avatar}
        component={RouterLink}
        src={props.imageUrl ? props.imageUrl : null}
        to="/settings"
      />
      <Typography
        className={classes.name}
        variant="h4"
      >
        {`${props.firstname} ${props.lastname}`}
      </Typography>
      <Typography variant="body2">{user.bio}</Typography>
    </div>
  );
};

Profile.prototype = {
  className: PropTypes.string,
  firstName: PropTypes.string,
  lastname: PropTypes.string,
  imageUrl: PropTypes.string
};

const mapStateToProps = state => {
  return {
    firstname: state.rule.page1.firstName,
    lastname: state.rule.page1.lastName,
    imageUrl: state.rule.page5.imageUrl
  };
};

export default connect(mapStateToProps)(Profile);
