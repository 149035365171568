import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Button } from '@material-ui/core';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import IdRnD from '../Verification/components/IdRnD';
import { fetchUserPageInfo } from '../../YoorzAPI';
import { getBlPage5 } from '../../API/page5IdrndAPI';
import { setOnFidoImageUrl, setImageCaptureDate } from '../../views/Verification/components/actions';
import { AccountProfile, AccountDetails } from './components';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const Account = (props) => {
  const classes = useStyles();

  const [imageCaptureForm, setImageCaptureForm] = React.useState('');

  const updatingImageCaptureMode = (status) => setImageCaptureForm(status);

  const updateNewImage = () => {
    getBlPage5()
      .then((res) => {
        if (res) {
          props.setOnFidoImageUrl(res.imageurl);
          props.setImageCaptureDate(res.onfidoid);
        }
      })
      .catch((err) => {
        props.history.push('/signin');
      });
    setImageCaptureForm('');
  };
  return (
    <div className={classes.root}>
      {!imageCaptureForm && (
        <Grid container spacing={4}>
          <Grid item lg={4} md={6} xl={4} xs={12}>
            <AccountProfile
              userInfo={props.userInfo}
              firstName={props.firstName}
              lastName={props.lastName}
              imageUrl={props.imageUrl}
              imageCaptureMode={updatingImageCaptureMode}
            />
          </Grid>
          <Grid item lg={8} md={6} xl={8} xs={12}>
            <AccountDetails
              userInfo={props.userInfo}
              firstName={props.firstName}
              lastName={props.lastName}
              phone={props.phone}
              email={props.email}
            />
          </Grid>
        </Grid>
      )}
      {imageCaptureForm && (
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} xl={12} xs={12}>
            {imageCaptureForm === 'camera' ? <IdRnD /> : <div>Yet to come</div>}
          </Grid>
          <Grid item>
            <Button onClick={() => updateNewImage()} variant="contained" color="primary">
              Back
						</Button>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

Account.propTypes = {
  className: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  userInfo: PropTypes.object,
  imageUrl: PropTypes.string,
  email: PropTypes.string
};

const mapStateToProps = (state) => {
  return {
    firstName: state.rule.page1.firstName,
    lastName: state.rule.page1.lastName,
    phone: state.rule.page1.phone,
    email: state.rule.page1.email,
    selectedDate: state.rule.page2.selectedDate,
    converusState: state.rule.page3.status,
    tracking: state.rule.page4.tracking,
    userInfo: state.rule.page2.userInfo,
    imageUrl: state.rule.page5.imageUrl
  };
};

const mapDispatchToProps = (dispatch) => ({
  setOnFidoImageUrl: (url) => dispatch(setOnFidoImageUrl(url)),
  setImageCaptureDate: (date) => dispatch(setImageCaptureDate(date))
});

export default connect(mapStateToProps, mapDispatchToProps)(Account);
