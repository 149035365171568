import axios from 'axios';
import { EMAIL_VERIFY_URL, onboardSetUser, signInBlockAPI } from '../api';
import { setBlToken, setBlAdress, blAddress } from '../UserInfo';
import { YoorzBlockAPI, YoorzAPI } from '../YoorzAPI';

export const postVerifyEmail = async email => {
  await onboardSetUser(email);
  const response = await YoorzAPI().post("/verify/email", {
    email: email
  })
  if (response.data.msg === "Valid") {
    return true
  }
  return false
};

export const postBlMobileRegister = async email => {
  const response = await signInBlockAPI.post('/users/mobileregister', {
    email: email
  });
  if (response.data.message) {
    setBlToken(response.data.message.Token);
    setBlAdress(response.data.message.UserAddress);
    return true;
  }
  return false;
};

export const postBlPage1 = async info => {
  const response = await YoorzBlockAPI().post('/page1/addpage1/', {
    ...info,
    address: blAddress,
  });
  if (response.data) {
    return response.data;
  }
  return false;
};


export const getBlPage1 = async () => {
  try {
    const response = await YoorzBlockAPI().post('/page1/getpage1/', {
      address: blAddress,
    });
    if (response.data.address !== '0x0000000000000000000000000000000000000000') {
      return response.data;
    }
    return false;
  } catch (err) {
    return false;
  }
};