export const LOGIN_SUCCESS = "login_success";

export const LOGIN_FAILURE = "login_failure";

export const UPDATE_PROFILE_INFO = "update_profile_info";

export const UPDATE_PROFILE_FAILURE = "update_profile_failure";

export const LOGOUT_SUCCESS = "logout_success";

export const VERIFY_PROCESS = "VERIFY_PROCESS";
