import * as Constants from './constants';

export const loginSuccess = (phone) => {
	return {
		type: Constants.LOGIN_SUCCESS,
		phone
	};
};

export const verifyProcess = () => {
	return {
		type: Constants.VERIFY_PROCESS
	};
};

export const loginFailure = (error) => {
	return {
		type: Constants.LOGIN_FAILURE,
		error
	};
};

export const logoutSuccess = () => {
	return {
		type: Constants.LOGOUT_SUCCESS
	};
};
