import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/styles';
import {
  Facebook,
  Twitter,
  Pinterest,
  Google,
  Tumblr,
  Email
} from 'react-sharingbuttons';
import 'react-sharingbuttons/dist/main.css';

import { Card, CardContent, Grid, Button, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
    width: 'auto',
    height: '216px'
  }
}));

const SocialShare = () => {
  const classes = useStyles();

  const url = 'https://yoorz.me';
  const shareText = 'Verify Yourself in Yoorz!';

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography variant="h3" container="h6">
          Share
        </Typography>
        <br />
        <Grid container>
          <Grid item>
            <Email url={url} />
            <Tumblr url={url} />
            <Facebook url={url} />

            <Twitter url={url} shareText={shareText} />

            <Google url={url} />

            <Pinterest url={url} shareText={shareText} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

SocialShare.propTypes = {
  className: PropTypes.string
};
const mapStateToProps = state => {
  return {
    firstName: state.rule.page1.firstName,
    lastName: state.rule.page1.lastName,
    formState: state.rule.form.status,
    selectedDate: state.rule.page2.selectedDate,
    email: state.rule.page1.email,
    values: state.rule.page2.userInfo,
    phone: state.rule.page1.phone
  };
};
export default connect(mapStateToProps)(SocialShare);
