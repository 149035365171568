import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, CardHeader, Divider, Button, Grid, TextField, Typography } from '@material-ui/core';
import { setPasswordSuccess } from './actions';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import PasswordStrengthBar from 'react-password-strength-bar';
import { postBlResetPassword, postBlSendActivationEmail } from '../../../API/page7PasswordAPI';

const useStyles = makeStyles((theme) => ({
  root: {},
  details: {
    display: 'flex'
  },
  textField: {
    width: '260px',
    float: 'left'
  },
  extendedtextField: {
    width: '365px'
  },
  button: {
    marginTop: '7px',
    height: '40px',
    marginLeft: '5px',
    width: '100px'
  }
}));

const Password = (props) => {
  const classes = useStyles();
  const { className, ...rest } = props;
  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [showPass, setShowPass] = React.useState(0);
  const [isSubmitDisabled, setIsSubmitDisabled] = React.useState(true);
  const [verifySocialLogin, setVerifySocialLogin] = React.useState(false);
  const [passwordScore, setPasswordScore] = React.useState(0);

  React.useEffect(
    () => {
      if (props.social) {
        loginType();
      }
      if (password && passwordScore > 1 && password === confirmPassword) {
        return setIsSubmitDisabled(false);
      }
      return setIsSubmitDisabled(true);
    },
    [password, confirmPassword, props.social]
  );

  const loginType = () => {
    const google = Object.keys(props.social.googleResponse);
    const fb = Object.keys(props.social.facebookResponse);
    const tw = Object.keys(props.social.twitterResponse);
    if (google.length || fb.length || tw.length) {
      setVerifySocialLogin(true);
    }
  };

  const handlePassword = (event) => {
    event.preventDefault();
    setPassword(event.target.value);
  };

  const handlePasswordConfirm = (event) => {
    event.preventDefault();
    setConfirmPassword(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();


    const signUpStatus = await postBlResetPassword(password);
    if (signUpStatus) {
      props.setPasswordSuccess();
      const sendMail = await postBlSendActivationEmail();
      console.log(sendMail);
      if (sendMail) {
        console.log(' In if condition');

        props.history.push('/signin');
      }
    }
  };

  const handleVisibility = (event) => {
    event.preventDefault();
    setShowPass(!showPass);
  };

  return (
    <Card className={classes.root} style={{ marginTop: '50px' }}>
      <CardHeader subheader="Please confirm your password" title="Password Setup" />
      <Divider />
      <CardContent>
        <Grid container spacing={3} xs={12} alignContent={'center'} direction={'column'}>
          {verifySocialLogin ? (
            <Grid item xs={12}>
              <Typography variant="h3" color="primary">
                Please proceed to submit and login using Social Login options to access dashboard once
                activated using your email.
							</Typography>
            </Grid>
          ) : (
            <>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Password"
                  subheader="Password"
                  margin="dense"
                  name="Password"
                  required
                  value={password}
                  variant="outlined"
                  type={showPass ? 'text' : 'Password'}
                  onChange={handlePassword}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end" onClick={handleVisibility}>
                          {showPass ? (
                            <VisibilityIcon style={{ color: '#3f51b5' }} />
                          ) : (
                            <VisibilityOffIcon style={{ color: '#3f51b5' }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  style={{ justifyContent: 'center' }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Confirm"
                  subheader="Confirm"
                  margin="dense"
                  name="Confirm"
                  required
                  value={confirmPassword}
                  variant="outlined"
                  type={showPass ? 'text' : 'Password'}
                  onChange={handlePasswordConfirm}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end" onClick={handleVisibility}>
                          {showPass ? (
                            <VisibilityIcon style={{ color: '#3f51b5' }} />
                          ) : (
                            <VisibilityOffIcon style={{ color: '#3f51b5' }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  style={{ justifyContent: 'center' }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <PasswordStrengthBar password={password} onChangeScore={score => setPasswordScore(score)
                } />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <center>
              <Button
                variant="contained"
                color="primary"
                disabled={isSubmitDisabled}
                className={classes.button}
                onClick={handleSubmit}
              >
                Submit
							</Button>
            </center>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
    </Card>
  );
};

Password.prototype = {
  history: PropTypes.object.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  userInfo: PropTypes.object.isRequired,
  onFido: PropTypes.string.isRequired,
  tracking: PropTypes.string.isRequired,
  bgNumber: PropTypes.string.isRequired,
  criminal: PropTypes.string.isRequired,
  judgment: PropTypes.string.isRequired,
  bankruptcy: PropTypes.string.isRequired,
  setPasswordSuccess: PropTypes.func,
  social: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  return {
    firstName: state.rule.page1.firstName,
    lastName: state.rule.page1.lastName,
    phone: state.rule.page1.phone,
    email: state.rule.page1.email,
    selectedDate: state.rule.page2.selectedDate,
    userInfo: state.rule.page2.userInfo,
    examId: state.rule.page2.examId,
    onFido: state.rule.page5.onFidoId,
    tracking: state.rule.page4.tracking,
    bgNumber: state.rule.page4.bgNumber,
    criminal: state.rule.page4.criminal,
    bankruptcy: state.rule.page4.bankruptcy,
    judgment: state.rule.page4.judgment,
    social: state.social
  };
};

const mapDispatchToProps = (dispatch) => ({
  setPasswordSuccess: () => dispatch(setPasswordSuccess())
});
export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Password);
