import { YoorzAPI, YoorzBlockAPI } from '../YoorzAPI';
import { userEmail, setBlAdress, setBlToken } from '../UserInfo';

export const postTwilioPhoneSendCode = async (phone) => {
  let plusOne = phone;
  try {
    if (plusOne.substring(0, 2) !== '+1') {
      plusOne = `+1${plusOne}`;
    }
    const twilioSid = await YoorzAPI().post('/sms/send', {
      email: userEmail,
      to: plusOne
    });
    if (twilioSid.data.status === 'pending') {
      return twilioSid.data.sid;
    }
    return false;
  } catch (err) {
    return err;
  }
};

export const postTwilioPhoneSendVerify = async (sid, code) => {
  try {
    const twilioVerify = await YoorzAPI().post('/sms/verify', {
      email: userEmail,
      verificationSid: sid,
      vcode: code
    });
    if (twilioVerify.data.status === 'approved') {
      return true;
    }
    return false;
  } catch (err) {
    return err;
  }
};

export const getUserEmailId = async (phone) => {
  try {
    const info = await YoorzBlockAPI().get(`/users/forgotcred?phonenumber=${phone}`);
    if (info.data.msg !== "Please send either an email or phone number") {
      return info.data;
    }
    return false;
  } catch (err) {
    return false;
  }
};

export const getUserPhoneInfo = async (email) => {
  try {
    const info = await YoorzBlockAPI().get(`/users/forgotcred?email=${email}`);
    if (info.data.msg !== "Cannot find the phone number with that email address.") {
      setBlToken(info.data.token); setBlAdress(info.data.address);
      return info.data;
    }
    return false;
  } catch (err) {
    return false;
  }
};