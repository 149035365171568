import { onboardSetUser, signInBlockAPI } from '../api';
import { setBlToken, setBlAdress } from '../UserInfo';

export const userSignIn = async info => {
  try {
    const status = await signInBlockAPI.post('/users/mobilelogin', {
      email: info.email, password: info.password
    });
    if (status.data.message && status.data.message.Token) {
      setBlToken(status.data.message.Token);
      setBlAdress(status.data.message.UserAddress);

      const onboardStatus = await onboardSetUser(info.email);
      if (onboardStatus) {
        return true;
      }
      return false;
    }
    return status.data;
  } catch (err) {
    return err;
  }
};
