import axios from 'axios';

export const postLinkedinLogin = async () => {
  const options = {
    mode: 'cors',
    method: 'POST',
    url: 'https://www.linkedin.com/oauth/v2/accessToken',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*'
    },
    data: {
      grant_type: 'client_credentials',
      client_id: '86vc3f1072ohun',
      client_secret: 'Nbvh9yQmJcXAIbd9'
    }
  };
  const login = await axios(options);
  console.log(login);
  return login;
};
