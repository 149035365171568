import axios from 'axios';
import { getCookie, URL, BLOCKURL } from './api';
import { apiToken, blToken } from './UserInfo';

export const YoorzAPI = () => axios.create({
  baseURL: URL,
  mode: 'cors',
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    Authorization: `Bearer ${apiToken}`
  }
});

export const YoorzBlockAPI = () => axios.create({
  baseURL: BLOCKURL,
  mode: 'cors',
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    Authorization: blToken
  }
});

export const postRegisterMobile = async email => {
  try {
    const info = await YoorzBlockAPI().post(`/users/mobileregister`, {
      email: email, password: "!communicatewithconfidence"
    });
    console.log(info);
    if (info.data.token) {
      return info.data;
    }
  } catch (err) {
    return err;
  }
};

//export const fetchUserPageInfo = async (pageId) => {
//  try {
//    const info = await YoorzAPI().post(`/fetch?pageid=${pageId}`, {
//      email: getCookie('yoorz-email')
//    });
//    if (info.data.page) {
//      return info.data;
//    }
//    return false;
//  } catch (err) {
//    return err;
//  }
//};

//export const postAnExistingUser = async (phone) => {
//  try {
//    const info = await YoorzAPI().post(`/cred?option=user`, {
//      email: getCookie('yoorz-email'),
//      phone: phone
//    });
//    if (info.data.email) {
//      return info.data;
//    }
//  } catch (err) {
//    return err;
//  }
//};

export default YoorzAPI;
