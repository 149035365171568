import axios from 'axios';
import { blAddress, userEmail } from '../UserInfo';
import { YoorzBlockAPI, YoorzAPI } from '../YoorzAPI';
import { ZIP_VERIFY } from '../api';

export const postBlPage2 = async (info) => {
  const data = {
    address: blAddress,
    streetaddress: `${info.address1}, ${info.address2}`,
    city: info.city,
    stateandzip: `${info.state}-${info.zip.substring(0, 5)}`,
    gender: info.gender,
    martialstatus: info.marritalStatus,
    dob: info.dob
  };
  try {
    const page2 = await YoorzBlockAPI().post('/page2/addpage2', {
      ...data
    });
    if (page2.data.streetaddress) {
      return true;
    }
    return false;
  } catch (err) {
    return err;
  }
};

export const postLocateAddress = async info => {
  try {
    const status = await YoorzAPI().post('/locate', {
      email: userEmail,
      location: `${info.address1},${info.city},${info.state}`
    });
    if (status.data.lat && status.data.long) {
      return status.data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const postFindZip = async zip => {
  try {
    const options = {
      method: 'POST',
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
        'Access-Control-Allow-Origin': '*'
      },
      data: {
        zip: zip
      },
      url: ZIP_VERIFY,
    };
    const status = await axios(options);
    if (status.data.zip5) {
      return status.data;
    }
    return false;
  } catch (err) {
    return false;
  }
};

export const getBlPage2 = async () => {
  try {
    const page2 = await YoorzBlockAPI().post('/page2/getpage2', {
      address: blAddress,
    });
    if (page2.data.address !== '0x0000000000000000000000000000000000000000') {
      return page2.data;
    }
    return false;
  } catch (err) {
    return false;
  }
};