import React from 'react';
import { ListItem, ListItemText, ListItemAvatar, Typography, Avatar } from '@material-ui/core';
import { getUsers } from '../../../../API/ChatApi';
import Loading from '../../../Loading';


export default class ChannelWindow extends React.Component {
  constructor() {
    super();
    this.state = {
      channelLoaded: false,
      channelView: '',
      channels: []
    };
  }

  async componentDidMount() {
    try {
      await this.getChannels();
      this.setState({ channelLoaded: true });
    }
    catch (err) {
      this.setState({ channelLoaded: false });

    }
  }

  getLocalTime = (timestamp) => {
    const dt = new Date(timestamp);
    const hours = dt.getHours();
    const min = dt.getMinutes();
    const str = (hours >= 12) ? 'PM' : 'AM';
    const nhour = (hours % 12) || 12;
    return `${(nhour < 10) ? '0' : ''}${nhour}:${(min < 10) ? '0' : ''}${min} ${str}`;
  };

  async getChannels() {
    const client = this.props.channelInit.chatClient;
    var chl_arr = [];
    try {

      const paginator = await client.getSubscribedChannels();
      // const paginator= await client.getUserChannelDescriptors();
      for (var i = 0; i < paginator.items.length; i++) {
        const channel = paginator.items[i];
        var item = {};
        item.key = channel.uniqueName;
        var lastReadIndex = channel.lastConsumedMessageIndex;
        if (lastReadIndex == null) {
          lastReadIndex = -1; //for initial msg
        }
        const page = await channel.getMessages(1);
        item.unread = 0;  //setting initial value incase new channel
        item.lastMsg = ''; //setting initial value incase new channel,will skip forloop
        for (var j = 0; j < page.items.length; j++) {
          const latest = page.items[j].index;
          item.unread = `${latest - lastReadIndex}`;

          item.lastMsg = `${page.items[j].body}`;
          item.timestamp = `${this.getLocalTime(page.items[j].timestamp)}`;
        }

        if (channel.friendlyName === 'General') {
          item.name = channel.friendlyName;
          item.initials = "GL";
          item.image = 'https://yoorz-icon.s3.amazonaws.com/SpinnerIcon.png';
        } else {
          const usr_email = this.props.user.email;
          var active_users;
          do {
            active_users = await getUsers();
          } while (active_users === 'try again');
          const users = active_users;
          if (users && users.length != 0) {
            const mem_list = await channel.getMembers();
            for (var k = 0; k < mem_list.length; k++) {
              const mem = JSON.parse(mem_list[k].identity);
              if (mem.email != usr_email) {
                item.name = `${mem.firstName} ${mem.lastName}`;
                item.initials = `${mem.firstName[0]}${mem.lastName[0]}`;
                for (var l in users) {
                  if (users[l].email === mem.email) {
                    item.image = `${users[l].imageurl}`;  //needs change probably no imageToken
                    break;
                  }
                }
              }
            }
          }
        }
        if (item.name)
          chl_arr.push(item);
      }
      this.setState({ channels: chl_arr });
    } catch (err) {
      console.log(err);
      this.setState({ channelLoaded: false });

    }

  }

  render() {
    if (this.state.channelLoaded) {
      const { channels } = this.state;
      const channelss = [...channels, ...channels, ...channels];
      console.log(channelss);
      return (channelss.map((channel, index) =>
        (<ListItem
          onClick={() => {
            console.log(`${channel.name} Clicked`);
            this.props.updateChannelInfo({
              channel: channel,
            });
          }}
          style={{ borderBottom: '1px solid #000' }}
          key={`channel-${index}`}
        >
          <ListItemAvatar>
            {!channel.image ? (
              <Avatar alt={channel.initials} src={channel.image} />
            ) : (
                <Avatar style={{ backgroundColor: '#4e34c7' }}>{channel.initials}</Avatar>
              )}
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography style={{}}>
                {channel.name}
              </Typography>
            }
          />
          {channel.unread ? (
            <Avatar
              style={{
                marginTop: '5px',
                fontSize: '9px',
                width: '20px',
                height: '20px',
                backgroundColor: '#000',
                color: '#fff'
              }}
            >
              {channel.unread}
            </Avatar>
          ) : null}
        </ListItem>)));
    }

    return <Loading />;
  }
}