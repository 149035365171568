import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import logo2 from '../assets/images/IdentityDetect.png';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Divider,
  Grid,
  Radio,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Button
} from '@material-ui/core';
//import { startExam, endExam, startQuestion, answerQuestion, endQuestion } from './TruthDetectCapture';
import { v4 as uuidv4 } from 'uuid';
import { CONVERUE_REQUEST } from './constants';
import Loading from '../../Loading';
import { postGetConverusKBAQuestions, postGetConverusKBAResults, postBlPage3 } from '../../../API/page3ConverusAPI';
import { setConverusCompleted } from './actions';
import { complexQuestionsUtil } from './converusComplexUtil'

const useStyles = makeStyles({
  root: {
    marginTop: '25px'
  },
  cardBody: {
    width: '100%'
  },
  textField: {
    float: 'left',
    width: '85%'
  },
  formControlContent: {
    textAlign: 'center',
    display: 'block',
    marginLeft: '80px',
    marginRight: '50px'
  },
  formControlButton: {
    marginTop: '50px',
    textAlign: 'right'
  },
  submitButton: {
    marginTop: '15px',
    textAlign: 'center'
  },
  disableContent: {
    pointerEvents: 'none',
    opacity: '0.5',
    textAlign: 'center',
    display: 'block'
  }
});

let coordinates;
let timer;
let sample = [];
const items = [];
const userSample = [];

const ConverusComplex = (props) => {
  const classes = useStyles();

  const [radioSelection, setRadioSelection] = React.useState(-1);
  const [submit, setSubmit] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  // const [userSamples, setUserSamples] = React.useState([]);
  const [singleQuestion, setSingleQuestion] = React.useState(false);
  const [isTimeout, setIsTimeout] = React.useState(false);
  const [exitApp, setExitApp] = React.useState(false);

  // const question = sample.length && items.length < sample.length ? sample[items.length] : {};

  const getCoordinateFunction = (event) => (coordinates = `${event.pageX},${event.pageY}`);

  const initializeTimer = (questionType) => {
    let second = null
    if (questionType === 'Simple') {
      second = 10000
    } else if (questionType[0] === 'C') {
      second = 14000
    }
    timer = setTimeout(() => {
      setIsTimeout(true)
      timeoutScreen()
    }, second);
  }

  const stopTimer = () => {
    clearTimeout(timer);
    timer = null;
  }


  const timeoutScreen = () => {
    setTimeout(() => {
      setIsTimeout(false);
      handleContinue({ pageX: "", pageY: "" })
    }, 2000)
  }

  useEffect(() => {
    setLoading(true);
    const data = complexQuestionsUtil(props);

    document.addEventListener('click', getCoordinateFunction);

    postGetConverusKBAQuestions(data)
      .then((res) => {

        const questionList = [...res, ...props.ComplexQuestions];
        sample = questionList;
        userSample.push({
          at: 'ES',
          ts: new Date().getTime(),
          id: '',
          dv: '',
          qs: '',
          it: '',
          ii: '',
        },
          {
            at: 'QS',
            ts: new Date().getTime(),
            id: questionList[items.length].QuestionId,
            dv: '',
            qs: '1',
            it: questionList[items.length].QuestionClassification,
            ii: 'Radio',
          });
        // start(questionList[items.length].QuestionClassification);
        initializeTimer(questionList[items.length].QuestionClassification)
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });

    return () => {
      document.removeEventListener('click', getCoordinateFunction, false);
    };
    // eslint-disable-next-line
  }, []);


  const handleRadioChange = (event) => {
    setRadioSelection(event.target.value);

    userSample.push({
      at: 'KD',
      ts: new Date().getTime(),
      id: sample[items.length].QuestionId,
      dv: coordinates,
      qs: '1',
      it: sample[items.length].QuestionClassification,
      ii: 'Radio',
    });
  };

  const correctStatus = () => {
    if (radioSelection !== -1) {
      return radioSelection === sample[items.length].ExpectedAnswerId ? 1 : 0;
    }
    return 0;
  };

  // submit current answer
  const handleSubmitContinue = event => {
    stopTimer()
    userSample.push({
      at: 'KU',
      ts: new Date().getTime(),
      id: sample[items.length].QuestionId,
      dv: `${event.pageX},${event.pageY}`,
      qs: '1',
      it: sample[items.length].QuestionClassification,
      ii: 'Radio',
    });
    setSingleQuestion(true);
  };

  // next Question
  const handleContinue = (event) => {
    const item = {
      correct: correctStatus(),
      id: sample[items.length].QuestionId
    }

    userSample.push({
      at: 'QF',
      ts: new Date().getTime(),
      id: sample[items.length].QuestionId,
      dv: `${event.pageX},${event.pageY}`[0] === ',' ? "" : `${event.pageX},${event.pageY}`,
      qs: '1',
      it: sample[items.length].QuestionClassification,
      ii: 'Radio',
    });
    items.push(item);

    if (items.length === sample.length) {
      setSubmit(false);
    } else {
      userSample.push({
        at: 'QS',
        ts: new Date().getTime(),
        id: sample[items.length].QuestionId,
        dv: '',
        qs: '1',
        it: sample[items.length].QuestionClassification,
        ii: 'Radio',
      });
      initializeTimer(sample[items.length].QuestionClassification)
    }

    setSingleQuestion(false);
    setRadioSelection(-1);
  };

  const handleSubmit = () => {
    setLoading(true);
    //const values = Object.values(window.sessionStorage);
    userSample.push({
      at: 'EF',
      ts: new Date().getTime(),
      id: "",
      dv: "",
      qs: "",
      it: "",
      ii: "",
    });

    const data = CONVERUE_REQUEST;
    data.examInfo.examId = uuidv4();
    data.examInfo.examineeId = props.email;
    data.examResponse.sessions[0].items = items;
    data.examSamples = userSample;

    postGetConverusKBAResults(data)
      .then(async (res) => {
        setLoading(false);
        if (res.examScore >= 0.5 && res.examScore.indexOf("E") === -1) {
          var currDate = new Date();
          currDate = currDate.getMonth() + 1 + "/" + currDate.getDate() + "/" + currDate.getFullYear();
          await postBlPage3({
            examid: data.examInfo.examId,
            examurl: props.email,
            examresult: res.examScore,
            examdate: currDate
          });
          return props.setConverusCompleted({ status: true, ...res });
        }
        setExitApp(true);
        return props.setConverusCompleted({
          status: false, examUrl: null,
          examScore: null,
          examInfo: {
            examineeId: null,
            examId: null
          }
        });
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };


  const displayLabel = (questionOptions) =>
    questionOptions.map((options) => (
      <FormControlLabel
        key={`question-${options.AnswerId}`}
        value={options.AnswerId}
        control={<Radio color="primary" />}
        label={options.AnswerText}
      />
    ));

  const ConverusIIContainer = () => (
    <Grid container className={classes.root}>
      <Card className={classes.cardBody}>
        <CardActionArea style={{ display: 'flex' }}>
          <CardMedia
            component="img"
            image={logo2}
            height="60px"
            style={{ width: '243px' }}
            alignSelf={'center'}
          />
        </CardActionArea>
        <Divider />
        <CardContent id="content">
          {sample.length &&
            submit && (
              <Grid container spacing={3}>
                {isTimeout ?
                  (
                    <Grid item lg={12} md={12} xs={12}>
                      <Typography style={{ color: 'red', textAlign: 'center' }}>Timeout expired</Typography>
                    </Grid>)
                  : (
                    <>
                      <Grid item lg={12} md={12} xs={12} className={radioSelection === -1 ? classes.formControlContent : classes.disableContent}>
                        <FormControl component="fieldset">
                          <FormLabel>
                            <Typography variant="h5">{sample[items.length].QuestionLabel}</Typography>
                          </FormLabel>
                          <RadioGroup
                            defaultValue=""
                            value={radioSelection}
                            onChange={handleRadioChange}
                            aria-label={sample[items.length].QuestionId}
                            name="customized-radios"
                          >
                            {displayLabel(sample[items.length].PossibleAnswers)}
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid item lg={12} md={12} xs={12} className={singleQuestion ? classes.submitButton : classes.formControlButton}>
                        {singleQuestion ? (<Button color="primary" variant="contained" onClick={handleContinue}>
                          Next
                        </Button>) : (<Button color="primary" variant="contained" onClick={handleSubmitContinue}>
                          Submit
                        </Button>)}
                      </Grid>
                    </>)}
              </Grid>
            )}
          {!submit &&
            !props.converusState && (
              <Grid container spacing={3}>
                {exitApp ?
                  (<Grid item md={12} xs={12} className={classes.submitButton}>
                    <Typography color="primary">Verification Incomplete</Typography>
                    <br />
                    <Typography color="primary">We apologize the verification process could not be completed. \n\nWe do not store any of your personal information and for security protection you will need to restart the process from the beginning.</Typography>
                    <br />
                    <Typography color="primary">Thank you</Typography>
                    <br />
                    <Typography color="primary">Yoorz Team</Typography>
                    <Button color="primary" variant="contained" onClick={() => props.history.push('/signin')}>Exit App</Button>
                  </Grid>) :
                  (<Grid item lg={12} md={12} xs={12} className={classes.submitButton}>
                    <Button color="primary" variant="contained" onClick={handleSubmit}>
                      Submit Answers
								    </Button>
                  </Grid>)}
              </Grid>
            )}
        </CardContent>
        <Divider />
      </Card>
    </Grid >
  );

  return (
    <Grid container spacing={3} justify="center" alignItems="center">
      {loading ? <Loading /> : ConverusIIContainer()}
    </Grid>
  );
};

ConverusComplex.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  userInfo: PropTypes.object.isRequired,
  selectedDate: PropTypes.string.isRequired,
  setConverusCompleted: PropTypes.func.isRequired,
  converusState: PropTypes.bool.isRequired,
  history: PropTypes.object
};

const mapStateToProps = (state) => {
  return {
    firstName: state.rule.page1.firstName,
    lastName: state.rule.page1.lastName,
    phone: state.rule.page1.phone,
    email: state.rule.page1.email,
    userInfo: state.rule.page2.userInfo,
    selectedDate: state.rule.page2.selectedDate,
    converusState: state.rule.page3.status
  };
};

const mapDispatchToProps = (dispatch) => ({
  setConverusCompleted: (status) => dispatch(setConverusCompleted(status))
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ConverusComplex);
