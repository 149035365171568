export const UPORT_LOGIN_SUCCESS = "uport_login_success";

export const UPORT_LOGIN_FAILURE = "uport_login_failure";

export const FACEBOOK_LOGIN_SUCCESS = "facebook_login_success";

export const FACEBOOK_LOGIN_FAILURE = "facebook_login_failure";

export const GOOGLE_LOGIN_SUCCESS = "google_login_success";

export const GOOGLE_LOGIN_FAILURE = "google_login_failure";

export const TWITTER_LOGIN_SUCCESS = "twitter_login_success";

export const TWITTER_LOGIN_FAILURE = "twitter_login_failure";
