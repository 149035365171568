import React from "react";
import Modal from "react-modal";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Grid, Typography, Button, TextField } from "@material-ui/core";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import OTP from 'react-otp-input';
import { postTwilioPhoneSendCode, postTwilioPhoneSendVerify } from '../../../API/CommonUtilsAPI';

const useStyles = makeStyles({
  textField: {
    width: "35px !important",
    height: "35px !important",
    float: "left",
    marginRight: "10px"
  },
  textFieldRed: {
    width: "35px !important",
    height: "35px !important",
    float: "left",
    marginRight: "10px",
    borderColor: 'red'
  },
  button: {
    height: "30px",
    marginLeft: "5px",
    width: "100px",
    marginTop: "10px"
  },
  clearButton: {
    float: "right"
  },
  didntReceive: {
    float: "left"
  },
  marginButton: {
    marginTop: "20px",
    textAlign: "center"
  },
  typography: {
    marginTop: "10px !important",
    marginBottom: "10px",
    justifySelf: "center",
    width: "200px"
  }
});
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "#fff",
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    height: "220px",
    width: "350px",
    border: "2px solid #3f51b5"
  }
};

Modal.setAppElement("#root");

const PhoneVerificationModal = props => {
  const classes = useStyles();

  const [code, setCode] = React.useState("");
  const [sid, setSid] = React.useState(null);
  const [incorrect, setIncorrect] = React.useState(false);

  React.useEffect(() => {
    handleDidntReceive();
    // eslint-disable-next-line
  }, []);


  const handleCloseModal = async () => {
    const status = await postTwilioPhoneSendVerify(sid, code);
    if (status && props.isModalView) {
      props.handleVerifyCode(status);
      props.closeModal();
    } else if (status) {
      props.handleVerifyCode(status);
    } else {
      setIncorrect(true);
    }

  };

  const handleDidntReceive = () => {
    postTwilioPhoneSendCode(props.phone).then(getSid => setSid(getSid)).catch(err => { });
  };
  const handleClear = () => {
    setCode("");
  };

  const mainView = () => (<Grid container direction="column" justify="center">
    <Grid item style={{ textAlign: 'center', margin: 'auto' }}>
      <Typography
        variant="h6"
        component="h2"
        alignSelf="center"
        className={classes.typography}
      >
        Please enter your code
    </Typography>
    </Grid>
    <Grid item style={props.isModalView ? {
      marginTop: '5px',
      marginBottom: '10px'
    } : { margin: 'auto', marginTop: '30px', marginBottom: '30px' }}>
      <OTP
        inputStyle={incorrect ? classes.textFieldRed : classes.textField}
        value={code}
        onChange={(otp) => setCode(otp)}
        numInputs={6}
        separator={<span>&nbsp;&nbsp;</span>} />
    </Grid>
    <Grid item style={props.isModalView ? {} : { margin: '20px' }}>
      <Button
        size="small"
        className={classes.didntReceive}
        onClick={handleDidntReceive}
        startIcon={<ClearAllIcon />}
      >
        Didn't Receive Code
    </Button>
      <Button
        size="small"
        className={classes.clearButton}
        onClick={handleClear}
        startIcon={<ClearAllIcon />}
      >
        Clear
    </Button>
    </Grid>
    <Grid item className={classes.marginButton}>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={handleCloseModal}
      >
        verify
    </Button>
    </Grid>
  </Grid>);

  return (
    <>
      {props.isModalView ?
        <Modal
          isOpen={props.formState}
          onRequestClose={props.closeModal}
          style={customStyles}
          contentLabel="Verify"
        >
          {mainView()}
        </Modal>
        : mainView()
      }
    </>
  );
};

PhoneVerificationModal.defaultProps = {
  isModalView: true
};
PhoneVerificationModal.prototype = {
  formState: PropTypes.bool.isRequired,
  closeModal: PropTypes.func,
  isModalView: PropTypes.bool,
  phone: PropTypes.string,
  handleVerifyCode: PropTypes.func
};

export default PhoneVerificationModal;
