import React, { Component } from 'react';
import { Grid, Card, Button, Typography } from '@material-ui/core';

const styles = {
	name: {
		verticalAlign: 'top',
		display: 'none',
		margin: 0,
		border: 'none',
		fontSize: '16px',
		fontFamily: 'Helvetica Neue',
		padding: '16px',
		color: '#373F4A',
		backgroundColor: 'transparent',
		lineHeight: '1.15em',
		placeholderColor: '#000',
		_webkitFontSmoothing: 'antialiased',
		_mozOsxFontSmoothing: 'grayscale',
		outline: 'none'
	},
	leftCenter: {
		float: 'left',
		textAlign: 'center'
	},
	blockRight: {
		display: 'block',
		float: 'right'
	},
	center: {
		textAlign: 'center'
	},
	divider: {
		width: '100%',
		border: '1px solid #4e3fe7',
		borderRadius: '5px',
		marginTop: '10px'
	},
	card: {
		padding: '20px 10px'
	}
};

export default class PaymentForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			cardBrand: '',
			nonce: undefined,
			googlePay: true,
			applePay: true,
			plan: null
		};
		this.requestCardNonce = this.requestCardNonce.bind(this);
	}

	requestCardNonce() {
		this.paymentForm.requestCardNonce();
	}

	componentDidMount() {
		const config = {
			applicationId: 'sandbox-sq0idb-wIbnrOni2IFPwR4uWETpMQ',
			locationId: '8HMARV5PMWB24',
			inputClass: 'sq-input',
			autoBuild: false,
			inputStyles: [
				{
					fontSize: '16px',
					fontFamily: 'Helvetica Neue',
					padding: '16px',
					color: '#373F4A',
					backgroundColor: 'transparent',
					lineHeight: '1.15em',
					placeholderColor: '#000',
					_webkitFontSmoothing: 'antialiased',
					_mozOsxFontSmoothing: 'grayscale'
				}
			],
			applePay: {
				elementId: 'sq-apple-pay'
			},
			masterpass: {
				elementId: 'sq-masterpass'
			},
			googlePay: {
				elementId: 'sq-google-pay'
			},
			cardNumber: {
				elementId: 'sq-card-number',
				placeholder: '• • • •  • • • •  • • • •  • • • •'
			},
			cvv: {
				elementId: 'sq-cvv',
				placeholder: 'CVV'
			},
			expirationDate: {
				elementId: 'sq-expiration-date',
				placeholder: 'MM/YY'
			},
			postalCode: {
				elementId: 'sq-postal-code',
				placeholder: 'Zip'
			},
			callbacks: {
				methodsSupported: (methods) => {
					if (methods.googlePay) {
						this.setState({
							googlePay: methods.googlePay
						});
					}
					if (methods.applePay) {
						this.setState({
							applePay: methods.applePay
						});
					}
					return;
				},
				createPaymentRequest: () => {
					return {
						requestShippingAddress: false,
						requestBillingInfo: true,
						currencyCode: 'USD',
						countryCode: 'US',
						total: {
							label: 'MERCHANT NAME',
							amount: '1',
							pending: false
						},
						lineItems: [
							{
								label: 'Subtotal',
								amount: '1',
								pending: false
							}
						]
					};
				},
				cardNonceResponseReceived: (errors, nonce, cardData) => {
					if (errors) {
						// Log errors from nonce generation to the Javascript console
						errors.forEach(function(error) {
							console.log(error.message);
						});

						return;
					}
					this.setState({
						nonce: nonce
					});
					this.props.updatePayment(this.state.plan);
				},
				unsupportedBrowserDetected: () => {},
				inputEventReceived: (inputEvent) => {
					switch (inputEvent.eventType) {
						case 'focusClassAdded':
							break;
						case 'focusClassRemoved':
							break;
						case 'errorClassAdded':
							document.getElementById('error').innerHTML =
								'Please fix card information errors before continuing.';
							break;
						case 'errorClassRemoved':
							document.getElementById('error').style.display = 'none';
							break;
						case 'cardBrandChanged':
							if (inputEvent.cardBrand !== 'unknown') {
								this.setState({
									cardBrand: inputEvent.cardBrand
								});
							} else {
								this.setState({
									cardBrand: ''
								});
							}
							break;
						case 'postalCodeChanged':
							break;
						default:
							break;
					}
				},
				paymentFormLoaded: function() {
					document.getElementById('name').style.display = 'inline-flex';
				}
			}
		};
		this.paymentForm = new this.props.paymentForm(config);
		this.paymentForm.build();
	}

	render() {
		return (
			<Grid container direction="row" spacing={3}>
				<Grid item xs={12} md={6}>
					<Card style={{ padding: '30px 10px' }}>
						<Grid container direction="column">
							<Grid item>
								<button
									style={{ display: this.state.applePay ? 'inherit' : 'none' }}
									className="wallet-button"
									id="sq-apple-pay"
								/>
							</Grid>
							<Grid item>
								<button
									style={{ display: this.state.googlePay ? 'inherit' : 'none' }}
									className="wallet-button"
									id="sq-google-pay"
								/>
							</Grid>
							<Grid item>
								<h2
									style={{
										width: '80%',
										textAlign: 'center',
										borderBottom: '1px solid #000',
										lineHeight: '0.1em',
										marginLeft: '10%',
										marginTop: '8%',
										fontSize: '15px'
									}}
								>
									<span
										style={{
											background: '#fff',
											padding: '0 10px'
										}}
									>
										OR
									</span>
								</h2>
							</Grid>
							<Grid item>
								<div id="sq-ccbox">
									<span style={styles.leftCenter}>Enter Card Info Below </span>
									<span style={styles.blockRight}>{this.state.cardBrand.toUpperCase()}</span>
									<div id="cc-field-wrapper">
										<div id="sq-card-number" />
										<input type="hidden" id="card-nonce" name="nonce" />
										<div id="sq-expiration-date" />
										<div id="sq-cvv" />
									</div>
									<div style={styles.divider}>
										<input id="name" style={styles.name} type="text" placeholder="Name" />
										<div id="sq-postal-code" />
									</div>
								</div>
							</Grid>
						</Grid>
					</Card>
				</Grid>
				<Grid item xs={12} md={6}>
					<Card style={{ padding: '30px 10px' }}>
						<Grid container direction="column">
							<Grid
								item
								style={{
									margin: '20px 10px'
								}}
							>
								<Typography color="primary">*Please select any one plan to continue</Typography>
							</Grid>
							<Grid item>
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-around',
										alignContent: 'space-around'
									}}
								>
									<Button
										color="primary"
										variant={this.state.plan === 'limited' ? 'contained' : 'outlined'}
										onClick={() => this.setState({ plan: 'limited' })}
									>
										Limited
									</Button>
									<Button
										color="primary"
										variant={this.state.plan === 'basic' ? 'contained' : 'outlined'}
										onClick={() => this.setState({ plan: 'basic' })}
									>
										Basic
									</Button>
									<Button
										color="primary"
										variant={this.state.plan === 'unlimited' ? 'contained' : 'outlined'}
										onClick={() => this.setState({ plan: 'unlimited' })}
									>
										Unlimited
									</Button>
								</div>
							</Grid>
							<Grid
								item
								style={{
									textAlign: 'center',
									marginTop: '20px'
								}}
							>
								{this.state.plan === 'limited' &&
									'5 searches will be added to the available search count'}
								{this.state.plan === 'basic' && 'Pay as you Use'}
								{this.state.plan === 'unlimited' && 'Unlimited searches for a month'}
							</Grid>
							<Grid item>
								<button
									className="button-credit-card"
									style={this.state.plan ? {} : { pointerEvents: 'none' }}
									onClick={this.requestCardNonce}
								>
									Pay
								</button>
							</Grid>
						</Grid>
					</Card>
				</Grid>
			</Grid>
		);
	}
}
