import { blAddress, userEmail } from '../UserInfo';
import { YoorzBlockAPI, YoorzAPI } from '../YoorzAPI';

export const postBackCheck = async info => {
  try {
    const userInfo = await YoorzAPI().post(`/backCheck?email=${userEmail}`, {
      ...info
    });
    if (userInfo.data.result) {
      return userInfo.data.result;
    }
    return false;
  } catch (err) {
    return err;
  }
};


export const postBackgroundCheck = async info => {
  try {
    const userInfo = await YoorzAPI().post(`/add/background`, {
      ...info
    });
    if (userInfo.data.msg) {
      return userInfo.data.msg;
    }
    return false;
  } catch (err) {
    return err;
  }
};

export const postBlPage4 = async (info) => {
  const data = {
    address: blAddress,
    ...info
  };
  try {
    const page4 = await YoorzBlockAPI().post('/page4/addpage4', {
      ...data
    });
    if (page4.data) {
      return true;
    }
    return false;
  } catch (err) {
    return false;
  }
};

export const getBlPage4 = async () => {
  try {
    const page4 = await YoorzBlockAPI().post('/page4/getpage4', {
      address: blAddress,
    });
    if (page4.data.address !== '0x0000000000000000000000000000000000000000') {
      return page4.data;
    }
    return false;
  } catch (err) {
    return false;
  }
};

export const getConverusSubjectId = async (info) => {
  try {
    const subResponse = await YoorzAPI().post(`/identity/subject?email=${userEmail}`, {
      ...info
    });
    if (subResponse.data) {
      return subResponse.data;
    }
    return false;
  } catch (err) {
    return err;
  }
}

export const postConverusQuestionTemplate = async (info) => {
  try {
    const templateInfo = await YoorzAPI().post(`/identity/template?email=${userEmail}`, {
      ...info
    });
    if (templateInfo.data) {
      return templateInfo.data;
    }
    return false;
  } catch (err) {
    return err;
  }
}

export const getConverusQuestionsJSON = async (examId) => {
  try {
    const questions = await YoorzAPI().get(`/identity/exam/${examId}?email=${userEmail}`);
    if (questions.data) {
      return questions.data;
    }
    return false;
  } catch (err) {
    return err;
  }
}