import React from 'react';
import { LinkedIn } from '@material-ui/icons';
import { Fab } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { postLinkedinLogin } from './Request';

const useStyles = makeStyles((theme) => ({
  gfab: {
    backgroundColor: '#fff'
  }
}));
const LinkedinLoginPage = (props) => {
  const classes = useStyles();

  const handleLinkedinClick = async () => {
    const data = await postLinkedinLogin();
    console.log('Data ', data);
  };

  return (
    <Fab aria-label="add" className={classes.gfab} onClick={handleLinkedinClick}>
      <LinkedIn style={{ color: '#4b7ab3' }} />
    </Fab>
  );
};

export default LinkedinLoginPage;


{/*<a href="https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=86vc3f1072ohun&scope=r_emailaddress&state=987654321&redirect_uri=https%3A%2F%2Fyoorz.me%2Fcallback">
<LinkedIn style={{ color: '#4b7ab3' }} />
</a>*/}