export const DISPLAY_FORM_MODEL = 'display_modal_form';

export const HIDE_FORM_MODEL = 'hide_modal_form';

export const SET_VALID_EMAIL = 'set_valid_email';

export const SET_VALID_PHONE = 'set_valid_phone';

export const SET_VALID_FIRST_NAME = 'set_valid_first_name';

export const SET_VALID_LAST_NAME = 'set_valid_last_name';

export const SET_ALL_VALID_ITEMS = 'set_all_valid_items';

export const SET_EXAM_URL = 'set_exam_url';

export const SET_EXAM_ID = 'set_exam_id';

export const SET_CONVERUE_INFO = 'set_converus_info';

export const SET_SELECTED_DATE = 'set_selected_date';

export const SET_SUBJECT_ID = 'set_subject_id';

export const SET_PAGE1_VERIFIED = 'set_page1_verified';

export const SET_PAGE2_VERIFIED = 'set_page2_verified';

export const SET_PAGE3_VERIFIED = 'set_page3_verified';

export const SET_CONVERUS_COMPLETED = 'set_converus_completed';

export const SET_BACKGROUND_CHECK = 'set_background_check';

export const SET_ONFIDO_ID = 'set_onfido_id';

export const SET_PASSWORD_SET_SUCCESSFULLY = 'set_password_set_successfully';

export const SET_ONFIDO_IMAGE_URL = 'set_onfido_image_url';

export const SET_IMAGE_CAPTURE_DATE = 'set_image_capture_date';

export const SET_IDRND_TOKEN = 'set_idrnd_token';

export const SET_IDRND_UPLOAD_STATUS = 'set_idrnd_upload_status';

export const CONVERUE_REQUEST = {
	examInfo: {
		customerId: 'HNRNBSHVNP',
		examId: null,
		examLocale: 'en-US',
		examModel: 'TruthDetect.0005',
		examineeId: null,
		templateId: '00000000000000000000000000000000'
	},
	examResponse: {
		sessions: [
			{
				items: null,
				session: '1'
			}
		]
	},
	examSamples: null
};

export const SET_USER_PRO_STATUS = 'set_user_pro_status';
