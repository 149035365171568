import { blAddress, userEmail } from '../UserInfo';
import { YoorzBlockAPI, YoorzAPI } from '../YoorzAPI';


export const postBlUpdatePassword = async (password) => {
  const data = {
    address: blAddress,
    oldpassword: '!communicatewithconfidence',
    newpassword: password
  };
  try {
    const status = await YoorzBlockAPI().post('/users/updatepassword', {
      ...data
    });
    if (status.data.msg === 'Password updated successfully') {
      return true;
    }
    return false;
  } catch (err) {
    return false;
  }
};


export const postBlResetPassword = async (password) => {
  const data = {
    address: blAddress,
    email: userEmail,
    newpassword: password
  };
  try {
    const status = await YoorzBlockAPI().post('/users/resetpassword', {
      ...data
    });
    if (status.data.msg) {
      return true;
    }
    return false;
  } catch (err) {
    return false;
  }
};

export const postBlSendActivationEmail = async () => {
  try {
    const status = await YoorzBlockAPI().post('/account/sendactivationemail', {
      address: blAddress,
    });
    if (status.data.msg === 'Email sent successfully') {
      return true;
    }
    return false;
  } catch (err) {
    return false;
  }
};