let userEmail = null;
let apiToken = null;

let blToken = null;
let blAddress = null;

const setEmail = newEmail => {
  userEmail = newEmail;
};

const setApiToken = newApiToken => {
  apiToken = newApiToken;
};

const setBlToken = newBlToken => {
  blToken = newBlToken;
};

const setBlAdress = newSetBlAddress => {
  blAddress = newSetBlAddress;
};

const resetTokens = () => {
  userEmail = null; apiToken = null; blToken = null; blAddress = null;
};

export { userEmail, apiToken, blToken, blAddress, setEmail, setApiToken, setBlToken, setBlAdress, resetTokens };