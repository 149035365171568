import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { displayForm, hideForm } from '../Verification/components/actions';
import { Grid, Card, Typography, Checkbox, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PhoneVerificationModal from '../Verification/modals/PhoneVerificationModal';
import TERMS from './constants';

const useStyles = makeStyles({
  paper: {
    maxHeight: '500px',
    overflowY: 'auto',
    margin: '0 50px',
    padding: '50px'
  },
  terms: {
    color: '#7355d2'
  },
  titleText: {
    marginTop: '20px'
  },
  textContent: {
    marginTop: '10px'
  },
  agreeText: {
    textAlign: 'center',
    marginTop: '30px',
    color: '#7355d2'
  },
  goOver: {
    fontSize: '14px',
    color: '#7355d2'
  }
});

const TermsOrMFA = props => {
  const classes = useStyles();

  const { phone, verify } = props;

  useEffect(() => {
    if (!props.phone && !props.verify) {
      props.history.push('/signin');
    } else if (props.phone && !props.verify) {
      //postTwilioPhoneSendCode(props.phone).then(getSid => {
      //  setSid(getSid);
      //  props.displayForm('phone');
      //});
      props.displayForm('phone');
    }
    // eslint-disable-next-line
  }, [phone, verify]);

  const [agree, setAgree] = useState(false);
  //const [sid, setSid] = useState('');

  const handleVerifyCode = async status => {
    if (status) {
      props.history.push('/dashboard');
    } else {
      props.history.push('/signin');
    }
  };

  const mfa = () => {
    return (
      <PhoneVerificationModal
        formState={props.formState}
        closeModal={closeModal}
        phone={props.phone}
        handleVerifyCode={handleVerifyCode}
        isModalView={true}
      />
    );
  };

  const closeModal = () => {
    props.hideForm();
  };

  const terms = () => (
    <Grid
      container
      spacing={3}
      direction='column'
      justify='center'
      alignItems='center'
    >
      <Grid item>
        <Typography className={classes.terms} variant='h3'>
          TERMS AND CONDITIONS
        </Typography>
      </Grid>
      <Grid item>
        <Card className={classes.paper}>
          <div className={classes.goOver}>
            * Please go over the terms & conditions to continue
          </div>
          {TERMS.map((item, index) => (
            <div key={`item-${index}`}>
              <Typography variant='h6' className={classes.titleText}>
                {item.title}
              </Typography>
              {item.text.map((text, i) => (
                <div key={`text-${index}-${i}`} className={classes.textContent}>
                  <Typography variant='span'>{text}</Typography>
                </div>
              ))}
            </div>
          ))}
          <div className={classes.agreeText}>
            <Checkbox
              checked={agree}
              onChange={() => setAgree(!agree)}
              color='primary'
            />
            I am above 18 years old and I accept the above Terms and Conditions.
          </div>
        </Card>
      </Grid>
      <Grid item>
        <Button
          className={classes.signInButton}
          disabled={agree ? false : true}
          size='large'
          type='submit'
          variant='contained'
          color='primary'
          onClick={() => props.history.push('/verify')}
        >
          Agree to Continue
        </Button>
      </Grid>
    </Grid>
  );

  return (
    <div style={{ marginTop: '50px' }}>{props.verify ? terms() : mfa()}</div>
  );
};

TermsOrMFA.propTypes = {
  history: PropTypes.object,
  displayForm: PropTypes.func,
  formState: PropTypes.bool.isRequired,
  login: PropTypes.bool,
  phone: PropTypes.string,
  verify: PropTypes.bool
};

const mapStateToProps = state => {
  return {
    modal: state.rule.form.modal,
    formState: state.rule.form.status,
    phone: state.login.phone,
    verify: state.login.verify
  };
};

const mapDispatchToProps = dispatch => ({
  displayForm: modal => dispatch(displayForm(modal)),
  hideForm: () => dispatch(hideForm())
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(TermsOrMFA);
