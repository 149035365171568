import { userEmail } from '../UserInfo';
import { YoorzAPI } from '../YoorzAPI';

export const getAcuantResult = async () => {
  try {
    const result = await YoorzAPI().get(`/verify/doc/result?email=${userEmail}`);
    if (result.data.result) {
      return result.data.result;
    }
    return false;
  } catch (err) {
    return err;
  }
};