import React from "react";
import Modal from "react-modal";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider
} from "@material-ui/core";
import { LockOpen, Email } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import { Grid, Typography } from "@material-ui/core";
import ForgotPassword from './ForgotPassword';
import ForgotUser from './ForgotUser';
import { Password } from '../Settings/components';

const useStyles = makeStyles({
  terms: {
    color: "#7355d2",
    marginBottom: "10px"
  },
  list: {}
});
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "#fff",
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    height: "350px",
    width: "500px",
    border: "2px solid #3f51b5",
    alignContent: "center",
    justify: "center"
  }
};

Modal.setAppElement("#root");

const TroubleSigningModal = props => {
  const classes = useStyles();

  const [resetStatus, setResetStatus] = React.useState("");

  const list = [
    {
      item: 1,
      title: "Forgot Password",
      icon: <LockOpen />,
      id: "password"
    },
    {
      item: 2,
      title: "Forgot UserId",
      icon: <Email />,
      id: "user"
    }
  ];

  const listItems = item => {
    console.log(item);
    return (
      <ListItem button key={`list-${item.item}`} onClick={() => updateResetStatus(item.id)}>
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText primary={item.title} />
      </ListItem>
    );
  };

  const updateResetStatus = status => setResetStatus(status);

  return (
    <Modal
      isOpen={props.formState}
      onRequestClose={props.closeModal}
      style={customStyles}
      contentLabel="Verify"
    >
      {resetStatus === "" &&
        <Grid
          container
          direction="column"
          justify="center"
          alignContent="center"
          spacing={3}
        >
          <Grid item lg={12}>
            <Typography className={classes.terms} variant="h4">
              Trouble Signing in
          </Typography>
          </Grid>

          <Grid item lg={12}>
            <List component="nav" aria-label="main">
              {list.map(item => listItems(item))}
            </List>
          </Grid>
        </Grid>}
      {resetStatus === list[0].id && <ForgotPassword updateResetStatus={updateResetStatus} />}
      {resetStatus === list[1].id && <ForgotUser updateResetStatus={updateResetStatus} />}
      {resetStatus === 'resetPassword' && <Password is_reset_password={true} updateResetStatus={updateResetStatus} />}
    </Modal>
  );
};

TroubleSigningModal.prototype = {
  formState: PropTypes.bool.isRequired,
  closeModal: PropTypes.func,
  userEmail: PropTypes.string.isRequired,
  history: PropTypes.object
};

export default withRouter(TroubleSigningModal);
