import Chat from 'twilio-chat';
import { getChatToken } from './API/ChatApi';


export const initializeChannel = async (data) => {
  var chlname = `2_${data[0].email}_${data[1].email}`;
  const chl = `2_${data[1].email}_${data[0].email}`;
  if (chlname < chl)
    chlname = chlname;
  else
    chlname = chl;

  const name = "Messaging";
  var token;
  do {
    token = await getChatToken(data[0].user);
  } while (token === 'try again');
  try {
    const client = await Chat.Client.create(token);
    const channel = await client.getChannelByUniqueName(chlname);
    closeClient(client);
    return channel;
  } catch (err) {

    if (err.message === 'Not Found') {

      let isUser0 = await checkUserExists(data[0], token);
      let isUser1 = await checkUserExists(data[1], token);
      if (isUser0 && isUser1) {
        const client = await Chat.Client.create(token);
        const chl = await client.createChannel({
          uniqueName: chlname,
          friendlyName: name,
          isPrivate: true
        });
        await chl.join();
        await chl.add(JSON.stringify(data[1]));
        closeClient(client);
        return chl;
      }
    } else if (err.code === 50404) { //member exists
      return 'error';
    }

    return 'error';
  }
};
export const subscribeToMainChat = async (addr) => {
  var token;
  do {
    token = await getChatToken(addr);
  } while (token === 'try again');
  try {
    const client = await Chat.Client.create(token);
    const channel = await client.getChannelByUniqueName("general_1");
    await channel.join();
    closeClient(client);
    return 'success';
  } catch (err) {
    if (err.code === 50300 || err.message === 'Not Found') //creating general channel
    {
      const client = await Chat.Client.create(token);
      const chl = await client.createChannel({
        uniqueName: "general_1",
        friendlyName: "General",
      });
      chl.join();
      closeClient(client);
      return 'success';
    } else if (err.code === 50404) { //member exists
      return 'exists';
    }

  }

};

const checkUserExists = async (user, token) => {

  try {
    const client = await Chat.Client.create(token);
    const usr = await client.getUser(JSON.stringify(user));
    closeClient(client);
    if (usr)
      return true;

  } catch (err) {
    return false;
  }
};

const closeClient = async (client) => {
  if (client)
    client.shutdown();
};

