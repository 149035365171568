import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { displayForm, hideForm, setEmail, setPhone, setLastName, setFirstName } from './actions';
import { makeStyles } from '@material-ui/styles';
import 'typeface-roboto';
import {
  Email as EmailIcon,
  Phone as PhoneIcon,
  VerifiedUser as VerifiedUserIcon,
  CheckCircle as CheckCircleIcon,
  CheckCircleOutline as CheckCircleOutlineIcon
} from '@material-ui/icons';
import { green } from '@material-ui/core/colors';
import PhoneVerificationModal from '../modals/PhoneVerificationModal';
import EmailVerificationModal from '../modals/EmailVerificationModal';
import NameVerificationModal from '../modals/NameVerificationModal';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Button,
  Grid,
  TextField,
  IconButton,
  InputAdornment,
  Typography,
  CircularProgress
} from '@material-ui/core';
import { postVerifyEmail } from '../../../API/page1PersonalAPI';
//import { postTwilioPhoneSendCode, postTwilioPhoneSendVerify } from '../../../API/CommonUtilsAPI';


const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '50px'
  },
  gridClass: {
    marginTop: '70px'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: 'auto'
  },
  innerGrid: {
    display: 'flex'
  },
  button: {
    marginTop: '7px',
    height: '40px',
    float: 'right',
    marginLeft: '5px',
    width: 'auto'
  },
  countryCode: {
    marginTop: '7px',
    height: '40px',
    marginRight: '10px',
    width: 'auto',
    fontSize: '18px',
    paddingTop: '13px'
  },
  verifyIcon: {
    color: green[800],
    marginTop: '16px',
    height: '40px',
    float: 'right',
    marginLeft: '5px',
    width: 'auto'
  }
}));

const SocialVerification = (props) => {
  const classes = useStyles();

  const [firstname, setFirstName] = React.useState('');
  const [lastname, setLastName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [phone, setPhone] = React.useState('');

  const [lcheck, setlCheck] = React.useState('');
  const [fcheck, setfCheck] = React.useState('');
  const [isPhoneVerified, setIsPhoneVerified] = React.useState(false);
  const [isEmailVerified, setIsEmailVerified] = React.useState(false);
  const [isEmailLoading, setIsEmailLoading] = React.useState(false);
  const [isPhoneLoading, setIsPhoneLoading] = React.useState(false);
  const [isCodeError, setIsCodeError] = React.useState(false);

  React.useEffect(
    () => {
      if (email && email === props.email) {
        setIsEmailVerified(true);
      } else {
        setIsEmailVerified(false);
      }
      if (phone && phone === props.phone) {
        setIsPhoneVerified(true);
      } else {
        setIsPhoneVerified(false);
      }
    },
    // eslint-disable-next-line
    [email, phone]
  );

  React.useEffect(
    () => {
      setPhone(props.phone && props.phone.replace('+1', ''));
      setEmail(props.email);
      setFirstName(props.firstName);
      setLastName(props.lastName);
    },
    [props.phone, props.email, props.firstName, props.lastName]
  );

  const hanldePhoneNumber = (event) => {
    event.preventDefault();
    setPhone(event.target.value);
  };

  const handleEmail = (event) => {
    event.preventDefault();
    setlCheck('lname');
    setEmail(event.target.value);
  };

  const handleFirstName = (event) => {
    event.preventDefault();
    setFirstName(event.target.value);
    props.setFirstName(event.target.value);
  };

  const handleLastName = (event) => {
    event.preventDefault();
    setLastName(event.target.value);
    props.setLastName(event.target.value);
    setfCheck('fname');
  };

  const firstnameHandle = (event) => {
    event.preventDefault();
    props.setFirstName(firstname);
  };

  const lastnameHandle = (event) => {
    event.preventDefault();
    props.setLastName(lastname);
  };

  const openModal = (modal) => {
    props.displayForm(modal);
  };
  const closeModal = () => {
    props.hideForm();
  };

  const handleEmailVerify = async (event) => {
    event.preventDefault();
    setIsEmailLoading(true);

    const status = await postVerifyEmail(email);

    setIsEmailLoading(false);
    if (status) {
      setIsEmailVerified(true);
      props.setEmail(email);
      return null;
    }
    props.setEmail(null);
    openModal('email');
  };

  const handleTwilioPhone = async () => {
    setIsPhoneLoading(true);
    setIsCodeError(false);
    //const getSid = await postTwilioPhoneSendCode(`+1${phone}`);
    //setSid(getSid);
    openModal('phone');
  };

  const handleVerifyCode = async (status) => {
    if (status) {
      setIsPhoneVerified(true);
      props.setPhone(`+1${phone}`);
    } else {
      setIsPhoneVerified(false);
      setIsCodeError(true);
      props.setPhone(null);
    }
    setIsPhoneLoading(false);
  };

  return (
    <Grid container className={classes.gridClass}>
      <Card>
        <CardHeader subheader="Welcome" title="Profile Validation">
          {`${firstname} ${lastname}`}
        </CardHeader>
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="First Name"
                subheader="First Name"
                margin="dense"
                name="First Name"
                onChange={handleFirstName}
                required
                value={firstname}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end">
                        {fcheck === 'fname' ? (
                          <CheckCircleIcon style={{ color: '#3f51b5' }} />
                        ) : (
                            <CheckCircleOutlineIcon
                              style={{ color: '#3f51b5' }}
                              onClick={firstnameHandle}
                            />
                          )}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                style={{ justifyContent: 'center' }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Last name"
                subheader="Last Name"
                margin="dense"
                name="lastName"
                onChange={handleLastName}
                required
                value={lastname}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end">
                        {lcheck === 'lname' ? (
                          <CheckCircleIcon style={{ color: '#3f51b5' }} />
                        ) : (
                            <CheckCircleOutlineIcon
                              style={{ color: '#3f51b5' }}
                              onClick={lastnameHandle}
                            />
                          )}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                style={{ justifyContent: 'center' }}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid item className={classes.innerGrid}>
                <TextField
                  fullWidth
                  label="Email Address"
                  margin="dense"
                  name="email"
                  onChange={handleEmail}
                  disabled={!(firstname && lastname)}
                  required
                  value={email}
                  variant="outlined"
                  style={{ float: 'left' }}
                /// Width not setting properly
                />
                {
                  <span>
                    {isEmailVerified ? (
                      <VerifiedUserIcon color="primary" className={classes.button} />
                    ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.button}
                          endIcon={<EmailIcon />}
                          disabled={!(email && email.includes('@'))}
                          onClick={handleEmailVerify}
                        >
                          {isEmailLoading ? <CircularProgress style={{ color: "#fff" }} size={25} /> : 'verify'}
                        </Button>
                      )}
                  </span>
                }
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid item className={classes.innerGrid}>
                <Typography className={classes.countryCode} variant="body2">
                  +1
								</Typography>
                <TextField
                  fullWidth
                  label="Phone Number"
                  margin="dense"
                  name="phone"
                  onChange={hanldePhoneNumber}
                  disabled={!(firstname && lastname && isEmailVerified)}
                  type="number"
                  value={phone}
                  variant="outlined"
                  error={isCodeError}
                  style={{ float: 'left' }}
                />
                {
                  <span>
                    {isPhoneVerified ? (
                      <VerifiedUserIcon color="primary" className={classes.button} />
                    ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.button}
                          endIcon={<PhoneIcon />}
                          disabled={!(phone && phone.length >= 10)}
                          onClick={handleTwilioPhone}
                        >
                          {isPhoneLoading ? <CircularProgress style={{ color: "#fff" }} size={25} /> : 'verify'}
                        </Button>
                      )}
                  </span>
                }
              </Grid>
            </Grid>
            {props.modal === 'phone' && (
              <PhoneVerificationModal
                formState={props.formState}
                closeModal={closeModal}
                phone={`+1${phone}`}
                handleVerifyCode={handleVerifyCode}
                isModalView={true}
              />
            )}
            {props.modal === 'email' && (
              <EmailVerificationModal formState={props.formState} closeModal={closeModal} />
            )}
            {props.modal === 'name' && (
              <NameVerificationModal formState={props.formState} closeModal={closeModal} />
            )}
          </Grid>
        </CardContent>
        <Divider />
      </Card>
    </Grid>
  );
};

SocialVerification.prototype = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  displayForm: PropTypes.func,
  hideForm: PropTypes.func,
  formState: PropTypes.bool.isRequired,
  modal: PropTypes.string,
  setEmail: PropTypes.func,
  setPhone: PropTypes.func
};

const mapStateToProps = (state) => {
  return {
    email: state.rule.page1.email,
    phone: state.rule.page1.phone,
    firstName: state.rule.page1.firstName,
    lastName: state.rule.page1.lastName,
    formState: state.rule.form.status,
    modal: state.rule.form.modal
  };
};

const mapDispatchToProps = (dispatch) => ({
  displayForm: (modal) => dispatch(displayForm(modal)),
  hideForm: () => dispatch(hideForm()),
  setEmail: (email) => dispatch(setEmail(email)),
  setPhone: (phone) => dispatch(setPhone(phone)),
  setFirstName: (firstName) => dispatch(setFirstName(firstName)),
  setLastName: (lastName) => dispatch(setLastName(lastName))
});

export default connect(mapStateToProps, mapDispatchToProps)(SocialVerification);
