import * as Constants from './constants';

const initialState = {
  // Is new Modal's needs to be added use the formState value
  form: {
    status: false,
    modal: null
  },
  validationStatus: false,
  page1: {
    email: null,
    firstName: null,
    lastName: null,
    phone: null
  },
  page2: {
    isVerified: false,
    userInfo: {
      state: '',
      country: '',
      address1: '',
      address2: '',
      city: '',
      gender: '',
      marritalStatus: '',
      zip: ''
    },
    selectedDate: null,
    subjectId: null
  },
  page3: {
    isVerified: false,
    status: false,
    examUrl: null,
    examId: null,
    examResult: null,
    examineeId: null
  },
  page4: {
    isVerified: false,
    tracking: '',
    message: '',
    criminal: '',
    bankruptcy: '',
    judgment: ''
  },
  page5: {
    isVerified: false,
    onFidoId: '',
    face: {},
    imageUrl: '',
    imageCaptureDate: '',
    idRnd: null,
    idRndScore: {},
    isUploaded: false
  },
  pro: {}
};

const loginPageForm = (state = initialState, action) => {
  switch (action.type) {
    case Constants.DISPLAY_FORM_MODEL:
      return { ...state, form: { status: true, modal: action.modal } };
    case Constants.HIDE_FORM_MODEL:
      return { ...state, form: { ...initialState.form } };
    case Constants.SET_VALID_EMAIL:
      return { ...state, page1: { ...state.page1, email: action.email } };
    case Constants.SET_VALID_PHONE:
      return { ...state, page1: { ...state.page1, phone: action.phone } };
    case Constants.SET_VALID_FIRST_NAME:
      return {
        ...state,
        page1: { ...state.page1, firstName: action.firstName }
      };
    case Constants.SET_VALID_LAST_NAME:
      return { ...state, page1: { ...state.page1, lastName: action.lastName } };
    case Constants.SET_ALL_VALID_ITEMS:
      return {
        ...state,
        page1: {
          ...state.page1,
          email: action.email,
          firstName: action.firstName,
          lastName: action.lastName,
          phone: action.phone
        }
      };
    case Constants.SET_EXAM_URL: {
      return { ...state, page2: { ...state.page2, examUrl: action.url } };
    }
    case Constants.SET_EXAM_ID: {
      return { ...state, page2: { ...state.page2, examId: action.id } };
    }
    case Constants.SET_CONVERUE_INFO: {
      return { ...state, page2: { ...state.page2, userInfo: action.info } };
    }
    case Constants.SET_SELECTED_DATE: {
      return {
        ...state,
        page2: { ...state.page2, selectedDate: action.date }
      };
    }
    case Constants.SET_SUBJECT_ID: {
      return {
        ...state,
        page2: { ...state.page2, subjectId: action.id }
      };
    }
    case Constants.SET_PAGE1_VERIFIED: {
      return {
        ...state,
        page1: { ...state.page1, isVerified: action.status }
      };
    }
    case Constants.SET_PAGE2_VERIFIED: {
      return {
        ...state,
        page1: { ...state.page2, isVerified: action.status }
      };
    }
    case Constants.SET_PAGE3_VERIFIED: {
      return {
        ...state,
        page1: { ...state.page3, isVerified: action.status }
      };
    }
    case Constants.SET_CONVERUS_COMPLETED: {
      return {
        ...state,
        page3: {
          ...state.page3,
          status: action.info.status,
          examUrl: action.info.examUrl,
          examId: action.info.examInfo.examId,
          examResult: action.info.examScore,
          examineeId: action.info.examInfo.examineeId
        }
      };
    }
    case Constants.SET_ONFIDO_ID: {
      return {
        ...state,
        page5: { ...state.page5, onFidoId: action.onfidoId, face: action.face }
      };
    }
    case Constants.SET_BACKGROUND_CHECK: {
      return {
        ...state,
        page4: {
          ...state.page4,
          tracking: action.check.tracking,
          criminal: action.check.criminal,
          bankruptcy: action.check.bankruptcy,
          judgment: action.check.judgment
        }
      };
    }
    case Constants.SET_PASSWORD_SET_SUCCESSFULLY: {
      return {
        ...state,
        validationStatus: true
      };
    }
    case Constants.SET_ONFIDO_IMAGE_URL: {
      return {
        ...state,
        page5: { ...state.page5, imageUrl: action.url }
      };
    }
    case Constants.SET_IMAGE_CAPTURE_DATE: {
      return {
        ...state,
        page5: { ...state.page5, imageCaptureDate: action.sas }
      };
    }
    case Constants.SET_IDRND_TOKEN: {
      return {
        ...state,
        page5: { ...state.page5, idRnd: action.idrnd, idRndScore: action.score }
      };
    }
    case Constants.SET_IDRND_UPLOAD_STATUS: {
      return {
        ...state,
        page5: { ...state.page5, isUploaded: action.isUploaded }
      };
    }
    case Constants.SET_USER_PRO_STATUS: {
      return {
        ...state,
        pro: action.status
      };
    }
    default:
      return state;
  }
};

export default loginPageForm;
