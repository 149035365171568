import * as Constants from './constants';

export const displayForm = (modal) => ({
  type: Constants.DISPLAY_FORM_MODEL,
  modal
});

export const hideForm = () => ({
  type: Constants.HIDE_FORM_MODEL
});

export const setEmail = (email) => ({
  type: Constants.SET_VALID_EMAIL,
  email
});

export const setPhone = (phone) => ({
  type: Constants.SET_VALID_PHONE,
  phone
});

export const setFirstName = (firstName) => ({
  type: Constants.SET_VALID_FIRST_NAME,
  firstName
});

export const setLastName = (lastName) => ({
  type: Constants.SET_VALID_LAST_NAME,
  lastName
});

export const setAllValidItems = (firstName, lastName, email, phone) => ({
  type: Constants.SET_ALL_VALID_ITEMS,
  firstName,
  lastName,
  email,
  phone
});

export const setExamUrl = (url) => ({
  type: Constants.SET_EXAM_URL,
  url
});

export const setExamId = (id) => ({
  type: Constants.SET_EXAM_ID,
  id
});

export const setConverusInfo = (info) => ({
  type: Constants.SET_CONVERUE_INFO,
  info
});

export const setSelectedDate = (date) => ({
  type: Constants.SET_SELECTED_DATE,
  date
});

export const setSubjectId = (id) => ({
  type: Constants.SET_SUBJECT_ID,
  id
});

export const setPage1Verified = (status) => ({
  type: Constants.SET_PAGE1_VERIFIED,
  status
});

export const setPage2Verified = (status) => ({
  type: Constants.SET_PAGE2_VERIFIED,
  status
});

export const setPage3Verified = (status) => ({
  type: Constants.SET_PAGE3_VERIFIED,
  status
});

export const setConverusCompleted = (info) => ({
  type: Constants.SET_CONVERUS_COMPLETED,
  info
});

export const setBackgroundCheck = (check) => ({
  type: Constants.SET_BACKGROUND_CHECK,
  check
});

export const setOnfidoId = (onfidoId, face) => ({
  type: Constants.SET_ONFIDO_ID,
  onfidoId,
  face
});

export const setPasswordSuccess = () => {
  return {
    type: Constants.SET_PASSWORD_SET_SUCCESSFULLY
  };
};

export const setOnFidoImageUrl = (url) => {
  return {
    type: Constants.SET_ONFIDO_IMAGE_URL,
    url
  };
};

export const setImageCaptureDate = (sas) => {
  return {
    type: Constants.SET_IMAGE_CAPTURE_DATE,
    sas
  };
};

export const setIdrndToken = (score, idrnd) => {
  return {
    type: Constants.SET_IDRND_TOKEN,
    score,
    idrnd
  };
};

export const setIdrndUploadeStatus = (isUploaded) => {
  return {
    type: Constants.SET_IDRND_UPLOAD_STATUS,
    isUploaded
  };
};

export const setProStatus = (status) => {
  return {
    type: Constants.SET_USER_PRO_STATUS,
    status
  };
};
