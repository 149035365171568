import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import FacebookLogin from 'react-facebook-login';
import { Facebook } from '@material-ui/icons';
import { facebookLoginSuccess, facebookLoginFailure } from './actions';
import { setAllValidItems } from '../../views/Verification/components/actions';
import { loginSuccess, loginFailure, verifyProcess } from '../../actions';
import { socialSignIn } from '../../api';
import FacebookLogo from '../../views/SignIn/facebook.png';
import './SocialVerification.scss';

const FacebookLoginPage = (props) => {
	const responseFacebook = async (response) => {
		console.log('Response: ', response);
		try {
			if (response.name) {
				const email = response.email;
				const firstName = response.name.split(' ')[0];
				const lastName = response.name.split(' ')[1];
				props.setAllValidItems(firstName, lastName, email, null);
				props.facebookLoginSuccess(response);
				if (email) {
					const { isVerified, phone } = await socialSignIn(email);
					if (isVerified) {
						props.loginSuccess(phone);
						return props.history.push('/initialize');
					}
				}
				props.loginFailure();
				props.verifyProcess();
				return props.history.push('/initialize');
			}
		} catch (error) {
			props.loginFailure();
			props.verifyProcess();
			props.facebookLoginFailure(response);
			return props.history.push('/initialize');
		}
	};

	return (
		<div className="fb-icon">
			<FacebookLogin
				appId="621587972022008"
				autoLoad={false}
				fields="name,email,picture"
				callback={responseFacebook}
				cssClass="my-facebook-button-class"
				textButton=""
				icon={<img alt="facebook icon" height="30px" src={FacebookLogo} width="30px" />}
			/>
		</div>
	);
};

const mapStateToProps = (state) => ({
	...state
});

const mapDispatchToProps = (dispatch) => ({
	facebookLoginSuccess: (response) => dispatch(facebookLoginSuccess(response)),
	facebookLoginFailure: (response) => dispatch(facebookLoginFailure(response)),
	setAllValidItems: (firstName, lastName, email, phone) =>
		dispatch(setAllValidItems(firstName, lastName, email, phone)),
	loginSuccess: (phone) => dispatch(loginSuccess(phone)),
	loginFailure: () => dispatch(loginFailure()),
	verifyProcess: () => dispatch(verifyProcess())
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(FacebookLoginPage);
