import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

import {
  Dashboard as DashboardView,
  Settings as SettingsView,
  SignIn as SignInView,
  NotFound as NotFoundView,
  Verification as VerificationView,
  ChatApp as ChatAppView,
  Account as AccountView,
  // UserVerify as UserVerifyView,
  TermsOrMFA as TermsOrMFAView,
  Payment as PaymentView,
  Check as CheckView
} from './views';

const Routes = () => {
  return (
    <Switch>
      <Redirect exact from="/" to="/signin" />
      <RouteWithLayout component={DashboardView} exact layout={MainLayout} path="/dashboard" />
      <RouteWithLayout component={SettingsView} exact layout={MainLayout} path="/settings" />
      <RouteWithLayout component={ChatAppView} exact layout={MainLayout} path="/chat" />
      <RouteWithLayout component={CheckView} exact layout={MainLayout} path="/check" />
      <RouteWithLayout component={AccountView} exact layout={MainLayout} path="/account" />
      <RouteWithLayout component={PaymentView} exact layout={MainLayout} path="/payment" />
      <RouteWithLayout component={VerificationView} exact layout={MinimalLayout} path="/verify" />
      <RouteWithLayout component={TermsOrMFAView} exact layout={MinimalLayout} path="/initialize" />
      <RouteWithLayout component={SignInView} exact layout={MinimalLayout} path="/signin" />
      <RouteWithLayout component={NotFoundView} exact layout={MinimalLayout} path="/not-found" />
    </Switch>
  );
};

export default Routes;