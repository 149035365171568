import React from 'react';
import Modal from 'react-modal';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles({
  button: {
    height: '30px',
    float: 'right',
    marginLeft: '5px',
    marginTop: '10px',
    width: '100px',
    margin: 'auto'
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: 'white',
    border: '2px solid #000',
    boxShadow: 5,
    padding: (2, 4, 3)
  },
  typography: {
    marginTop: '20px !important',
    marginBottom: '10px'
  }
});
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    background: '#fff',
    display: 'flex',
    flexDirection: 'column',
    height: '150px',
    width: '250px',
    border: '2px solid #3f51b5'
  }
};

//Modal.setAppElement('#root');

const NameVerificationModal = props => {
  const classes = useStyles();

  const handleCloseModal = event => {
    event.preventDefault();
    props.closeModal();
  };

  return (
    <Modal
      isOpen={props.formState}
      onRequestClose={props.closeModal}
      style={customStyles}
      contentLabel="NameVerify">
      <Grid container justify={'center'} alignSelf={'center'}>
        <Grid item>
          <Typography
            variant="h6"
            component="h2"
            alignSelf={'center'}
            className={classes.typography}>
           Is this right First Name and Last Name?
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleCloseModal}>
            OK
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default NameVerificationModal;
