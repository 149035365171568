import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
//import clsx from 'clsx';
import { connect } from 'react-redux';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { ListItemText } from '@material-ui/core';
import GavelIcon from '@material-ui/icons/Gavel';
import HarassIcon from '../assets/images/harass.png';
import HandCuff from '../assets/images/handcuff.png';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { ErrorOutline } from '@material-ui/icons';
import lightGreen from '@material-ui/core/colors/lightGreen';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Button,
  Typography,
  LinearProgress,
  Grid,
  CircularProgress
} from '@material-ui/core';
import { setBackgroundCheck } from './actions';
import { postBackCheck, postBackgroundCheck } from '../../../API/page4BackgroundAPI';
import { BootstrapTooltip } from '../../../utils';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: '50px',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  },
  VerifiedUserIcon: {
    color: lightGreen[500]
  }
}));

const Background = (props) => {
  const classes = useStyles();

  const [count, setCount] = React.useState(-1);
  const [progress, setProgress] = React.useState(0);
  const [foundResult, setFoundResult] = React.useState({});
  const [apiCompleted, setApiCompleted] = React.useState(false);

  useEffect(() => {
    async function processCheck() {
      let phone = props.phone.indexOf("+1") !== -1 ? props.phone.replace("+1", "") : props.phone;
      const data = {
        firstName: props.firstName,
        lastName: props.lastName,
        address: props.userInfo.address1,
        phonenumber: phone
      };
      const status = await postBackCheck(data);
      setApiCompleted(true);
      if (status) {
        postBackgroundCheck({
          email: props.email,
          data: status[0]
        });

        setFoundResult({
          tracking: 'tracked',
          criminal: (status[0] && status[0].criminal && status[0].criminal.length) ? foundResult.criminal : [],
          bankruptcy: (status[0] && status[0].bankruptcy && status[0].bankruptcy.length) ? foundResult.bankruptcy : [],
          judgment: (status[0] && status[0].judgment && status[0].judgment.length) ? foundResult.judgment : []
        });
      } else {
        setFoundResult(null);
      }
    }
    processCheck();
  }, []);

  useEffect(() => {
    function tick() {
      if (apiCompleted || progress < 50) {
        setProgress((oldProgress) => (oldProgress >= 100 ? 100 : oldProgress + 1));
        switch (progress) {
          case 50:
            setCount(9);
            break;
          case 70:
            setCount(29);
            break;
          case 90:
            setCount(79);
            break;
          case 100:
            props.setBackgroundCheck({
              tracking: 'tracked',
              criminal: foundResult.criminal.length ? foundResult.criminal : null,
              bankruptcy: foundResult.bankruptcy.length ? foundResult.bankruptcy : null,
              judgment: foundResult.judgment.length ? foundResult.judgment : null
            });
            clearInterval(timer);
            break;
          default:
            break;
        }
      }
    }
    const timer = setInterval(tick, 125);
    return () => {
      clearInterval(timer);
    };
  });

  const returnStatus = (info) => {
    if (info.length) {
      return (
        <BootstrapTooltip title="Found records">
          <ErrorOutline style={{ color: 'red' }} />
        </BootstrapTooltip>
      );
    }
    return (
      <BootstrapTooltip title="No Records! Clean">
        <VerifiedUserIcon className={classes.VerifiedUserIcon} />
      </BootstrapTooltip>
    );
  };

  return (
    <Card className={classes.root}>
      <form autoComplete="off" noValidate>
        <CardHeader
          subheader={count < 79 ? 'Verification in progress..' : 'Verification Completed'}
          title="Background Check"
        />
        <Divider />
        <CardContent>
          {/* {foundResult ? */}
          <Grid container spacing={3}>
            <Grid item md={12} xs={12} className={classes.root}>
              <List component="nav" aria-label="main mailbox folders">
                <ListItem button>
                  <ListItemIcon>
                    <GavelIcon />
                  </ListItemIcon>
                  <ListItemText primary="Criminal Background check" />
                  {count <= 9 ? (
                    <CircularProgress size={25} />
                  ) : (
                    returnStatus(foundResult.criminal)
                  )}
                </ListItem>
                <Divider />
                <ListItem button>
                  <ListItemIcon>
                    <img src={HandCuff} height="30px" />
                  </ListItemIcon>
                  <ListItemText primary="Bankruptcy Report" />
                  {count <= 29 ? (
                    <CircularProgress size={25} />
                  ) : (
                    returnStatus(foundResult.bankruptcy)
                  )}
                </ListItem>
                <Divider />
                <ListItem button>
                  <ListItemIcon>
                    <img src={HarassIcon} height="30px" />
                  </ListItemIcon>
                  <ListItemText primary="Judgement Records" />
                  {count < 79 ? (
                    <CircularProgress size={25} />
                  ) : (
                    returnStatus(foundResult.judgment)
                  )}
                </ListItem>
              </List>
            </Grid>
            <Grid item md={12} xs={12} className={classes.root}>
              {progress >= 100 ? <LinearProgress variant="determinate" color="secondary" value={progress} /> : <LinearProgress color="secondary" />}
              <LinearProgress variant="determinate" value={progress} />
            </Grid>
          </Grid>
          {/* : <Grid container spacing={3} justifyContent="center" alignContent="center">
              <Grid item md={12} xs={12} className={classes.root}>
                <Typography color="primary">Sorry, User data Not found</Typography>
                <Typography color="primary">Please enter correct information</Typography>
                <Button color="primary" variant="contained" onClick={() => props.history.push('/signin')}>Exit App</Button>
              </Grid>
            </Grid>
          } */}
        </CardContent>
      </form>
    </Card>
  );
};

Background.prototype = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string,
  selectedDate: PropTypes.instanceOf(Date),
  history: PropTypes.object,
  setBackgroundCheck: PropTypes.func
};

const mapStateToProps = (state) => {
  console.log(' all props', state);
  return {
    firstName: state.rule.page1.firstName,
    lastName: state.rule.page1.lastName,
    formState: state.rule.form.status,
    selectedDate: state.rule.page2.selectedDate,
    email: state.rule.page1.email,
    userInfo: state.rule.page2.userInfo,
    phone: state.rule.page1.phone
  };
};

const mapDispatchToProps = (dispatch) => ({
  setBackgroundCheck: (check) => dispatch(setBackgroundCheck(check))
});
export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Background);
