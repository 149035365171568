import React from 'react';
import Payment from '../Payment';
import UserVerify from './UserVerify';
import { getUserPro, postPro } from './Request';
import { connect } from 'react-redux';
import { setProStatus } from '../Verification/components/actions';

const Check = (props) => {
  React.useEffect(() => {
    getUserInfo();
  }, []);

  const getUserInfo = async () => {
    const pro = await getUserPro();
    props.setProStatus({ ...pro });
  };

  const updatePayment = async (plan) => {
    const pro = await postPro({ plan: plan });
    props.setProStatus({ ...pro });
  };

  return (
    <div>
      {props.pro &&
        (props.pro.isPro ? (
          <UserVerify data={props.pro} getUserInfo={getUserInfo} />
        ) : (
            <Payment updatePayment={updatePayment} />
          ))}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    pro: state.rule.pro
  };
};

const mapDispatchToProps = (dispatch) => ({
  setProStatus: (status) => dispatch(setProStatus(status))
});

export default connect(mapStateToProps, mapDispatchToProps)(Check);
