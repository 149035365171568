import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import IconButton from "@material-ui/core/IconButton";

export class CameraFeed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hidePreview: true
    };
  }

  processDevices(devices) {
    devices.forEach(device => {
      this.setDevice(device);
    });
  }

  async setDevice(device) {
    const { deviceId } = device;
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: false,
      video: {
        deviceId,
        facingMode: "user",
        width: { min: 720, ideal: 1280, max: 1280 },
        height: { min: 720, ideal: 720, max: 1280 }
      }
    });
    this.videoPlayer.srcObject = stream;
    this.videoPlayer.play();
  }

  async componentDidMount() {
    const cameras = await navigator.mediaDevices.enumerateDevices();
    this.processDevices(cameras);
  }

  takePhoto = () => {
    const context = this.canvas.getContext("2d");
    this.canvas.width = 1280;
    this.canvas.height = 720;
    context.drawImage(this.videoPlayer, 0, 0, 1280, 720);

    document.querySelector("canvas").style.margin = "auto";
    document.querySelector("canvas").style.width = "500px";
    this.setState({ hidePreview: false });

    const image = this.canvas.toDataURL("image/png", 1.0);
    this.props.sendFile(image);
  };

  render() {
    return (
      <Grid
        container
        style={{
          alignContent: "center",
          justifyContent: "space-around",
          display: "flex",
          flexDirection: "column"
        }}
      >
        <Grid
          item
          style={{
            justifyContent: "space-around",
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <Grid
            item
            style={{
              border: "2px solid #3f51b5",
              marginTop: "5px",
              display: "flex",
              flexDirection: "column",
              width: "auto",
              alignItems: "center",
              margin: '20px'
            }}
          >
            <video
              ref={ref => (this.videoPlayer = ref)}
              width="500"
              height="300"
            />

            <IconButton
              color="primary"
              onClick={this.takePhoto}
              component="span"
              style={{ border: "2px solid #3f51b5", marginBottom: "5px" }}
            >
              <PhotoCamera />
            </IconButton>
          </Grid>

          <canvas style={{ position: "absolute" }} width="500" height="280" ref={ref => (this.canvas = ref)} />
        </Grid>
      </Grid>
    );
  }
}
