
export const localeUtil = (props) => {
  return { address1: props.userInfo.address1, city: props.userInfo.city, state: props.userInfo.state }
}

export const subjectUtil = (props) => {
  return {
    "subjectName": `${props.firstName} ${props.lastName}`,
    "subjectEmail": props.email,
    "subjectMobile": props.phone,
    "subjectToken": ""
  }
}

export const longQuestionTemplate = (props, locale, subjectResponse) => {
  return {
    "subjectId": subjectResponse.subjectId,
    "templateId": "11111111111111111111111111111168",
    "templateInput": {
      "DB.0001.01": props.firstName,
      "DB.0002.01": props.lastName,
      "DB.0032.01": props.email,
      "DB.0033.01": props.phone,
      "DB.0003.01": props.userInfo.address1,
      "DB.0004.01": props.userInfo.city,
      "DB.0005.01": props.userInfo.state,
      "DB.0006.01": props.selectedDate,
      "DB.0009.01": props.userInfo.marritalStatus,
      "DB.0030.01": props.userInfo.gender,
      "DB.0011.01": locale.lat,
      "DB.0012.01": locale.long,
      "DB.0103.01": props.userInfo.zip
    }
  }
}

export const responseTemplate = (props, sample, hash, agent, answer) => {
  return {
    email: props.email,
    customerId: sample.customerId,
    hash: hash.substring(hash.length - 40),
    examId: sample.examId,
    examData: {
      examId: sample.examId,
      userAgent: agent.ua,
      userLanguage: "en-US",
      userTZ: new Date().getTimezoneOffset(),
      userIP: agent.cip,
      userResolution: `${window.innerWidth}x${window.innerHeight}`,
      examSamples: answer
    }
  }
}