import React, { useState } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Badge, Hidden, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
import { AccountBox } from '@material-ui/icons';
import topBar from '../../../../views/SignIn/assets/bar2.jpg';
import YoorzSvg from '../../../../icons/Yoorz/yoorzAssetBackdrop.svg';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { logoutSuccess } from '../../../../actions';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
    backgroundImage: `url(${topBar}) !important`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  },
  fab: {
    margin: theme.spacing(1),
    backgroundColor: '#fff'
  }
}));

const Topbar = (props) => {
  const { className, onSidebarOpen, ...rest } = props;
  const { history } = props;
  const classes = useStyles();

  const [notifications] = useState([]);

  const handleSignout = async (e) => {
    e.preventDefault();
    props.logoutSuccess();
    history.push('/signin');
  };

  return (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      <Toolbar>
        <RouterLink to="/">
          {/* <Fab aria-label="delete" className={classes.fab} variant="primary">
            <img alt="Logo" height="50px" src={YoorzSvg} />
          </Fab> */}
          {/* <Avatar alt="Remy Sharp" src={YoorzSvg} className={classes.avatar} /> */}
          <img alt="Logo" src={YoorzSvg} height="65px" width="auto" />
        </RouterLink>
        <div className={classes.flexGrow} />
        <Hidden mdDown>
          <IconButton color="inherit">
            <Badge badgeContent="Pro" color="primary" onClick={() => history.push('/payment')}>
              <AccountBox />
            </Badge>
          </IconButton>
          <IconButton color="inherit">
            <Badge badgeContent={notifications.length} color="primary" variant="dot">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <IconButton className={classes.signOutButton} color="inherit" onClick={handleSignout}>
            <InputIcon />
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
  logoutSuccess: PropTypes.func
};

const mapStateToProps = (state) => {
  return {
    pro: state.rule.pro
  };
};
const mapDispatchToProps = (dispatch) => ({
  logoutSuccess: () => dispatch(logoutSuccess())
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Topbar);
