import { blAddress, userEmail, apiToken } from '../UserInfo';
import { YoorzBlockAPI } from '../YoorzAPI';
import axios from 'axios';
import { URL } from '../api';

export const postBlPage5 = async (info) => {
  const data = {
    address: blAddress,
    ...info
  };
  try {
    const page5 = await YoorzBlockAPI().post('/page5/addpage5', {
      ...data
    });
    if (page5.data) {
      return true;
    }
    return false;
  } catch (err) {
    return false;
  }
};

export const getBlPage5 = async () => {
  try {
    const page5 = await YoorzBlockAPI().post('/page5/getpage5', {
      address: blAddress,
    });
    if (page5.data.address !== '0x0000000000000000000000000000000000000000') {
      return page5.data;
    }
    return false;
  } catch (err) {
    return false;
  }
};

export const postUploadIdRndImage = async (data) => {
  // `${URL}/idrnd/image?email=${userEmail}`,
  try {
    const uploadImage = await axios({
      method: 'POST',
      url: `${URL}/idrnd/upload?email=${userEmail}`,
      data: data,
      mode: 'cors',
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${apiToken}`
      }
    });
    return uploadImage.data;
  } catch (err) {
    return err;
  }
};

export const postTestLiveness = async (data) => {
  try {
    const testLiveness = await axios({
      method: 'POST',
      url: `${URL}/liveness`,
      data: data,
      mode: 'cors',
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${apiToken}`
      }
    });
    return testLiveness.data;
  } catch (err) {
    return err;
  }
};