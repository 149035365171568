import { YoorzAPI } from '../../YoorzAPI';
import { getCookie } from '../../api';
import { userEmail } from '../../UserInfo';
import axios from 'axios';

export const postUpdateSearch = async (result) => {
  try {
    const update = await YoorzAPI().post('/update/search', {
      email: userEmail,
      searchData: { ...result[0], time: new Date() }
    });
    if (update.msg) {
      return true;
    }
    return false;
  } catch (err) {
    return err;
  }
};

export const getSearch = async () => {
  try {
    const user = await YoorzAPI().get(`/search/${userEmail}`);
    if (user.data.length) {
      return user.data;
    }
    return false;
  } catch (err) {
    return err;
  }
};

export const getUserPro = async () => {
  try {
    const user = await YoorzAPI().get(`/pro/${userEmail}`);
    console.log('Get User Pro ', user);
    if (user.data) {
      return user.data;
    }
    return false;
  } catch (err) {
    return err;
  }
};

export const postPro = async (info) => {
  console.log('Post serach ', info);
  try {
    const user = await YoorzAPI().post('/pro', {
      ...info,
      email: userEmail
    });
    console.log('Post User Pro ', user);
    if (user.data) {
      return user.data;
    }
    return false;
  } catch (err) {
    return err;
  }
};