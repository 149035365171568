import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import logo2 from "../assets/images/IdentityDetect.png";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Divider,
  Grid,
  Button,
  Typography
} from "@material-ui/core";
import Loading from '../../Loading';
import { displayForm, hideForm, setConverusCompleted } from "./actions";
import { getConverusSubjectId, postConverusQuestionTemplate, getConverusQuestionsJSON } from '../../../API/page4BackgroundAPI'
import { getAgent, postConverusSubmitExamLong, postBlPage3, getConverusResultLong } from '../../../API/page3ConverusAPI'
import { postLocateAddress } from '../../../API/page2AddressAPI'
// import sam from './Sample_Converus.json'
import { answerOptions } from './ConverusConstants'
import { localeUtil, subjectUtil, longQuestionTemplate, responseTemplate } from './converusLongUtil'

const useStyles = makeStyles({
  root: {
    marginTop: "25px",
    width: "100%",
    minHeight: '50%',
    maxWidth: '800px',
    margin: 'auto',
    marginTop: '30px'
  },
  buttonArea: {
    textAlign: 'center',
    marginTop: '100px'
  },
  NextButtonArea: {
    marginTop: '100px',
    textAlign: 'right'
  },
  yesOrNo: {
    margin: '20px 0'
  },
  button: {
    marginTop: "15px",
    height: "40px",
    float: "left",
    width: "10%",
    marginLeft: "10%"
  },
  button2: {
    marginTop: "15px",
    height: "40px",
    float: "right",
    width: "10%",
    marginRight: "10%"
  },
  continueButtonNext: {
    textAlign: 'right',
    marginTop: '100px'
  },
  errorText: { marginTop: '10px ' },
  errorContents: { display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '30px', textAlign: 'center' }
});

let answer = [
  {
    at: "ES",
    ts: new Date().getTime(),
    id: "",
    dv: ""
  }
];
let timer;
let sample = [];
let requestData = null

const ConverusLong = props => {
  const classes = useStyles();

  const [hash, setHash] = React.useState("");
  const [indexPush, setIndexPush] = React.useState(0);
  const [currentAnswer, setCurrentAnswer] = React.useState("none")
  const [doneStep, setDoneStep] = React.useState(false)
  const [isTimeout, setIsTimeout] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);
  // const [seconds, setSeconds] = React.useState(0);
  // const [isActive, setIsActive] = React.useState(false);

  // console.log(indexPush);


  React.useEffect(() => {
    async function initalizeConverus() {

      const locale = await postLocateAddress(localeUtil(props));
      const subjectResponse = await getConverusSubjectId(subjectUtil(props));
      const examResponse = await postConverusQuestionTemplate(longQuestionTemplate(props, locale, subjectResponse));
      const questionsResponse = await getConverusQuestionsJSON(examResponse.examId);

      // setExamData(questionsResponse.templateItems)
      sample = questionsResponse.templateItems
      requestData = questionsResponse
      answer.push({
        at: "QS",
        ts: new Date().getTime(),
        id: questionsResponse.templateItems[indexPush].itemId,
        dv: questionsResponse.templateItems[indexPush].itemType
      })
      setHash(examResponse.examUrl)
    }
    initalizeConverus()

  }, []);

  const submitResults = async () => {
    answer.push({
      at: "EF",
      ts: new Date().getTime(),
      id: "",
      dv: ""
    });
    const agent = await getAgent();
    const currentExamId = await postConverusSubmitExamLong(responseTemplate(props, requestData, hash, agent, answer));
    const h = hash
    setHash("")
    setTimeout(async () => {
      setSubmitted(true)
      const result = await getConverusResultLong(currentExamId.examId);
      setHash(h)
      if (result.examScore1 >= .5 && `${result.examScore1}`.indexOf("E") === -1) {
        var currDate = new Date();
        currDate = currDate.getMonth() + 1 + "/" + currDate.getDate() + "/" + currDate.getFullYear();
        // console.log(`${currentExamId.examId} ${props.email} ${result.examScore1} ${currDate}`)
        await postBlPage3({
          examid: currentExamId.examId,
          examurl: props.email,
          examresult: result.examScore1,
          examdate: currDate
        });
        props.setConverusCompleted({
          status: true,
          examUrl: hash,
          examScore: result.examScore1,
          examInfo: {
            examineeId: result.examineeId,
            examId: currentExamId.examId
          }
        });
        return;
      }
      props.setConverusCompleted({
        status: false, examUrl: hash,
        examScore: null,
        examInfo: {
          examineeId: null,
          examId: null
        }
      });
    }, 15000);
  };

  const initializeTimer = (questionType) => {
    let second = null
    if (questionType === 'Simple') {
      second = 5000
    } else {
      second = 10000
    }
    timer = setTimeout(() => {
      setIsTimeout(true)
      timeoutScreen()
    }, 10000);
  }

  const stopTimer = () => {
    clearTimeout(timer);
    timer = null;
  }


  const timeoutScreen = () => {
    setTimeout(() => {
      setIsTimeout(false);
      handleContinue()
    }, 2000)
  }

  // console.log(isTimeout)

  // const incrementIndex = () => setIndexPush(indexPush + 1);

  React.useEffect(() => {
    // console.log('Input push useEffect', indexPush)
    if (indexPush) {
      if (sample[indexPush].itemType !== 'Instruction') {
        initializeTimer(sample[indexPush].itemType)
      }

      answer.push({
        at: "QS",
        ts: new Date().getTime(),
        id: sample[indexPush].itemId,
        dv: sample[indexPush].itemType
      })
      setDoneStep(false)
    }
  }, [indexPush]);

  const handleContinue = () => {
    setCurrentAnswer("none")
    switch (sample[indexPush].itemType) {
      case 'Instruction':
        answer.push({
          at: "QF",
          ts: new Date().getTime(),
          id: sample[indexPush].itemId,
          dv: sample[indexPush].itemLabel01
        });
        break;
      default:
        answer.push({
          at: "QF",
          ts: new Date().getTime(),
          id: sample[indexPush].itemId,
          dv: answerOptions[currentAnswer].value
        });
    }

    if (sample.length - 1 === indexPush) {
      submitResults();
    } else {
      setIndexPush(indexPush + 1);
    }
  };

  const handleKeyDown = (selection) => {
    // console.log(answerOptions)
    setCurrentAnswer(selection);
    answer.push({
      at: "KD",
      ts: new Date().getTime(),
      id: sample[indexPush].itemId,
      dv: answerOptions[selection].id
    });
  }

  const handleKeyUp = () => {
    setDoneStep(true);
    answer.push({
      at: "KU",
      ts: new Date().getTime(),
      id: sample[indexPush].itemId,
      dv: answerOptions[currentAnswer].id
    });
    stopTimer()
  }

  const displayQuestion = () => {
    if (indexPush === sample.length) {
      return null;
    }
    if (sample[indexPush].itemQuestion.indexOf("list") !== -1) {
      const res = sample[indexPush].itemQuestion.split(": [[list:");
      return <Typography variant="h6" id="question" key={indexPush}>{`${res[0]} ${res[1].slice(0, -2)}`}</Typography>;
    }
    if (sample[indexPush].itemQuestion.indexOf("array") !== -1) {
      const res = sample[indexPush].itemQuestion.split(" [[array:");
      return <Typography variant="h6" id="question" key={indexPush}>{`${res[0]} ${res[1].slice(0, -2)}`}</Typography>;
    }
    if (sample[indexPush].itemQuestion.indexOf("imglink") !== -1) {
      const ques = sample[indexPush].itemQuestion.split(": [[imglink:");
      return <Grid item xs={12}>
        <center>
          <Typography
            variant="h6"
            id="question"
            key={indexPush}
            gutterBottom
          >
            {ques[0]}
          </Typography>
        </center>
        <center>
          <img
            id="BotImg"
            alt="no converus image"
            src={ques[1].slice(0, -2)}
            height="300px"
            width="400px"
          />
        </center>
      </Grid>
    }
    return <Typography variant="h6" id="question" key={indexPush}>{sample[indexPush].itemQuestion}</Typography>;
  };

  return (
    <Card className={classes.root}>
      <CardActionArea style={{ display: "flex" }}>
        <CardMedia
          component="img"
          image={logo2}
          height="60px"
          style={{ width: "243px" }}
          alignSelf={"center"}
        />
      </CardActionArea>
      <Divider />
      {submitted && !props.converusState ? <CardContent id="content">
        <Grid container spacing={4} className={classes.errorContents}>
          <Typography color="primary" variant="h4" className={classes.errorText}>Verification Incomplete</Typography>
          <Typography color="primary" variant="h4" className={classes.errorText}>We apologize the verification process could not be completed.</Typography>
          <Typography color="primary" variant="h4" className={classes.errorText}>We do not store any of your personal information and for security protection you will need to restart the process from the beginning.</Typography>
          <Typography color="primary" variant="h4" className={classes.errorText}>Thank you</Typography>
          <Typography color="primary" variant="h4">Yoorz Team</Typography>
          <Button color="primary" variant="contained" className={classes.errorText} onClick={() => props.history.push('/signin')}>Exit App</Button>
        </Grid>
      </CardContent> :
        <CardContent id="content">
          {!hash && <Loading />}
          {hash && (
            <>
              {
                isTimeout ?
                  <Grid container spacing={4} justify={"center"} alignContent={"center"}>
                    < Typography style={{ color: 'red', textAlign: 'center' }}>Timeout expired</Typography>
                  </Grid>
                  :
                  <>
                    <Grid item xs={12}>
                      <center>
                        {displayQuestion()}
                      </center>
                    </Grid>
                    {sample[indexPush].itemLabel02 && (<Grid item xs={12} className={classes.yesOrNo}>
                      <Button
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        startIcon={<DoneIcon />}
                        onClick={() => handleKeyDown("yes")}
                        disabled={currentAnswer !== "none"}
                      >
                        {sample[indexPush].itemLabel01}
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        className={classes.button2}
                        startIcon={<CloseIcon />}
                        onClick={() => handleKeyDown("no")}
                        disabled={currentAnswer !== "none"}
                      >
                        {sample[indexPush].itemLabel02}
                      </Button>
                    </Grid>)}
                    {sample[indexPush].itemLabel02 && (<Grid item xs={12} className={doneStep ? classes.continueButtonNext : classes.buttonArea}>
                      {doneStep ? (<Button
                        variant="contained"
                        color="primary"
                        onClick={handleContinue}
                      >
                        Next
                      </Button>) : (<center><Button
                        variant="contained"
                        color="primary"
                        onClick={handleKeyUp}
                        disabled={currentAnswer === "none"}
                      >
                        Submit
              </Button></center>)}
                    </Grid>)}
                    {!sample[indexPush].itemLabel02 && (<Grid item xs={12} className={classes.NextButtonArea}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleContinue}
                      >
                        Continue
              </Button>
                    </Grid>)}
                  </>}
            </>)}
        </CardContent >}
      <Divider />
    </Card >
  );
};

ConverusLong.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  userInfo: PropTypes.object.isRequired,
  selectedDate: PropTypes.string.isRequired,
  converusState: PropTypes.bool.isRequired
};

const mapStateToProps = state => {
  return {
    firstName: state.rule.page1.firstName,
    lastName: state.rule.page1.lastName,
    phone: state.rule.page1.phone,
    email: state.rule.page1.email,
    userInfo: state.rule.page2.userInfo,
    selectedDate: state.rule.page2.selectedDate,
    converusState: state.rule.page3.status
  };
};

const mapDispatchToProps = dispatch => ({
  displayForm: modal => dispatch(displayForm(modal)),
  hideForm: () => dispatch(hideForm()),
  setConverusCompleted: status => dispatch(setConverusCompleted(status))
});

export default connect(mapStateToProps, mapDispatchToProps)(ConverusLong);
