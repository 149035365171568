import React from 'react';
import { makeStyles } from "@material-ui/styles";
import { Grid, Typography, TextField, Button } from "@material-ui/core";
import PropTypes from 'prop-types';
import { getUserPhoneInfo } from '../../API/CommonUtilsAPI';
import { onboardSetUser } from '../../api';
import PhoneVerificationModal from '../Verification/modals/PhoneVerificationModal';
import { setBlAdress, setBlToken } from '../../UserInfo';

const useStyles = makeStyles({
  terms: {
    color: "#7355d2",
    marginBottom: "10px"
  },
  getUser: {
    width: '150px'
  },
  userId: {
    marginLeft: '20px',
    width: '100px'
  }
});

const ForgotPassword = props => {
  const classes = useStyles();
  //const [isTokenValid, setIsTokenValid] = React.useState(false);
  const [userId, setUserId] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [twillioNumber, setTwillioNumber] = React.useState("");
  const [displayPhoneModal, setDisplayPhoneModal] = React.useState(false);

  const handlePhone = event => {
    setEmail(event.target.value);
  };

  const handleGetInfo = async () => {
    const info = await getUserPhoneInfo(email);
    if (info) {
      await onboardSetUser(email);
      setTwillioNumber(info.phonenumber);
      setDisplayPhoneModal(true);
    }
  };

  const handleVerifyCode = async (status) => {
    console.log(status);
    if (status) {
      props.updateResetStatus('resetPassword');
    }
    setDisplayPhoneModal(false);
  };


  return (
    <>
      {displayPhoneModal ?
        <PhoneVerificationModal
          formState={props.formState}
          closeModal={props.hideForm}
          phone={twillioNumber}
          handleVerifyCode={handleVerifyCode}
          isModalView={false}
        />
        : <Grid
          container
          direction="column"
          justify="center"
          alignContent="center"
          spacing={3}
          lg={12}
        >
          <Grid item>
            <Typography className={classes.terms} variant="h6">
              Please verify yourself, we will send a One Time Token to your registered mobile
          </Typography>
            <Typography className={classes.terms} variant="h6">
              Please enter the email ID used to register
          </Typography>
          </Grid>
          <Grid item>
            <TextField
              className={classes.textField}
              fullWidth
              label="email"
              name="email"
              margin="normal"
              onChange={handlePhone}
              type="text"
              value={email}
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <Typography className={classes.terms} variant="h6">
              {userId}
            </Typography>
          </Grid>
          <Grid item style={{ textAlign: 'center' }}>
            <Button
              className={classes.getUser}
              size="large"
              type="submit"
              variant="contained"
              color="primary"
              onClick={handleGetInfo}
            >
              Verify
			      </Button>
            <Button
              className={classes.userId}
              size="large"
              type="submit"
              variant="contained"
              color="primary"
              onClick={() => props.updateResetStatus("")}
            >
              Back
			  </Button>
          </Grid>
        </Grid>
      }
    </>
  );
};

ForgotPassword.prototype = {
  updateResetStatus: PropTypes.func.isRequired
};

export default ForgotPassword;
