import { blAddress, userEmail } from '../UserInfo';
import { YoorzBlockAPI, YoorzAPI } from '../YoorzAPI';


export const postBlPage3 = async (info) => {
  const date = new Date();
  const data = {
    address: blAddress,
    examid: info.examid,
    examurl: info.examurl,
    examresult: info.examresult,
    examdate: info.examdate
  };
  try {
    const page3 = await YoorzBlockAPI().post('/page3/addpage3', {
      ...data
    });
    if (page3.data) {
      return true;
    }
    return false;
  } catch (err) {
    return err;
  }
};

export const getBlPage3 = async () => {
  try {
    const page3 = await YoorzBlockAPI().post('/page3/getpage3', {
      address: blAddress,
    });
    if (page3.data) {
      return page3.data;
    }
    return false;
  } catch (err) {
    return err;
  }
};

export const postGetConverusKBAQuestions = async (data) => {
  try {
    const kbaQuestions = await YoorzAPI().post(`/kbagen?email=${userEmail}`, [...data]);
    if (kbaQuestions.data.SimpleQuestions.length) {
      return kbaQuestions.data.SimpleQuestions;
    }
    return false;
  } catch (err) {
    return err;
  }
};

export const postGetConverusKBAResults = async (request) => {
  try {
    const kbaResult = await YoorzAPI().post(`/kba/result?email=${userEmail}`, {
      ...request
    });
    if (kbaResult.data) {
      return kbaResult.data;
    }
    return false;
  } catch (err) {
    return err;
  }
};

export const getComplexKBAQuestions = async () => {
  try {
    const kbaResult = await YoorzAPI().get(`/kba/questions?email=${userEmail}`);
    if (kbaResult.data) {
      return kbaResult.data;
    }
    return false;
  } catch (err) {
    return err;
  }
};

export const getAgent = async () => {
  try {
    const response = await YoorzAPI().get("/agent");
    if (response.data.ua) {
      return response.data
    }
    return false
  } catch (err) {
    return err;
  }
}

export const postConverusSubmitExamLong = async (data) => {
  try {
    const response = await YoorzAPI().post("/identity/examsubmit", {
      ...data
    });
    if (response.data.examId) {
      return response.data
    }
    return false
  } catch (err) {
    return err;
  }
}

export const getConverusResultLong = async (subjectId) => {
  try {
    const response = await YoorzAPI().get(`/identity/exam/result/${subjectId}?email=${userEmail}`)
    if (response.data.examScore1) {
      return response.data
    }
    return false
  } catch (err) {
    return err;
  }
}