import React from 'react';
// import ConverusLong from './ConverusLong';
import ConverusComplex from './ConverusComplex';
import ConverusLong from './ConverusLong1';
import { getComplexKBAQuestions } from '../../../API/page3ConverusAPI';
import Loading from '../../Loading';

const ConverusDecide = props => {

  const [isLoading, setIsLoading] = React.useState(true);
  const [response, setResponse] = React.useState({});

  React.useEffect(() => {
    const decideKBAformat = async () => {
      const res = await getComplexKBAQuestions();
      setResponse(res);
      setIsLoading(false);
    };

    decideKBAformat();
  }, []);

  if (isLoading) {
    return <Loading />;
  } else if (response.isComplexSuccess) {
    return <ConverusComplex ComplexQuestions={response.result} />;
  }
  return <ConverusLong />;
};

export default ConverusDecide;