import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  setAllValidItems,
  setPage1Verified,
  setPage2Verified,
  setPage3Verified,
  setConverusInfo,
  setBackgroundCheck,
  setSelectedDate,
  setExamUrl,
  setExamId,
  setOnfidoId,
  setOnFidoImageUrl,
  setConverusCompleted,
  setIdrndUploadeStatus
} from './components/actions';
import { makeStyles } from '@material-ui/styles';
import SocialVerification from './components/SocialVerification';
import AddressVerification from './components/AddressVerification';
import 'typeface-roboto';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Grid, Button, Tooltip } from '@material-ui/core';
import transform from './transform';
//import Converus from './components/Converus';
import Stepper from '@material-ui/core/Stepper';
import Avatar from '@material-ui/core/Avatar';
import Step from '@material-ui/core/Step';
//import StepButton from '@material-ui/core/StepButton';
import Acuant from './components/Acuant';
import IdRnD from './components/IdRnD';
import Password from './components/Password';
import BackConcent from './components/BackConcent';
import * as Request from './Request';
import Loading from '../Loading';
import { Person, PersonAdd, Policy, Gavel, Camera, AssignmentInd, Lock, Help } from '@material-ui/icons';
import { URLAVATAR } from '../../api';
import ConverusDecide from './components/ConverusDecide';
import { postBlPage1, postBlMobileRegister } from '../../API/page1PersonalAPI';
import { postBlPage2, getBlPage2 } from '../../API/page2AddressAPI';
import { postBlPage3, getBlPage3 } from '../../API/page3ConverusAPI';
import { postBlPage4, getBlPage4 } from '../../API/page4BackgroundAPI';
import { postBlPage5, getBlPage5 } from '../../API/page5IdrndAPI';

const useStyles = makeStyles({
  progress: {
    flexGrow: 1
  },
  container: {
    display: 'flex',
    width: 'auto',
    marginTop: '50px'
  },
  button: {
    marginTop: '20px',
    marginLeft: '5px',
    width: '150px',
    alignSelf: 'center'
  },
  buttonTooltip: {
    marginTop: '20px',
    marginLeft: '10px',
    width: '10px',
    alignSelf: 'center'
  },
  margin: {
    width: '375px'
  },
  completed: {
    display: 'inline-block'
  },
  stepper: {
    marginTop: '25px'
  }
});

const getSteps = () => {
  return ['Name', 'Address', 'Behavior', 'Background', 'Liveliness', 'Document', 'Password'];
};

const getStepIcons = (index, activeStep) => {
  const icons = [
    <Person style={{ color: index <= activeStep ? '#ffffff' : '#3f51b5' }} />,
    <PersonAdd style={{ color: index <= activeStep ? '#ffffff' : '#3f51b5' }} />,
    <Policy style={{ color: index <= activeStep ? '#ffffff' : '#3f51b5' }} />,
    <Gavel style={{ color: index <= activeStep ? '#ffffff' : '#3f51b5' }} />,
    <Camera style={{ color: index <= activeStep ? '#ffffff' : '#3f51b5' }} />,
    <AssignmentInd style={{ color: index <= activeStep ? '#ffffff' : '#3f51b5' }} />,
    <Lock style={{ color: index <= activeStep ? '#ffffff' : '#3f51b5' }} />
  ];
  return icons[index];
};

const Verification = (props) => {
  const classes = useStyles();

  const completed = {};
  const { history } = props;
  const { firstName, lastName, email, phone } = transform(props.social);
  const firstname = props.firstName ? props.firstName : firstName;
  const lastname = props.lastName ? props.lastName : lastName;
  const emailAddress = props.email ? props.email : email;
  const phoneNo = props.phone ? props.phone : phone;
  // const examId = props.examId;
  const [activeStep, setActiveStep] = React.useState(0);
  // const [completed, setCompleted] = React.useState({});
  const steps = getSteps();
  const [count, setCount] = React.useState(0);
  const [nextButtonStatus, setNextButtonStatus] = React.useState(true);
  const [loading, setLoading] = React.useState(true);

  // to delete ( only to test locally)
  // const [loading, setLoading] = React.useState(false);
  // React.useEffect(() => {
  //   setLoading(false);
  //   props.setAllValidItems('Joshua', 'Babu', 'joshua@adroitts.com', '+17742086930');
  //   props.setConverusInfo({
  //     address1: '44267 meandering Ter',
  //     address2: null,
  //     city: 'Ashburn',
  //     country: 'USA',
  //     gender: 'male',
  //     marritalStatus: 'married',
  //     state: 'VA',
  //     zip: '20147'
  //   });
  //   props.setSelectedDate(new Date('10/05/1989'.replaceAll('/', ',')));
  //   setLoading(true);
  // }, []);

  // const updateVerifyProfileInfo = async () => {
  //   //setLoading(false);
  //   let noData = true;
  //   const page2 = await getBlPage2();
  //   const page3 = await getBlPage3();
  //   const page4 = await getBlPage4();
  //   const page5 = await getBlPage5();

  //   console.log('page2: ', page2);
  //   console.log('page3: ', page3);
  //   console.log('page4: ', page4);
  //   console.log('page5: ', page5);
  //   //setLoading(true);
  //   if (page2 && page2.address !== '0x0000000000000000000000000000000000000000') {
  //     noData = false;
  //     const stateZip = page2.stateandzip.split('-');
  //     const add = page2.streetaddress.split(',');

  //     props.setConverusInfo({
  //       address1: add[0],
  //       address2: add[1] ? add[1] : null,
  //       city: page2.city,
  //       country: 'USA',
  //       gender: page2.gender,
  //       marritalStatus: page2.martialstatus,
  //       state: stateZip[0],
  //       zip: stateZip[1]
  //     });
  //     props.setSelectedDate(new Date(page2.dob.replaceAll('/', ',')));
  //     setActiveStep(2);
  //     setCount(2);
  //   }

  //   if (page3 && page3.address !== '0x0000000000000000000000000000000000000000') {
  //     const data = {
  //       examUrl: page3.examurl,
  //       examId: page3.examid,
  //       examScore: page3.examresult,
  //       examInfo: {
  //         examId: page3.examid,
  //         examineeId: page3.examurl
  //       }
  //     };

  //     props.setConverusCompleted({ status: true, ...data });
  //     setActiveStep(3);
  //     setCount(3);
  //   }

  //   if (page4 && page4.address !== '0x0000000000000000000000000000000000000000') {
  //     props.setBackgroundCheck({
  //       isverified: true,
  //       tracking: page4.tracking,
  //       criminal: page4.hasCriminalOffense,
  //       bankruptcy: page4.hasOffense,
  //       judgment: page4.hasSexOffense
  //     });
  //     setActiveStep(4); setCount(4);
  //   }

  //   if (page5 && page5.address !== '0x0000000000000000000000000000000000000000') {
  //     props.setIdrndUploadeStatus(true);
  //     props.setOnFidoImageUrl(page5.imageurl);
  //     setActiveStep(5); setCount(5);
  //   }
  //   return noData;
  //   //Request.postVerifyUserProfile(props.email)
  //   //  .then((profile) => {
  //   //    const { page1, page2, page3, page4, page5 } = profile;
  //   //    const { firstName, lastName, phone } = page1;
  //   //    const { address1, address2, city, country, gender, marritalStatus, state, zip } = page2;
  //   //    const { examId, examUrl, examResult } = page3;
  //   //    const { hasCriminalOffense, hasOffense, hasSexOffense, tracking } = page4;
  //   //    const { onFidoId, imageUrl } = page5;
  //   //    props.setOnFidoImageUrl(imageUrl);
  //   //    props.setOnfidoId(onFidoId, null);
  //   //    props.setAllValidItems(firstName, lastName, profile.email, phone);
  //   //    props.setConverusInfo({
  //   //      address1,
  //   //      address2,
  //   //      city,
  //   //      country,
  //   //      gender,
  //   //      marritalStatus,
  //   //      state,
  //   //      zip
  //   //    });
  //   //    props.setSelectedDate(page2.dob);
  //   //    props.setExamId(examId);
  //   //    props.setExamUrl(examUrl);
  //   //    props.setBackgroundCheck({
  //   //      offenderCount: hasCriminalOffense,
  //   //      hasOffense,
  //   //      hasSexOffense,
  //   //      tracking
  //   //    });
  //   //    setLoading(true);
  //   //    if (profile.isActive) {
  //   //      return history.push('/dashboard');
  //   //    }
  //   //    if (page5.isVerified && page4.isVerified && page3.isVerified && page2.isVerified) {
  //   //      setActiveStep(5);
  //   //      return setCount(6);
  //   //    }
  //   //    if (page4.isVerified && page3.isVerified && page2.isVerified) {
  //   //      setActiveStep(4);
  //   //      return setCount(4);
  //   //    }
  //   //    if (page3.isVerified && page2.isVerified) {
  //   //      setActiveStep(3);
  //   //      return setCount(3);
  //   //    }
  //   //    if (page2.isVerified) {
  //   //      setActiveStep(2);
  //   //      return setCount(2);
  //   //    }
  //   //  })
  //   //  .catch((err) => props.history.push('/signin'));

  // };

  React.useEffect(
    () => {
      if (count === 0 && firstname && lastname && emailAddress && phoneNo) {
        return setNextButtonStatus(false);
      }
      if (count === 1 && props.userInfo.country && props.userInfo.zip) {
        return setNextButtonStatus(false);
      }
      // Moving tracking to count 2 and converusState to count 3 since moving backgournd and converus pages
      if (count === 2 && props.tracking) {
        return setNextButtonStatus(false);
      }
      if (count === 3 && props.converusState) {
        return setNextButtonStatus(false);
      }
      //if (count === 4) {
      if (count === 4 && props.isUploaded) {
        return setNextButtonStatus(false);
      }
      //if (count === 5) {
      if (count === 5 && props.onFido.onFidoId) {
        return setNextButtonStatus(false);
      }
      if (count === 6 && props.validationStatus) {
        return setNextButtonStatus(false);
      }
      return setNextButtonStatus(true);
    },
    [
      count,
      props.converusState,
      props.userInfo.country,
      props.firstName,
      props.lastName,
      props.phone,
      props.email,
      props.validationStatus,
      props.tracking,
      firstname,
      lastname,
      emailAddress,
      phoneNo,
      // examId,
      props.onFido.onFidoId,
      props.idRnd,
      props.isUploaded
    ]
  );

  const updateUserPage1 = async () => {
    const status1 = await postBlMobileRegister(props.email);
    if (status1) {
      const statusPage1 = await postBlPage1({
        isverified: true,
        firstname: props.firstName,
        lastname: props.lastName,
        phonenumber: props.phone,
        issocial: false
      });
      if (statusPage1) {
        return true;
      }
      return false;
    }
    return false;
  };
  //Request.getUserProfile({
  //  email: props.email,
  //  phone: props.phone,
  //  firstName: props.firstName,
  //  lastName: props.lastName,
  //  isVerified: true
  //});

  const updateUserPage2 = async () => {
    const m = `${props.selectedDate.getMonth() + 1}`.length === 1 ? `0${props.selectedDate.getMonth() + 1}` : `${props.selectedDate.getMonth() + 1}`;
    const d = `${props.selectedDate.getDate()}`.length === 1 ? `0${props.selectedDate.getDate()}` : `${props.selectedDate.getDate()}`;
    const y = `${props.selectedDate.getFullYear()}`;
    const dob = `${m}/${d}/${y}`;
    console.log(dob);
    const status = await postBlPage2({ ...props.userInfo, dob: dob });
    if (status) {

    }
  };
  //Request.postUserProfilePage2({
  //  isVerified: true,
  //  ...props.userInfo,
  //  email: props.email,
  //  selectedDate: props.selectedDate
  //});

  const updateUserPage3 = async () => {
    // const response = await postBlPage3({
    //   examid: props.examId, examresult: props.examResult, examineeId: props.examineeId
    // });
    // if (response) {

    // }
  };
  //Request.postUserProfilePage3({
  //  isVerified: status,
  //  email: props.email,
  //  examUrl: props.examUrl,
  //  examId: props.examId,
  //  examResult: null
  //});

  const updateUserPage4 = async () => {
    const response = await postBlPage4({
      isverified: true,
      tracking: props.background.tracking,
      hasCriminalOffense: props.background.criminal ? props.background.criminal : 'none',
      hasOffense: props.background.bankruptcy ? props.background.bankruptcy : 'none',
      hasSexOffense: props.background.judgment ? props.background.judgment : 'none'
    });
    if (response) {

    }
  };
  //Request.postUserProfilePage4({
  //  isVerified: true,
  //  hasOffense: props.background.hasOffense,
  //  hasSexOffense: props.background.hasSexOffense,
  //  hasCriminalOffense: props.background.offenderCount,
  //  tracking: props.tracking,
  //  email: props.email
  //});

  //const updateUserPage5b = () => Request.getprofileImageUploadPage5(props.onFido.onFidoId);

  const updateUserPage5 = async () => {
    const d = new Date();
    const response = await postBlPage5({
      isverified: true,
      onfidoid: `${d.getMonth() + 1}-${d.getDate()}-${d.getFullYear()}`,
      imageurl: props.onFido.imageUrl
    });
    if (response) {

    }
  };
  //Request.postUserProfilePage5({
  //  isVerified: true,
  //  onFidoId: props.onFido.onFidoId,
  //  imageUrl: `${URLAVATAR}/${props.onFido.onFidoId}.png`,
  //  email: props.email
  //});



  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
        // find the first step that has been completed
        steps.findIndex((step, i) => !(i in completed))
        : count + 1;
    setActiveStep(newActiveStep);
  };

  const handleNextPage = async (event) => {
    event.preventDefault();
    setLoading(false);
    if (count < 6) {
      if (count === 0) {
        await updateUserPage1();
        // const noData = await updateVerifyProfileInfo();
        // if (!noData) {
        //   setLoading(true);
        //   return;
        // }
      } else if (count === 1) {
        await updateUserPage2();
      } else if (count === 2) {
        // Moving the Background check to page 3 
        await updateUserPage4();
      } else if (count === 3) {
        // Converus on page 4
        await updateUserPage3();
      } else if (count === 4) {
        await updateUserPage5();
      }
      //else if (count === 6) {

      //}
      setCount(count + 1);
      handleNext();
    } else {
      history.push('/signin');
      setCount(count + 1);
      handleNext();
    }
    setLoading(true);
  };
  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const getCard = [
    <SocialVerification />,
    <AddressVerification />,
    <BackConcent />,
    <ConverusDecide />,
    <IdRnD />,
    <Acuant />,
    <Password />
  ];

  const container = () => (
    <Grid item lg={6} md={12} xs={12}>
      <Grid item className={classes.stepper}>
        <Stepper nonLinear activeStep={activeStep}>
          {steps.map((label, index) => (
            <Step key={label}>
              <Avatar
                style={{
                  backgroundColor: index <= activeStep ? '#3f51b5' : '#ffffff',
                  border: '2px solid #3f51b5'
                }}
                completed={completed[index]}
              >
                {getStepIcons(index, activeStep)}
              </Avatar>
            </Step>
          ))}
        </Stepper>
      </Grid>
      {getCard[count]}
      {count < 6 &&
        (<Grid container justify={'center'}>
          <Button
            variant="contained"
            color="primary"
            disabled={nextButtonStatus}
            className={classes.button}
            endIcon={<ArrowForwardIcon />}
            onClick={handleNextPage}
          >
            Next Page
				</Button>
          <Tooltip title="Next button will automatically enable when you complete the above steps" placement="top">
            <Button
              className={classes.buttonTooltip}
              color="primary"
              endIcon={<Help />}
            />
          </Tooltip>
        </Grid>)}
    </Grid>
  );

  return (
    <Grid container spacing={3} justify="center" alignItems="center">
      {loading ? container() : <Loading />}
    </Grid>
  );
};

Verification.prototype = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string,
  setAllValidItems: PropTypes.func,
  examId: PropTypes.string,
  examUrl: PropTypes.string,
  examineeId: PropTypes.string,
  selectedDate: PropTypes.object,
  history: PropTypes.object,
  converusState: PropTypes.bool.isRequired,
  validationStatus: PropTypes.bool,
  tracking: PropTypes.string,
  userInfo: PropTypes.object,
  background: PropTypes.object,
  setConverusInfo: PropTypes.func,
  setSelectedDate: PropTypes.func,
  onFido: PropTypes.object,
  setOnfidoId: PropTypes.func,
  setOnFidoImageUrl: PropTypes.func,
  isUploaded: PropTypes.bool.isRequired,
  setIdrndUploadeStatus: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  return {
    ...state,
    social: state.social,
    firstName: state.rule.page1.firstName,
    lastName: state.rule.page1.lastName,
    phone: state.rule.page1.phone,
    email: state.rule.page1.email,
    examId: state.rule.page3.examId,
    examResult: state.rule.page3.examResult,
    examineeId: state.rule.page3.examineeId,
    selectedDate: state.rule.page2.selectedDate,
    converusState: state.rule.page3.status,
    validationStatus: state.rule.validationStatus,
    tracking: state.rule.page4.tracking,
    userInfo: state.rule.page2.userInfo,
    background: state.rule.page4,
    onFido: state.rule.page5,
    idRnd: state.rule.page5.idRnd,
    isUploaded: state.rule.page5.isUploaded
  };
};

const mapDispatchToProps = (dispatch) => ({
  setAllValidItems: (firstName, lastName, email, phone) =>
    dispatch(setAllValidItems(firstName, lastName, email, phone)),
  setConverusInfo: (info) => dispatch(setConverusInfo(info)),
  setBackgroundCheck: (info) => dispatch(setBackgroundCheck(info)),
  setSelectedDate: (info) => dispatch(setSelectedDate(info)),
  setExamUrl: (url) => dispatch(setExamUrl(url)),
  setExamId: (id) => dispatch(setExamId(id)),
  setOnfidoId: (id, face) => dispatch(setOnfidoId(id, face)),
  setOnFidoImageUrl: (url) => dispatch(setOnFidoImageUrl(url)),
  setPage1Verified: (status) => dispatch(setPage1Verified(status)),
  setPage2Verified: (status) => dispatch(setPage2Verified(status)),
  setPage3Verified: (status) => dispatch(setPage3Verified(status)),
  setIdrndUploadeStatus: isUploaded => dispatch(setIdrndUploadeStatus(isUploaded)),
  setConverusCompleted: (status) => dispatch(setConverusCompleted(status))
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Verification);
