import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import TwitterLogo from './twitter.png';
import purple from '@material-ui/core/colors/purple';
import { Grid, Button, TextField, Typography, Fab, Card, Tab, Tabs, Paper } from '@material-ui/core';
import { PersonAdd, Lock, Pinterest, Twitter } from '@material-ui/icons';
import { GoogleLoginPage } from '../../components';
import { FacebookLoginPage } from '../../components';
import { UportLoginPage } from '../../components';
import { LinkedinLoginPage } from '../../components';
import PasswordModal from './PasswordModal';
import TroubleSigningModal from './TroubleSigningModal';
import { displayForm, hideForm } from '../Verification/components/actions';
import { loginSuccess, loginFailure, verifyProcess, logoutSuccess } from '../../actions';
import { resetTokens } from '../../UserInfo'
import YoorzLogo from './assets/yoorzAsset.svg';
import './SignIn.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  root2: {
    ...theme.typography.button,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1)
  },
  grid: {
    height: '100%'
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/auth.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    opacity: '.4'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 400
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    height: '200px',
    background: 'white'
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    },
    height: '100vh'
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  socialButtons: {
    marginTop: theme.spacing(3)
  },
  socialIcon: {
    marginRight: 'none'
  },
  sugestion: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0),
    float: 'right',
    fontSize: '14px',
    padding: 0
  },
  signUpButton: {
    margin: theme.spacing(2, 0)
  },
  gfab: {
    backgroundColor: '#fff'
  },
  fab: {
    margin: theme.spacing(1)
  },
  mypurple: {
    color: purple[900]
  },
  logoDisplay: {
    alignContent: 'center',
    justifyContent: 'space-around',
    display: 'flex',
    marginTop: '100px'
  },
  MuiTabsCentered: {},
  trouble: {
    textDecorationLine: 'underline',
    marginTop: 0,
    marginRight: 50,
    color: '#4e39c4',
    padding: 0,
    border: 'none',
    background: 'none',
    float: 'right'
  }
}));

const SignIn = (props) => {
  const { history } = props;
  const classes = useStyles();

  const [email, setEmail] = React.useState('');
  const [value, setValue] = React.useState(0);

  React.useEffect(() => {
    props.logoutSuccess();
    resetTokens()
  }, []);

  const openModal = (modal) => {
    props.displayForm(modal);
  };

  const handleEmailChange = (event) => {
    event.preventDefault();
    setEmail(event.target.value);
  };

  const handlePassword = (event) => {
    event.preventDefault();
    openModal('password');
  };

  const closeModal = () => {
    props.hideForm();
  };

  const handleSignIn = (event) => {
    event.preventDefault();
    history.push('/');
  };

  const handleVerification = () => {
    props.verifyProcess();
    history.push('/initialize');
  };

  const handleTabs = (event, newValue) => {
    event.preventDefault();
    setValue(newValue);
  };

  const handleTroubleSigning = (event) => {
    event.preventDefault();
    openModal('trouble');
  };

  const a11yProps = (index) => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  });

  return (
    <div className={classes.root}>
      <Grid className={classes.grid} container>
        <Grid className={classes.quoteContainer} justifyContent="center" item lg={7}>
          <Grid item className={classes.logoDisplay}>
            <img src={YoorzLogo} height="600px" />
          </Grid>
        </Grid>
        <Grid className={classes.content} item lg={5} xs={12}>
          <Card>
            <div className={classes.contentBody}>
              <form className={classes.form} onSubmit={handleSignIn}>
                <Typography align="center" variant="h1" className={classes.mypurple}>
                  Connect..Communicate with Confidence
								</Typography>
                <br /> <br />
                <Paper>
                  <Tabs
                    className={classes.MuiTabsCentered}
                    value={value}
                    onChange={handleTabs}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                  >
                    <Tab icon={<PersonAdd />} label="SIGN UP" {...a11yProps(0)} />
                    <Tab icon={<Lock />} label="LOGIN" {...a11yProps(1)} />
                  </Tabs>
                </Paper>
                <br />
                <Typography color="textSecondary" gutterBottom>
                  Sign{value ? ' In ' : ' Up '}with social media
								</Typography>
                <Grid className={classes.socialButtons} container spacing={1}>
                  <Grid item xs={3}>
                    <Fab aria-label="add" className={classes.gfab}>
                      <FacebookLoginPage />
                    </Fab>
                  </Grid>

                  <Grid item xs={3}>
                    <Fab aria-label="add" className={classes.gfab}>
                      <GoogleLoginPage />
                    </Fab>
                  </Grid>
                  <Grid item xs={3}>
                    <LinkedinLoginPage />
                  </Grid>
                  <Grid item xs={3}>
                    <Fab aria-label="add" className={classes.gfab}>
                      <img alt="t" height="30px" src={TwitterLogo} width="30px" />
                    </Fab>
                  </Grid>
                </Grid>
                <br />
                <Typography color="textSecondary" gutterBottom>
                  {value ? (
                    'Login with registered yoorz Email'
                  ) : (
                    "If you don't have a login we can help you setup"
                  )}
                </Typography>
                {value === 0 && (
                  <div>
                    <Button
                      className={classes.signUpButton}
                      color="primary"
                      onClick={handleVerification}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Sign up now
										</Button>
                  </div>
                )}
                {value === 1 && (
                  <div>
                    <TextField
                      className={classes.textField}
                      fullWidth
                      label="Email address"
                      name="email"
                      margin="normal"
                      onChange={handleEmailChange}
                      type="text"
                      value={email}
                      variant="outlined"
                    />
                    <button
                      className={classes.trouble}
                      size="large"
                      type="submit"
                      onClick={handleTroubleSigning}
                    >
                      Trouble SignIn ?
										</button>
                    <Button
                      className={classes.signUpButton}
                      disabled={email === '' ? true : false}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      color="primary"
                      onClick={handlePassword}
                    >
                      Sign in
										</Button>
                    {props.loginError && (
                      <Typography
                        style={{
                          marginTop: '10px !important',
                          marginBottom: '10px',
                          justifySelf: 'center',
                          marginLeft: '20px',
                          color: 'red'
                        }}
                      >
                        {props.loginError}
                      </Typography>
                    )}
                  </div>
                )}
              </form>
              {props.modal === 'password' && (
                <PasswordModal
                  formState={props.formState}
                  closeModal={closeModal}
                  userEmail={email}
                  loginSuccess={props.loginSuccess}
                  loginFailure={props.loginFailure}
                />
              )}
              {props.modal === 'trouble' && (
                <TroubleSigningModal formState={props.formState} closeModal={closeModal} />
              )}
            </div>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

SignIn.propTypes = {
  history: PropTypes.object,
  displayForm: PropTypes.func,
  formState: PropTypes.bool.isRequired,
  loginSuccess: PropTypes.func,
  loginFailure: PropTypes.func,
  login: PropTypes.bool,
  phone: PropTypes.string,
  verifyProcess: PropTypes.func
};

const mapStateToProps = (state) => {
  return {
    modal: state.rule.form.modal,
    formState: state.rule.form.status,
    login: state.login.login,
    phone: state.login.phone,
    loginError: state.login.loginError
  };
};

const mapDispatchToProps = (dispatch) => ({
  displayForm: (modal) => dispatch(displayForm(modal)),
  hideForm: () => dispatch(hideForm()),
  loginSuccess: (phone) => dispatch(loginSuccess(phone)),
  loginFailure: (error) => dispatch(loginFailure(error)),
  logoutSuccess: () => dispatch(logoutSuccess()),
  verifyProcess: () => dispatch(verifyProcess())
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(SignIn);
