import * as Constants from './constants';

const initialState = {
	DID: {},
	googleResponse: {},
	facebookResponse: {},
	twitterResponse: {}
};

const socialReducer = (state = initialState, action) => {
	switch (action.type) {
		case Constants.UPORT_LOGIN_SUCCESS:
			return { ...state, DID: action.did };
		case Constants.UPORT_LOGIN_FAILURE:
			return { ...state, DID: action.err };
		case Constants.GOOGLE_LOGIN_SUCCESS:
			return { ...state, googleResponse: action.googleResponse };
		case Constants.GOOGLE_LOGIN_FAILURE:
			return { ...state, googleResponse: action.err };
		case Constants.FACEBOOK_LOGIN_SUCCESS:
			return { ...state, facebookResponse: action.facebookResponse };
		case Constants.FACEBOOK_LOGIN_FAILURE:
			return { ...state, facebookResponse: action.err };
		case Constants.TWITTER_LOGIN_SUCCESS:
			return { ...state, twitterResponse: action.twitterResponse };
		case Constants.TWITTER_LOGIN_FAILURE:
			return { ...state, twitterResponse: action.err };
		default:
			return state;
	}
};

export default socialReducer;
