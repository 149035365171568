import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Typography, Link } from '@material-ui/core';
import { BottomNavigation } from '@material-ui/core';

const useStyles = makeStyles({
  bottomStyle: {
    bottom: 0,
    width: '100%',
    textAlign: 'center'
  },
  textContainer: {
    marginTop: '15px'
  }
});

const Footer = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.bottomStyle}>
      <BottomNavigation color="silver">
        <Typography variant="body1" className={classes.textContainer}>
          Copyright &copy; 2019
					<Link component="a" href="https://verify.yoorz.me/" target="_blank">
            {' Yoorz.ME '}
          </Link>
					- All Rights Reserved.
				</Typography>
      </BottomNavigation>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;
