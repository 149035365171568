import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, Button, Paper } from '@material-ui/core';
import { connect } from 'react-redux';
import UserVerifyResultModel from './UserVerifyResultModel';
import UserVerifySearch from './UserVerifySearch';
import UserVerifyResults from './UserVerifyResults';
import { getSearch } from './Request';
import UserVerifyHistory from './UserVerifyHistory';
import { ArrowBack } from '@material-ui/icons';
import Loading from '../Loading';

const useStyles = makeStyles((theme) => ({
	maxSize: {
		width: '100%'
	},
	button: {
		float: 'right',
		margin: '10px 50px'
	},
	header: {
		textAlign: 'center',
		marginTop: '20px'
	},
	paperContainer: {
		width: '80%',
		marginTop: '10px',
		padding: '10px',
		margin: 'auto',
		height: '150px'
	},
	errorText: {
		textAlign: 'center',
		margin: '20px'
	},
	headerHistory: {
		display: 'flex',
		justifyContent: 'space-around',
		alignContent: 'space-around',
		width: '80%',
		margin: 'auto'
	}
}));

const UserVerify = (props) => {
	const classes = useStyles();

	const [ result, setResult ] = React.useState([]);
	const [ searchHistory, setSearchHistory ] = React.useState(false);
	const [ resultError, setResultError ] = React.useState({});

	React.useEffect(() => {
		onHistoryClick();
	}, []);

	const onHistoryClick = async () => {
		const user = await getSearch();
		if (user) {
			return setSearchHistory(user);
		}
	};

	return (
		<Grid container direction="column" justify="center" alignItems="center">
			<Grid item className={classes.maxSize}>
				<div className={classes.headerHistory}>
					<Typography color="primary" className={classes.header} variant="h5">
						{props.data.plan === 'limited' ? (
							`Available searches : ${props.data.availableSearchs}`
						) : (
							`Searches: ${props.data.billing.toUpperCase()}`
						)}
					</Typography>
					<Typography color="primary" className={classes.header} variant="h5">
						{`Current Plan: ${props.data.plan.toUpperCase()} / Mo`}
					</Typography>
					<Button className={classes.button} color="primary" variant="contained" onClick={onHistoryClick}>
						History
					</Button>
				</div>
			</Grid>
			{!result.length &&
			!resultError.code && (
				<Grid item xs={12} className={classes.maxSize}>
					<UserVerifySearch setResult={setResult} setResultError={setResultError} />
					<UserVerifyHistory searchHistory={searchHistory} data={props.data} />
				</Grid>
			)}
			{resultError.code && (
				<Grid item xs={12} className={classes.maxSize}>
					<Paper elevation={3} className={classes.paperContainer}>
						<Typography color="primary" variant="h4" className={classes.errorText}>
							{`${resultError.message}, Please provide more info`}
						</Typography>
						<Button
							style={{ marginLeft: 'calc(100% - 60%)' }}
							startIcon={<ArrowBack />}
							color="primary"
							variant="contained"
							onClick={() => setResultError({})}
						>
							Back
						</Button>
					</Paper>
				</Grid>
			)}
			{result.length > 1 && (
				<Grid item xs={12} className={classes.maxSize}>
					<Typography color="primary" className={classes.header} variant="h4">
						Results
					</Typography>
					<UserVerifyResults results={result} setResult={setResult} />
				</Grid>
			)}
			{result.length === 1 && (
				<Grid item xs={12} className={classes.maxSize}>
					<UserVerifyResultModel result={result} setResult={setResult} getUserInfo={props.getUserInfo} />
				</Grid>
			)}
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {};
};

export default connect(mapStateToProps)(UserVerify);
