
import React from 'react';
import { GiftedChat } from 'react-web-gifted-chat';
import { getChatToken } from '../../../../API/ChatApi';
import Loading from '../../../Loading';
import { ChannelWindow, ChatWindow } from '../index';
import { ListItem, ListItemText, ListItemAvatar, Typography, Avatar } from '@material-ui/core';
const Chat = require('twilio-chat');

class ChatPage extends React.Component {

  constructor() {
    super();
    this.state = {
      messages: [],
      user: {},
      clientReady: false,
      channel: {},
      channelView: '',
      channelInit: {
        chatClient: null
      }
    };
  }

  async componentWillMount() {
    const token = await getChatToken();
    try {
      const client = await Chat.Client.create(token);
      const user = JSON.parse(client.user.identity);


      console.log('Client ', client);
      console.log('User ', user);
      this.setState({
        user: user,
        clientReady: true,
        channelInit: {
          chatClient: client
        }
      });

      client.on('tokenAboutToExpire', () => {
        console.log("INSIDE TOKEN EXPRING>>>>");
        this.refreshToken(client);
      });
      client.on('tokenExpired', () => {
        console.log("INSIDE TOKEN EXPRIRED>>>>");
        this.refreshToken(client);
      });

    } catch (err) {
      console.log(err);
      this.setState({
        clientReady: false,
      });
    }
  }


  refreshToken = async (client) => {
    try {
      console.log("TOKEN EXPIRING>>>>>>");
      const token = await getChatToken();

      console.log("NEW TOKEN", token);
      await client.updateToken(token);

      console.log("CLIENT TOKEN", client.token);
      this.setState({
        clientReady: true,
        channelInit: {
          chatClient: client
        }
      });

      console.log("AFTER CHATCLIENT", this.state.channelInit.chatClient);
      console.log("TOKEN AFTER CHATCLIENT", this.state.channelInit.chatClient.token);

    } catch (err) {
      this.setState({
        clientReady: false
      });
    }
  };


  componentWillUnmount() {
    if (this.state.channelInit.chatClient) {
      this.state.channelInit.chatClient.shutdown().then(value => {
      }).catch(err => {
        console.log(err);
        return err;
      });
    }
  }

  updateChannelInfo = channel => {
    console.log('update channel called ', channel);
    this.setState({ ...channel, clientReady: true });
  };

  render() {
    if (this.state.clientReady) {
      return (
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '100%' }}>
          <div style={{ height: '100%', width: '30%', border: '.5px solid grey' }}>
            <ListItem
              onClick={() => console.log()}
              style={{ borderBottom: '1px solid #000', background: 'linear-gradient(to right, rgb(178, 167, 240,0), rgba(78, 57, 196, 1)' }}
            >
              <ListItemText
                primary={<Typography style={{ textAlign: 'center', fontWeight: 'bold', color: '#fff', fontStyle: 'italic' }}>
                  CHATS
              </Typography>}
              />
            </ListItem>
            <ChannelWindow
              user={this.state.user}
              channelInit={this.state.channelInit}
              updateChannelInfo={this.updateChannelInfo}
            />
          </div>
          <div style={{ height: '100%', width: '70%', border: '.5px solid grey', borderLeft: 'none' }}>
            <ListItem style={{ borderBottom: '1px solid #000', background: 'linear-gradient(to left, rgb(178, 167, 240,0), rgba(78, 57, 196, 1)' }}>
              <ListItemText
                primary={<Typography style={{ textAlign: 'center', fontWeight: 'bold', color: '#fff', fontStyle: 'italic' }}>
                  MESSAGES
              </Typography>}
              />
            </ListItem>
            <div style={{ height: '90%', width: '100%' }}>
              <ChatWindow
                user={this.state.user}
                channel={this.state.channel}
                channelInit={this.state.channelInit}
              />
            </div>
          </div>
        </div>
      );
    }
    return <Loading />;
  }
}

export default ChatPage;