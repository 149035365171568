import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar } from '@material-ui/core';
import YoorzSvg from '../../../../icons/Yoorz/yoorzAssetBackdrop.svg';
//import topBar from '../../../../views/SignIn/assets/bar2.jpg';

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: 'none',
    // backgroundImage: `url(${topBar}) !important`,
    // backgroundRepeat: 'no-repeat',
    // backgroundSize: 'cover',
    backgroundColor: '#5530cc'
  }
}));

const Topbar = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      color="primary"
      position="fixed">
      <Toolbar>
        <RouterLink to="/">
          <img alt="Logo" src={YoorzSvg} height="80px" width="auto" />
        </RouterLink>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string
};

export default Topbar;
