import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { ScoreCard2 } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1)
  }
}));

const Dashboard = props => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item sm={6} xl={3} xs={12}>
          <ScoreCard2 />
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
