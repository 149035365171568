import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { setExamUrl, setExamId, setConverusInfo, setSubjectId, setSelectedDate } from './actions';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  InputLabel,
  Divider,
  Button,
  MenuItem,
  Select,
  Grid,
  TextField,
  FormControl,
  Typography
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { postFindZip, postLocateAddress } from '../../../API/page2AddressAPI';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '25px'
  },
  paper: {
    padding: theme.spacing(2),
    marginTop: '100px',
    textAlign: 'center',
    color: theme.palette.text.secondary,
    width: '600px',
    boxShadow: '12px 12px 15px 1px rgba(63, 81, 181, .2);'
  },
  typography: {
    display: 'block'
  },
  textField: {
    width: '300px'
  },
  avatar: {
    marginLeft: 'auto',
    height: 110,
    width: 100,
    flexShrink: 0,
    flexGrow: 0
  },
  progress: {
    marginTop: theme.spacing(2)
  },
  uploadButton: {
    marginRight: theme.spacing(2)
  },
  button: {
    justifySelf: 'center',
    width: '150px'
  },
  dropDownPaddingMargin: {
    paddingLeft: '25px',
    marginTop: '-13px'
  },
  dropDownPadding: {
    paddingLeft: '10px',
  },
  zipText: {
    width: '75%',
    margin: 0
  },
  zipButton: {
    width: '18%',
    marginLeft: 5,
    marginTop: 2
  }
}));

const AddressVerification = (props) => {
  const classes = useStyles();

  const date = new Date();
  const month = `${date.getMonth() + 1}`.length === 1 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`;
  const day = `${date.getDate()}`.length === 1 ? `0${date.getDate()}` : `${date.getDate()}`;
  const year = `${date.getFullYear() - 18}`;
  const constDate = `${month}-${day}-${year}`;

  const [selectedDate, setSelectedDate] = React.useState(constDate);
  const [isValidAge, setIsValidAge] = React.useState(false);
  const [isInValidZip, setIsInValidZip] = React.useState(false);
  const [isInvalidAddress, setIsInvalidAddress] = React.useState(false);
  const [isMarriedEmpty, setIsMarriedEmpty] = React.useState(false);
  const [isGenderEmpty, setIsGenderEmpty] = React.useState(false);
  const [values, setValues] = useState({
    state: '',
    country: 'USA',
    address1: '',
    address2: '',
    city: '',
    zip: '',
    gender: '',
    marritalStatus: ''
  });

  const handleDateChange = (date) => {
    if (date < new Date(constDate)) {
      setIsValidAge(false);
      setSelectedDate(date);
    } else {
      setIsValidAge(true);
    }
  };

  const marritalStatus = [
    {
      value: '',
      label: ''
    },
    {
      value: 'married',
      label: 'married'
    },
    {
      value: 'single',
      label: 'single'
    }
  ];

  const gender = [
    {
      value: '',
      label: ''
    },
    {
      value: 'male',
      label: 'male'
    },
    {
      value: 'female',
      label: 'female'
    },
    {
      value: 'dontDisclose',
      label: "Don't Disclose"
    }
  ];

  const country = [
    {
      value: 'USA',
      label: 'USA'
    }
  ];

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmission = async (event) => {
    event.preventDefault();

    const response = await postLocateAddress(values);

    if (response && response.lat && values.marritalStatus && values.gender) {
      setIsInvalidAddress(false); setIsGenderEmpty(false); setIsMarriedEmpty(false);

      const selDate = typeof selectedDate === 'string' ? new Date(selectedDate.replaceAll('-', ',')) : selectedDate;

      const m = `${selDate.getMonth() + 1}`.length === 1 ? `0${selDate.getMonth() + 1}` : `${selDate.getMonth() + 1}`;
      const d = `${selDate.getDate()}`.length === 1 ? `0${selDate.getDate()}` : `${selDate.getDate()}`;
      const y = `${selDate.getFullYear()}`;

      console.log(selectedDate, selDate);
      props.setConverusInfo({ ...values });
      props.setSelectedDate(new Date(`${m}/${d}/${y}`));
      return;
    }
    setIsGenderEmpty(true); setIsMarriedEmpty(true); setIsInvalidAddress(true);
  };

  const findZip = async () => {
    const response = await postFindZip(values.zip);
    if (!response) {
      setIsInValidZip(true);
      return;
    }
    setIsInValidZip(false);
    setValues({
      ...values, state: response.defaultState,
      city: response.defaultCity,
      zip: response.zip5,
    });
  };

  return (
    <Card className={classes.root}>
      <form autoComplete="off" noValidate>
        <CardHeader subheader="Please update information below" title="Profile" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} style={{ display: 'grid', width: '100%' }}>
              {isValidAge ? <Typography color="error">Should be older than 18 years</Typography> : ''}
            </Grid>
            <Grid item xs={12} style={{ display: 'grid', width: '100%' }}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  label="Date of Birth"
                  value={selectedDate}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Address Line 1"
                margin="dense"
                name="address1"
                onChange={handleChange}
                required
                value={values.address1}
                error={isInvalidAddress}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Address Line 2"
                margin="dense"
                name="address2"
                onChange={handleChange}
                value={values.address2}
                error={isInvalidAddress}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="City"
                margin="dense"
                name="city"
                onChange={handleChange}
                required
                value={values.city}
                error={isInvalidAddress}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="State"
                margin="dense"
                name="state"
                onChange={handleChange}
                required
                value={values.state}
                error={isInvalidAddress}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl fullWidth>
                <InputLabel className={values.marritalStatus ? classes.dropDownPadding : classes.dropDownPaddingMargin} id="marrital-status-label">Marrital Status</InputLabel>
                <Select
                  fullWidth
                  id="marrital-status-value"
                  margin="dense"
                  name="marritalStatus"
                  onChange={handleChange}
                  required
                  select
                  // eslint-disable-next-line react/jsx-sort-props
                  SelectProps={{ native: true }}
                  value={values.marritalStatus}
                  variant="outlined"
                  error={isMarriedEmpty}
                >
                  {marritalStatus.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl fullWidth>
                <InputLabel className={values.gender ? classes.dropDownPadding : classes.dropDownPaddingMargin} id="genter-label">Gender</InputLabel>
                <Select
                  fullWidth
                  id="gender-options"
                  margin="dense"
                  name="gender"
                  onChange={handleChange}
                  required
                  select
                  // eslint-disable-next-line react/jsx-sort-props
                  SelectProps={{ native: true }}
                  value={values.gender}
                  variant="outlined"
                  error={isGenderEmpty}
                >
                  {gender.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl fullWidth>
                <Select
                  fullWidth
                  id="country-value"
                  margin="dense"
                  name="country"
                  onChange={handleChange}
                  required
                  select
                  disabled={true}
                  // eslint-disable-next-line react/jsx-sort-props
                  SelectProps={{ native: true }}
                  value={values.country}
                  variant="outlined"
                >
                  {country.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                className={classes.zipText}
                fullWidth
                label="Postal"
                margin="dense"
                name="zip"
                onChange={handleChange}
                required
                value={values.zip}
                variant="outlined"
                error={isInValidZip}
              />
              <Button className={classes.zipButton} color="primary" variant="contained" disabled={`${values.zip}`.length === 5 ? false : true} onClick={findZip}>
                find
              </Button>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions style={{ justifyContent: 'center' }}>
          <Button
            color="primary"
            variant="contained"
            justify={'center'}
            onClick={handleSubmission}
            className={classes.button}
          >
            Submit
					</Button>
        </CardActions>
      </form>
    </Card>
  );
};

AddressVerification.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  setExamUrl: PropTypes.func,
  setExamId: PropTypes.func,
  setSubjectId: PropTypes.func,
  selectedDate: PropTypes.string,
  setConverusInfo: PropTypes.func,
  userInfo: PropTypes.object,
  setSelectedDate: PropTypes.func,
  values: PropTypes.object
};

const mapStateToProps = (state) => {

  return {
    firstName: state.rule.page1.firstName,
    lastName: state.rule.page1.lastName,
    phone: state.rule.page1.phone,
    email: state.rule.page1.email,
    selectedDate: state.rule.page2.selectedDate,
    userInfo: state.rule.page2.userInfo,
    values: state.rule.page2.values
  };
};

const mapDispatchToProps = (dispatch) => ({
  setExamUrl: (url) => dispatch(setExamUrl(url)),
  setExamId: (id) => dispatch(setExamId(id)),
  setSubjectId: (id) => dispatch(setSubjectId(id)),
  setConverusInfo: (info) => dispatch(setConverusInfo(info)),
  setSelectedDate: (date) => dispatch(setSelectedDate(date))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddressVerification);
