import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
	ExpansionPanel,
	ExpansionPanelDetails,
	ExpansionPanelSummary,
	Grid,
	Paper,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Divider,
	Typography,
	Tooltip
} from '@material-ui/core';
import { ExpandMore, Gavel, HighlightOff, AccountBalance, ErrorOutline } from '@material-ui/icons';
import HandCuff from '../Verification/assets/images/handcuff.png';

const useStyles = makeStyles({
	paperContainer: {
		width: '80%',
		marginTop: '30px',
		padding: '10px'
	},
	heading: {
		fontWeight: 'bold',
		width: '60%'
	},
	content: {
		fontWeight: 'bold',
		width: '40%'
	},
	headerText: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		width: '100%',
		margin: '5px'
	},
	bodyContent: {
		width: '100%'
	},
	header: {
		textAlign: 'center',
		margin: '10px'
	}
});

const useStylesBootstrap = makeStyles({
	tooltip: {
		backgroundColor: '#000'
	}
});

function BootstrapTooltip(props) {
	const classes = useStylesBootstrap();
	return <Tooltip classes={classes} {...props} />;
}

const UserVerifyResults = (props) => {
	const classes = useStyles();
	const [ expanded, setExpanded ] = React.useState(false);

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	const currentName = (names) => {
		const name = names.map((user) => user.data);
		return [ ...new Set(name) ];
	};

	const returnStatus = (info) => {
		if (info.length)
			return (
				<BootstrapTooltip title="Found records">
					<ErrorOutline style={{ color: 'red' }} />
				</BootstrapTooltip>
			);
		return (
			<BootstrapTooltip title="No Records! Clean">
				<HighlightOff color="primary" />
			</BootstrapTooltip>
		);
	};

	const displayPanel = () => {
		return (
			props.searchHistory.length &&
			props.searchHistory.map((user, index) => {
				const name = currentName(user.name);
				const resentName = name[0];
				const time = user.time.replace('T', ' ').replace('Z', '');
				return (
					<ExpansionPanel
						key={`user-serach-result-${index}`}
						expanded={expanded === `panel${index}`}
						onChange={handleChange(`panel${index}`)}
					>
						<ExpansionPanelSummary
							expandIcon={<ExpandMore />}
							aria-controls={`user-search-panel${index}`}
							id={`user-search-panel${index}`}
						>
							<div className={classes.headerText}>
								<div className={classes.heading}>{resentName}</div>
								<div className={classes.content}>{time}</div>
							</div>
						</ExpansionPanelSummary>
						<ExpansionPanelDetails>
							<List className={classes.bodyContent} component="nav" aria-label="main mailbox folders">
								<ListItem button>
									<ListItemIcon>
										<Gavel />
									</ListItemIcon>
									<ListItemText primary="Judgement Records" />
									{returnStatus(user.criminal)}
								</ListItem>
								<Divider />
								<ListItem button>
									<ListItemIcon>
										<img src={HandCuff} height="30px" />
									</ListItemIcon>
									<ListItemText primary="Criminal Background check" /> {returnStatus(user.judgment)}
								</ListItem>
								<Divider />
								<ListItem button>
									<ListItemIcon>
										<AccountBalance />
									</ListItemIcon>
									<ListItemText primary="Bankruptcy Report" />
									{returnStatus([ ...user.bankruptcy ])}
								</ListItem>
							</List>
						</ExpansionPanelDetails>
					</ExpansionPanel>
				);
			})
		);
	};

	return (
		<Grid container direction="row" justify="center" alignItems="center">
			<Paper elevation={3} className={classes.paperContainer}>
				{/* <Grid item>
					<div style={{ display: 'flex', justifyContent: 'space-around', alignContent: 'space-around' }}>
						<Typography color="primary" className={classes.header} variant="h5">
							{props.data.plan === 'limited' ? (
								`Available searches : ${props.data.availableSearchs}`
							) : (
								`Searches: ${props.data.billing.toUpperCase()}`
							)}
						</Typography>
						<Typography color="primary" className={classes.header} variant="h5">
							{`Current Plan: ${props.data.plan.toUpperCase()} / Mo`}
						</Typography>
					</div>
					<Divider />
				</Grid> */}
				<Grid item xs={12}>
					{props.searchHistory.length ? (
						<Typography color="primary" className={classes.header} variant="h5">
							Search History
						</Typography>
					) : (
						<Typography color="primary" className={classes.header} variant="h5">
							No Search History
						</Typography>
					)}
					<Divider />
					{displayPanel()}
				</Grid>
			</Paper>
		</Grid>
	);
};

export default UserVerifyResults;
