import * as Constants from './constants';

export const uportLoginSuccess = did => {
  return {
    type: Constants.UPORT_LOGIN_SUCCESS,
    did
  };
};

export const uportLoginFailure = err => {
  return {
    type: Constants.UPORT_LOGIN_FAILURE,
    err
  };
};

export const facebookLoginSuccess = facebookResponse => {
  return {
    type: Constants.FACEBOOK_LOGIN_SUCCESS,
    facebookResponse
  };
};

export const facebookLoginFailure = err => {
  return {
    type: Constants.FACEBOOK_LOGIN_FAILURE,
    err
  };
};

export const googleLoginSuccess = googleResponse => {
  return {
    type: Constants.GOOGLE_LOGIN_SUCCESS,
    googleResponse
  };
};

export const googleLoginFailure = err => {
  return {
    type: Constants.GOOGLE_LOGIN_FAILURE,
    err
  };
};

export const twitterLoginSuccess = twitterResponse => {
  return {
    type: Constants.TWITTER_LOGIN_SUCCESS,
    twitterResponse
  };
};

export const twitterLoginFailure = err => {
  return {
    type: Constants.TWITTER_LOGIN_FAILURE,
    err
  };
};
