import React from 'react';
import { makeStyles } from "@material-ui/styles";
import { Grid, Typography, TextField, Button } from "@material-ui/core";
import PropTypes from 'prop-types';
import { getUserEmailId } from '../../API/CommonUtilsAPI';
import { onboardSetUser } from '../../api';
import PhoneVerificationModal from '../Verification/modals/PhoneVerificationModal';
//import { displayForm, hideForm } from '../Verification/components/actions';

const useStyles = makeStyles({
  terms: {
    color: "#7355d2",
    marginBottom: "10px"
  },
  getUser: {
    width: '150px'
  },
  userId: {
    marginLeft: '20px',
    width: '100px'
  }
});

const ForgotUser = props => {
  const classes = useStyles();
  //const [isTokenValid, setIsTokenValid] = React.useState(false);
  const [userId, setUserId] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [twillioNumber, setTwillioNumber] = React.useState("");
  const [displayPhoneModal, setDisplayPhoneModal] = React.useState(false);

  const handlePhone = event => {
    setPhone(event.target.value);
  };

  const handleGetUserId = async () => {
    let user = phone;
    if (user.length >= 10 && user.substring(0, 2) === '+1') {
      user = '+1' + user;
    }
    const status = await onboardSetUser('robot@yooz.me');
    if (status) {
      setTwillioNumber(user);
      setDisplayPhoneModal(true);
    }

    //const status = await onboardSetUser('robot@yooz.me');
    //if (status) {
    //const forgotUser = await getUserEmailId(user.replace("+1", ""));
    //if (forgotUser) {
    //  setUserId(forgotUser.email);
    //}
    //}

  };

  const handleVerifyCode = async (status) => {
    if (status) {
      const forgotUser = await getUserEmailId(phone.replace("+1", ""));
      if (forgotUser) {
        setUserId(forgotUser.email);
      }
    }
    setDisplayPhoneModal(false);
  };

  console.log(twillioNumber, ' .... ', phone);

  return (
    <>
      {displayPhoneModal ?
        <PhoneVerificationModal
          formState={props.formState}
          closeModal={props.hideForm}
          phone={twillioNumber}
          handleVerifyCode={handleVerifyCode}
          isModalView={false}
        />
        : <Grid
          container
          direction="column"
          justify="center"
          alignContent="center"
          spacing={3}
          lg={12}
        >
          <Grid item>
            <Typography className={classes.terms} variant="h6">
              The User Id is the email the you used to sign up.
        </Typography>
            <Typography className={classes.terms} variant="h6">
              Please enter the phone number and we can send you the user ID
        </Typography>
          </Grid>
          <Grid item>
            <TextField
              className={classes.textField}
              fullWidth
              label="Phone"
              name="phone"
              margin="normal"
              onChange={handlePhone}
              type="text"
              value={phone}
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <Typography className={classes.terms} variant="h6">
              {userId}
            </Typography>
          </Grid>
          <Grid item style={{ textAlign: 'center' }}>
            <Button
              className={classes.getUser}
              size="large"
              type="submit"
              variant="contained"
              color="primary"
              onClick={handleGetUserId}
            >
              Get User-Id
			      </Button>
            <Button
              className={classes.userId}
              size="large"
              type="submit"
              variant="contained"
              color="primary"
              onClick={() => props.updateResetStatus("")}
            >
              Done
			  </Button>
          </Grid>
        </Grid>
      }
    </>
  );
};


ForgotUser.prototype = {
  updateResetStatus: PropTypes.func.isRequired
};

export default ForgotUser;
