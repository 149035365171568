import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Grid, Button, Popover, Typography } from '@material-ui/core';

import { UserList, ChatPage } from './components';
import { Create, ArrowBack } from '@material-ui/icons';
import { getUsers, getUserIdForStream } from './Request';
//import { disconnect, initializeChat } from '../../streamAPI';

//const useStyles = makeStyles((theme) => ({
//	root: {
//		padding: theme.spacing(4),
//		height: '100%',
//		width: '100%',
//		paddingTop: '10px'
//	},
//	girdContainer: {
//		height: '100%',
//		width: '100%'
//	},
//	iconNew: {
//		float: 'left',
//		marginBottom: '5px'
//	},
//	iconBack: {
//		float: 'right'
//	}
//}));

//const ChatApp = (props) => {
//	const classes = useStyles();

//	const [ isloading, setIsLoading ] = React.useState(true);
//	const [ chatClient, setChatClient ] = React.useState({});
//	const [ userId, setUserId ] = React.useState(null);
//	const [ users, setUsers ] = React.useState([]);
//	const [ newChannel, setNewChannel ] = React.useState({});
//	const [ sasToken, setSasToken ] = React.useState('');

//	React.useEffect(() => {
//		return () => {
//			disconnect();
//		};
//	}, []);

//	React.useEffect(
//		() => {
//			if (props.email) {
//				initializeChatForUser();
//			}
//		},
//		[ props.email ]
//	);

//	const initializeChatForUser = async () => {
//		try {
//			const userId = await getUserIdForStream(props.email);
//			const chat = await initializeChat({
//				id: userId,
//				name: `${props.firstName} ${props.lastName}`,
//				image: props.imageUrl,
//				email: props.email
//			});

//			setChatClient(chat);
//			setUserId(userId);
//			setIsLoading(false);
//		} catch (err) {
//			console.log('Error loading ');
//		}
//	};

//	const onGetUser = () => {
//		getUsers(props.email).then((allUser) => {
//			console.log('User list ', allUser);
//			setSasToken(allUser.imageToken);
//			setUsers(allUser.users.filter((user) => user._id !== userId));
//		});
//	};

//	const onBack = () => setUsers([]);

//	return (
//		<div>
//			<Grid container direction="column" className={classes.root} justify="center">
//				<Grid item xs={12}>
//					{users.length ? (
//						<Button
//							variant="contained"
//							color="primary"
//							className={classes.iconNew}
//							onClick={onBack}
//							endIcon={<ArrowBack />}
//						>
//							Back to Chat
//						</Button>
//					) : (
//						<Button
//							variant="contained"
//							color="primary"
//							className={classes.iconNew}
//							onClick={onGetUser}
//							startIcon={<Create />}
//						>
//							Contacts
//						</Button>
//					)}
//				</Grid>
//				{users.length ? (
//					<Grid item xs={12} md={6}>
//						<UserList
//							users={users}
//							userId={userId}
//							onBack={onBack}
//							setNewChannel={setNewChannel}
//							sasToken={sasToken}
//						/>
//					</Grid>
//				) : (
//					<Grid item xs={12}>
//						<StreamChatWindow userId={userId} isloading={isloading} chatClient={chatClient} />
//					</Grid>
//				)}
//			</Grid>
//		</div>
//	);
//};

//ChatApp.prototype = {
//	email: PropTypes.string.isRequired,
//	firstName: PropTypes.string.isRequired,
//	lastName: PropTypes.string.isRequired,
//	imageUrl: PropTypes.string.isRequired
//};

//const mapStateToProps = (state) => {
//	return {
//		email: state.rule.page1.email,
//		firstName: state.rule.page1.firstName,
//		lastName: state.rule.page1.lastName,
//		imageUrl: state.rule.page5.imageUrl
//	};
//};
//export default connect(mapStateToProps)(ChatApp);


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    height: '15%',
    width: '100%',
    paddingTop: '10px'
  },
  girdContainer: {
    height: '100%',
    width: '100%'
  },
  iconContact: {
    float: 'left',
  },
  iconBack: {
    float: 'right'
  },
  chatView: {
    position: 'relative',
    height: '85%',
    padding: theme.spacing(2),
    width: '100%'
  },
  typography: {
    padding: theme.spacing(2),
  },
  popover: {
    width: '60%',
    height: '80%',
  }
}));

const ChatApp = (props) => {
  const classes = useStyles();

  const [isloading, setIsLoading] = React.useState(true);
  const [chatClient, setChatClient] = React.useState({});
  const [userId, setUserId] = React.useState(null);
  const [users, setUsers] = React.useState([]);
  const [newChannel, setNewChannel] = React.useState({});
  const [sasToken, setSasToken] = React.useState('');

  React.useEffect(() => {
    return () => {
      //disconnect();
    };
  }, []);

  React.useEffect(
    () => {
      if (props.email) {
        initializeChatForUser();
      }
    },
    [props.email]
  );

  const initializeChatForUser = async () => {
    //try {
    //	const userId = await getUserIdForStream(props.email);
    //	const chat = await initializeChat({
    //		id: userId,
    //		name: `${props.firstName} ${props.lastName}`,
    //		image: props.imageUrl,
    //		email: props.email
    //	});

    //	setChatClient(chat);
    //	setUserId(userId);
    //	setIsLoading(false);
    //} catch (err) {
    //	console.log('Error loading ');
    //}
  };

  const onGetUser = () => {
    getUsers(props.email).then((allUser) => {
      console.log('User list ', allUser);
      setSasToken(allUser.imageToken);
      setUsers(allUser.users.filter((user) => user._id !== userId));
    });
  };

  const onBack = () => setUsers([]);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <>
      <div className={classes.root}>
        <Button aria-describedby={id}
          variant="contained"
          onClick={open ? handleClose : handleClick}
          variant="contained"
          color="primary"
          className={classes.iconContact}
          startIcon={open ? <ArrowBack /> : <Create />}>
          {open ? 'Back to Chat' : 'Contacts'}
        </Button>
        {/*{open && <Button
          variant="contained"
          color="primary"
          className={classes.iconBack}
          onClick={handleClose}
          endIcon={<ArrowBack />}
        >
          Back to Chat
        </Button>}*/}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          placement={'bottom-start'}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          PaperProps={{
            style: {
              width: '60%',
              height: '75%',
              marginTop: '35px'
            },
          }}
        >
          <Typography className={classes.typography}>The content of the Popover.</Typography>
        </Popover>
      </div>
      <div className={classes.chatView}>
        <ChatPage />
      </div>
    </>
  );
};

ChatApp.prototype = {
  email: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired
};

const mapStateToProps = (state) => {
  return {
    email: state.rule.page1.email,
    firstName: state.rule.page1.firstName,
    lastName: state.rule.page1.lastName,
    imageUrl: state.rule.page5.imageUrl
  };
};
export default connect(mapStateToProps)(ChatApp);

