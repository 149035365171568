import React from 'react';
import Background from './Background';
import { Card, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  agreeText: {
    textAlign: 'center',
    marginTop: '30px',
    color: '#7355d2'
  }
});

const BackConcent = () => {
  const classes = useStyles();
  const [agree, setAgree] = React.useState(false);

  return (
    <Card>
      {agree ? (
        <Background />
      ) : (
          <div className={classes.agreeText}>
            <Checkbox checked={agree} onChange={() => setAgree(!agree)} color="primary" />
					I consent to perform background check.
          </div>
        )}
    </Card>
  );
};

export default BackConcent;
