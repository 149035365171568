import React from 'react';
//import PropTypes from 'prop-types';
//import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid,
  Divider,
  FormControlLabel,
  Checkbox,
  Typography,
  Button
} from '@material-ui/core';
import { getBlNotification, postBlNotification } from '../../../../API/SettingsAPI';
import Loading from '../../../Loading';

const useStyles = makeStyles(() => ({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  },
  isDisabled: {
    pointerEvents: 'none',
    opacity: '.2'
  }
}));

const Notifications = (props) => {
  const classes = useStyles();

  const [isEdit, setIsEdit] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [changeNotification, setChangeNotification] = React.useState({
    email: false,
    text: false,
    push: false,
    call: false
  });

  React.useEffect(() => {
    getBlNotification().then((notification) => {
      setChangeNotification({
        email: notification.email,
        push: notification.push,
        text: notification.text,
        call: notification.call
      });
    });
  }, []);

  const updateNotification = async (event) => {
    const name = event.target.name;
    //const status = await postBlNotification(name, !changeNotification[name]);
    //if (status) {
    setChangeNotification({
      ...changeNotification,
      [name]: !changeNotification[name]
    });
    //}
  };

  const onHandleSave = async () => {
    setIsLoading(true);

    await postBlNotification("email", changeNotification["email"]);
    await postBlNotification("push", changeNotification["push"]);
    await postBlNotification("text", changeNotification["text"]);
    await postBlNotification("call", changeNotification["call"]);

    setIsEdit(false);
    setIsLoading(false);
  };

  return (
    <Card className={classes.root}>
      <form>
        <CardHeader subheader="Manage the notifications" title="Notifications" />
        <Divider />
        <CardContent>
          {isLoading && <Loading />}
          <Grid className={isEdit ? '' : classes.isDisabled} container spacing={6} wrap="wrap">
            <Grid className={classes.item} item sm={6} xs={12}>
              <Typography gutterBottom variant="h6">
                Notifications
							</Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={changeNotification.email}
                    name="email"
                    onChange={updateNotification}
                  />
                }
                label="Email"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={changeNotification.push}
                    name="push"
                    onChange={updateNotification}
                  />
                }
                label="Push Notifications"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={changeNotification.text}
                    name="text"
                    onChange={updateNotification}
                  />
                }
                label="Text Messages"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={changeNotification.call}
                    name="call"
                    onChange={updateNotification}
                  />
                }
                label="Phone calls"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          {isEdit ? (
            <Button color="primary" variant="outlined" onClick={onHandleSave}>
              Save
            </Button>
          ) : (
              <Button color="primary" variant="outlined" onClick={() => setIsEdit(true)}>
                Edit
              </Button>
            )}
        </CardActions>
      </form>
    </Card>
  );
};

Notifications.propTypes = {};

export default Notifications;
