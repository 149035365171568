
import { YoorzAPI, YoorzBlockAPI } from '../YoorzAPI';
import { userEmail, setBlAdress, setBlToken, blAddress } from '../UserInfo';

export const getChatToken = async (phone) => {
  try {
    const info = await YoorzBlockAPI().post(`/chat/generatetoken`, {
      address: blAddress
    });
    if (info.data.address !== '0x0000000000000000000000000000000000000000') {
      return info.data.Token;
    }
    return false;
  } catch (err) {
    return err;
  }
};

export const getUsers = async (email) => {
  try {
    const userList = await YoorzBlockAPI().post(`/activeusers/getallactiveuser`);
    if (!userList.data.error) {
      return userList.data;
    }
    return userList.data.error;
  } catch (err) {
    return err;
  }
};