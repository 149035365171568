import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Connect } from 'uport-connect';
import { uportLoginSuccess, uportLoginFailure } from './actions';
import UportLogo from '../../views/SignIn/uPort.png';
import { setAllValidItems } from '../../views/Verification/components/actions';
import { socialSignIn } from '../../api'
import { loginSuccess, loginFailure } from '../../actions'
import * as Request from '../../views/Verification/Request'

const UportLoginPage = props => {
  const handleUportClick = event => {
    event.preventDefault();

    const uport = new Connect('Yoorz');
    const reqObj = {
      requested: ['name', 'country', 'email'],
      notifications: true
    };
    uport.requestDisclosure(reqObj);

    uport.onResponse('disclosureReq').then(async res => {
      const did = res.payload;
      props.uportLoginSuccess(did);
      if (did.email) {
        const email = did.email;
        const name = did.name.split('');
        const firstName = name.length ? name[0] : '';
        const lastName = name.length === 2 ? name[1] : '';
        props.setAllValidItems(firstName, lastName, email, null);
        try {
          const response = await socialSignIn(email);
          if (response) {
            props.loginSuccess();
            const profile = await Request.postVerifyUserProfile(email);
            if (profile.isActive) {
              props.history.push('/dashboard');
              return;
            }
            props.history.push('/verify');
          }
          props.loginFailure();
          props.history.push('/verify');
        } catch (error) {
          props.loginFailure();
          props.history.push('/verify');
        }
      } else {
        props.uportLoginFailure('uPort Login Failed');
      }
    });
  };

  return (
    <div onClick={handleUportClick}>
      <img alt="g" height="30px" src={UportLogo} style={{ marginTop: '7px' }} />
    </div>
  );
};

UportLoginPage.propTypes = {
  setAllValidItems: PropTypes.func,
  loginSuccess: PropTypes.func,
  loginFailure: PropTypes.func,
  uportLoginSuccess: PropTypes.func,
  uportLoginFailure: PropTypes.func
}
/*
 * mapStateToProps
 */
const mapStateToProps = state => ({
  ...state
});

/*
 * mapDispatchToProps
 */
const mapDispatchToProps = dispatch => ({
  setAllValidItems: (firstName, lastName, email, phone) =>
    dispatch(setAllValidItems(firstName, lastName, email, phone)),
  uportLoginSuccess: did => dispatch(uportLoginSuccess(did)),
  uportLoginFailure: err => dispatch(uportLoginFailure(err)),
  loginSuccess: () => dispatch(loginSuccess()),
  loginFailure: () => dispatch(loginFailure())
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(UportLoginPage);
