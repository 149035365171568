import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/styles';
import SamplePic from '../avatars/avatar_1.png';
import Link from '@material-ui/core/Link';
import { Card, CardContent, CardMedia, Grid, Divider, Typography, Avatar } from '@material-ui/core';
import Purple from '@material-ui/core/colors/purple';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import PinterestIcon from '@material-ui/icons/Pinterest';
import InstagramIcon from '@material-ui/icons/Instagram';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import YoorzAPI from '../../../../YoorzAPI';
import { getCookie } from '../../../../api';

const useStyles = makeStyles((theme) => ({
	card: {
		display: 'flex',
		width: '600px'
	},
	divCss: (props) => {
		return {
			position: 'relative',
			zIndex: 3,
			backgroundImage: `url(${props.imageUrl})`,
			width: '33%',
			backgroundSize: 'cover',
			backgroundRepeat: 'no-repeat'
		};
	},
	cover: {
		width: '33%',
		zIndex: 1
	},
	stamp: {
		position: 'relative',
		zIndex: 99
	},
	details: {
		width: '67%'
	},
	content: {
		display: 'flex',
		flexDirection: 'row'
	},
	itemcontent1: {
		width: '25%'
	},
	itemcontent: {
		width: '48%'
	},
	typography: {
		height: '1.5rem'
	},
	itemcontent2: {
		width: '2%'
	},
	name: {
		justifyItems: 'center',
		alignItems: 'center'
	},
	shareIcon: {
		display: 'flex',
		justifyContent: 'space-around'
	},
	avatar: {
		background: 'White',
		border: '1px solid #4e33c7',
		'&:hover': {
			background: 'silver'
		}
	}
}));

const ScoreCard2 = (props) => {
	const classes = useStyles({ ...props });
	const mypurple = Purple[900];
	console.log(props.tracking);
	return (
		<Card className={classes.card}>
			<div className={classes.details}>
				<CardContent className={classes.name}>
					<Typography component="h4" variant="h4">
						{`${props.firstName} ${props.lastName}`}
					</Typography>
				</CardContent>
				<Divider />
				<div className={classes.content}>
					<CardContent className={classes.itemcontent1}>
						<Typography className={classes.typography} variant="body1" color="textSecondary">
							City:
						</Typography>
						<Typography className={classes.typography} variant="body1" color="textSecondary">
							Email:
						</Typography>
						<Typography className={classes.typography} variant="body1" color="textSecondary">
							Phone:
						</Typography>
						<Typography className={classes.typography} variant="body1" color="textSecondary">
							Background:
						</Typography>
						<Typography className={classes.typography} variant="body1" color="textSecondary">
							Biometrics:
						</Typography>
					</CardContent>
					<CardContent className={classes.itemcontent}>
						<Typography className={classes.typography} variant="body1" color="textSecondary">
							{props.values.city}
						</Typography>
						<Typography className={classes.typography} variant="body1" color="textSecondary">
							{props.email}
						</Typography>
						<Typography className={classes.typography} variant="body1" color="textSecondary">
							{props.phone}
						</Typography>
						<Typography className={classes.typography} variant="body1" color="textSecondary">
							{props.tracking ? 'Verified' : 'Background'}
						</Typography>
						<Typography className={classes.typography} variant="body1" color="textSecondary">
							Biometrics
						</Typography>
					</CardContent>

					<CardContent className={classes.itemcontent1}>
						<Typography className={classes.typography} color={mypurple} variant="body1">
							<VerifiedUserIcon color="Primary" />
						</Typography>
						<Typography className={classes.typography} variant="body1">
							<VerifiedUserIcon color="Primary" />
						</Typography>
						<Typography className={classes.typography} variant="body1">
							<VerifiedUserIcon color="Primary" />
						</Typography>
						<Typography className={classes.typography} variant="body1">
							<VerifiedUserIcon color="Primary" />
						</Typography>
						<Typography className={classes.typography} variant="body1">
							<VerifiedUserIcon color="Primary" />
						</Typography>
					</CardContent>
				</div>
				<Divider />
				<CardContent className={classes.name}>
					<div className={classes.shareIcon}>
						<Avatar alt="f" className={classes.avatar}>
							<Link href="https://www.facebook.com/sharer/sharer.php?u=https%2F%2Fverify.yoorz.me">
								<FacebookIcon color="primary" />
							</Link>
						</Avatar>
						<Avatar alt="m" className={classes.avatar}>
							<Link href="mailto:contact@yoorz.me?subject=%23Verify%20Your%20Identity&body=You%20shall%20verify%20yourself%20and%20communicate%20with%20confidence.%0ACheck%20out%20https%3A/verify.yoorz.me">
								<MailOutlineIcon color="primary" />
							</Link>
						</Avatar>
						<Avatar alt="t" className={classes.avatar}>
							<Link href="https://twitter.com/intent/tweet?text=Please%20verify%20yourself%20at%20https%3A/verify.yoorz.me">
								<TwitterIcon color="primary" />
							</Link>
						</Avatar>
						<Avatar alt="in" className={classes.avatar}>
							<Link href="https://www.linkedin.com/shareArticle?mini=true&url=https%3A/verify.yoorz.me&title=Verify%20Your%20Identity&summary=You%20shall%20verify%20yourself%20and%20communicate%20with%20confidence&source=">
								<LinkedInIcon color="primary" />
							</Link>
						</Avatar>
						<Avatar alt="in" className={classes.avatar}>
							<Link href="mailto:contact@yoorz.me?subject=%23Verify%20Your%20Identity&body=You%20shall%20verify%20yourself%20and%20communicate%20with%20confidence.%0ACheck%20out%20https%3A/verify.yoorz.me">
								<InstagramIcon color="primary" />
							</Link>
						</Avatar>
						<Avatar alt="p" className={classes.avatar}>
							<Link href="https://pinterest.com/pin/create/button/?url=https%3A/verify.yoorz.me&media=Verify%20Your%20Identity&description=You%20shall%20verify%20yourself%20and%20communicate%20with%20confidence">
								<PinterestIcon color="primary" />
							</Link>
						</Avatar>
					</div>
					<Grid item style={{ float: 'right', marginTop: '5px', paddingRight: '18px' }}>
						<Typography variant="caption">Image Captured on {props.imageCaptureDate}</Typography>
					</Grid>
				</CardContent>
			</div>
			<CardMedia className={classes.divCss} image={props.imageUrl ? props.imageUrl : null}>
				<Avatar
					className={classes.stamp}
					alt="Yoorz Stamp"
					src="https://yoorz-icon.s3.amazonaws.com/Verified%403x.png"
				/>
			</CardMedia>
		</Card>
	);
};

ScoreCard2.propTypes = {
	className: PropTypes.string,
	setOnfidoId: PropTypes.func,
	setOnFidoImageUrl: PropTypes.func
};
const mapStateToProps = (state) => {
	return {
		firstName: state.rule.page1.firstName,
		lastName: state.rule.page1.lastName,
		formState: state.rule.form.status,
		selectedDate: state.rule.page2.selectedDate,
		email: state.rule.page1.email,
		values: state.rule.page2.userInfo,
		phone: state.rule.page1.phone,
		imageUrl: state.rule.page5.imageUrl,
		imageCaptureDate: state.rule.page5.imageCaptureDate,
		tracking: state.rule.page4.tracking
	};
};

export default connect(mapStateToProps)(ScoreCard2);
