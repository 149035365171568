import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, InputAdornment, IconButton, TextField, Button } from '@material-ui/core';
import { userSignIn } from '../../API/LoginAPI';
import { getBlPage1 } from '../../API/page1PersonalAPI';
import { Visibility, VisibilityOff } from '@material-ui/icons';

const useStyles = makeStyles({
  textField: {
    width: '300px',
    float: 'left'
  },
  button: {
    height: '30px',
    float: 'right',
    marginLeft: '5px',
    width: '100px',
    marginTop: '10px'
  },
  typography: {
    marginTop: '10px !important',
    marginBottom: '10px',
    justifySelf: 'center',
    marginLeft: '20px'
  },
  typographyError: {
    marginTop: '10px !important',
    marginBottom: '10px',
    justifySelf: 'center',
    marginLeft: '20px',
    color: 'red'
  }
});

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    background: '#fff',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    height: '250px',
    width: '400px',
    border: '2px solid #3f51b5',
    alignContent: 'center',
    justify: 'center'
  }
};

Modal.setAppElement('#root');

const PasswordModal = (props) => {
  const classes = useStyles();

  const [password, setPassword] = React.useState('');
  const [isInValid, setIsInValid] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(0);

  const handleCloseModal = async (event) => {
    event.preventDefault();
    // Password api call here 
    if (password.length >= 3) {
      try {

        const loginStatus = await userSignIn({ email: props.userEmail, password: password });
        const page1 = await getBlPage1();
        if (!loginStatus.error && page1) {
          props.loginSuccess(page1.phonenumber);
          props.history.push('/initialize');
          return;
        } else {
          console.log('error else', loginStatus);
          props.loginFailure(loginStatus.error);
        }
        props.closeModal();
      } catch (error) {
        console.log('error', error);
        props.loginFailure(error);
        setIsInValid(true);
      }
    }
  };

  const handlePassword = (event) => {
    event.preventDefault();
    setPassword(event.target.value);
  };

  const handleVisibility = (event) => {
    event.preventDefault();
    setShowPassword(!showPassword);
  };

  return (
    <Modal isOpen={props.formState} onRequestClose={props.closeModal} style={customStyles} contentLabel="Verify">
      <Grid container justify={'center'} alignContent={'center'}>
        <Grid item>
          <Typography variant="h6" component="h2" alignSelf="center" className={classes.typography}>
            Enter Password for <span style={{ fontStyle: 'italic', color: '#3f51b5' }}>{props.userEmail}</span>
          </Typography>
          <TextField
            required
            autoFocus="true"
            id="outlined-required"
            label="Password"
            className={classes.textField}
            type={showPassword ? 'text' : 'Password'}
            margin="normal"
            variant="outlined"
            onChange={handlePassword}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={handleVisibility}>
                    {showPassword ? (
                      <Visibility style={{ color: '#3f51b5' }} />
                    ) : (
                        <VisibilityOff style={{ color: '#3f51b5' }} />
                      )}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item>
          {isInValid && (
            <Typography variant="h6" component="h2" alignSelf="center" className={classes.typographyError}>
              InValid Username or Password Please try again!!!
            </Typography>
          )}
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            disabled={password === '' ? true : false}
            className={classes.button}
            onClick={handleCloseModal}
          >
            Login
					</Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

PasswordModal.prototype = {
  formState: PropTypes.bool.isRequired,
  closeModal: PropTypes.func,
  userEmail: PropTypes.string.isRequired,
  history: PropTypes.object,
  loginSuccess: PropTypes.func,
  loginFailure: PropTypes.func
};

export default withRouter(PasswordModal);
