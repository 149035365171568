import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid, Paper, Button } from '@material-ui/core';
import { ExpandMore, LocationCity, PersonPin, PersonAdd } from '@material-ui/icons';

const useStyles = makeStyles({
	paperContainer: {
		width: '80%',
		marginTop: '10px',
		padding: '10px'
	},
	heading: {
		fontWeight: 'bold',
		width: '80%'
	},
	content: {
		fontWeight: 'bold',
		width: '20%'
	},
	headerText: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		width: '100%',
		margin: '5px'
	},
	bodyText: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		width: '100%'
	},
	bodyIcon: {
		width: '10%'
	},
	bodyContent: {
		width: '90%'
	},
	bodyButton: {
		display: 'flex',
		justifyContent: 'space-around',
		width: '100%'
	},
	button: {
		height: '30px',
		fontSize: '14px',
		padding: '3px 20px'
	}
});

const UserVerifyResults = (props) => {
	const classes = useStyles();
	const [ expanded, setExpanded ] = React.useState(false);

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	const currentName = (names) => {
		const name = names.map((user) => user.data);
		return [ ...new Set(name) ];
	};

	const onHandleSelect = (user) => {
		props.setResult([ user ]);
	};

	const displayPanel = () => {
		return props.results.map((user, index) => {
			const name = currentName(user.name);
			const resentName = name[0];
			const otherName = name.slice(1);
			const state = user.address[0].state || '';
			return (
				<ExpansionPanel
					key={`user-result-${index}`}
					expanded={expanded === `panel${index}`}
					onChange={handleChange(`panel${index}`)}
				>
					<ExpansionPanelSummary
						expandIcon={<ExpandMore />}
						aria-controls={`user-result-panel${index}`}
						id={`user-panel${index}`}
					>
						<div className={classes.headerText}>
							<div className={classes.heading}>{resentName}</div>
							<div className={classes.content}>{state}</div>
						</div>
					</ExpansionPanelSummary>
					<ExpansionPanelDetails>
						<div className={classes.bodyText}>
							<div className={classes.headerText}>
								<PersonPin className={classes.bodyIcon} />
								<div className={classes.bodyContent}>{resentName}</div>
							</div>
							<div className={classes.headerText}>
								<PersonAdd className={classes.bodyIcon} />
								<div className={classes.bodyContent}>
									{otherName.map((q, i) => {
										if (i === otherName.length - 1) {
											return q;
										}
										return `${q}, `;
									})}
								</div>
							</div>
							<div className={classes.headerText}>
								<LocationCity className={classes.bodyIcon} />
								<div className={classes.bodyContent}>{state}</div>
							</div>
							<div className={classes.bodyButton}>
								<Button
									className={classes.button}
									color="primary"
									variant="contained"
									onClick={() => onHandleSelect(user)}
								>
									Select this user
								</Button>
							</div>
						</div>
					</ExpansionPanelDetails>
				</ExpansionPanel>
			);
		});
	};

	return (
		<Grid container direction="row" justify="center" alignItems="center">
			<Paper elevation={3} className={classes.paperContainer}>
				<Grid item xs={12}>
					{displayPanel()}
				</Grid>
			</Paper>
		</Grid>
	);
};

export default UserVerifyResults;
