import { userEmail, blAddress } from '../../UserInfo';
import { YoorzAPI, YoorzBlockAPI } from '../../YoorzAPI';

export const getUsers = async (email) => {
  try {
    const userList = await YoorzAPI().get(`/fetchUsers?email=${email}`);
    if (!userList.data.error) {
      return userList.data;
    }
    return userList.data.error;
  } catch (err) {
    return err;
  }
};

export const getUserIdForStream = async (email) => {
  try {
    const userId = await YoorzAPI().get(`/stream/${email}`);
    if (userId.data.id) {
      return userId.data.id;
    }
    return false;
  } catch (err) {
    return err;
  }
};
