import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { CameraFeed } from "./CameraFeed";
import { setIdrndToken, setIdrndUploadeStatus, setOnFidoImageUrl } from "./actions";
import PreviewImage from "./PreviewImage";
import { makeStyles } from '@material-ui/styles';
import { Card } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '50px',
    maxWidth: '700px',
    margin: 'auto'
  },
}));

const IdRnd = props => {

  const classes = useStyles();

  const [currentImage, setCurrentImage] = React.useState("");

  const uploadedState = async image => {
    setCurrentImage(image);
  };

  return (
    <Card className={classes.root}>
      {currentImage ? (
        <PreviewImage
          currentImage={currentImage}
          sendFile={uploadedState}
          setIdrndUploadeStatus={props.setIdrndUploadeStatus}
          setIdrndToken={props.setIdrndToken}
          setOnFidoImageUrl={props.setOnFidoImageUrl}
        />
      ) : (
          <CameraFeed sendFile={uploadedState} />
        )}
    </Card>
  );
};

IdRnd.prototype = {
  setIdrndToken: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  setIdrndUploadeStatus: PropTypes.func.isRequired,
  setOnFidoImageUrl: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  email: state.rule.page1.email
});
const mapDispatchToProps = dispatch => ({
  setIdrndToken: (idRnd, probability) =>
    dispatch(setIdrndToken(idRnd, probability)),
  setIdrndUploadeStatus: isUploaded =>
    dispatch(setIdrndUploadeStatus(isUploaded)),
  setOnFidoImageUrl: imageUrl => dispatch(setOnFidoImageUrl(imageUrl))
});

export default connect(mapStateToProps, mapDispatchToProps)(IdRnd);
