import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Tooltip } from '@material-ui/core';

const useStylesBootstrap = makeStyles({
  tooltip: {
    backgroundColor: '#000'
  }
});

export default function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();
  return <Tooltip classes={classes} {...props} />;
}