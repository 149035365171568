import axios from 'axios';
import { setEmail, setApiToken } from './UserInfo';

export const URL = 'https://verify.yoorz.me/api/v1';
export const BLOCKURL = 'https://verify.yoorz.me/api';
export const URLDOCVERIFY = 'https://verify.yoorz.me/document/';
export const URLAVATAR = "https://yoorzprod.file.core.windows.net/avatar-prod";

// export const URL = 'https://access.yoorz.me/api/v1';
// export const BLOCKURL = 'https://access.yoorz.me/api';
// export const URLDOCVERIFY = 'https://access.yoorz.me/document/';
// export const URLAVATAR = "https://yoorzprod.file.core.windows.net/avatar";

export const EMAIL_VERIFY_URL = 'https://api.email-validator.net/api';
export const ZIP_VERIFY = 'https://tools.usps.com/tools/app/ziplookup/cityByZip';
//export const BACKCHECK = 'https://verify.yoorz.me/idi/_te5t';

const signInAPI = axios.create({
  baseURL: URL,
  mode: 'cors',
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  }
});

export const signInBlockAPI = axios.create({
  baseURL: BLOCKURL,
  mode: 'cors',
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  }
});

export const signIn = (username, password) => {
  return signInAPI
    .post('/signin', {
      email: username,
      password
    })
    .then((res) => {
      if (res.data.login === 'Success') {
        setCookie(res.data.token, username);
        return res.data;
      }
      return res.data;
    })
    .catch((err) => {
      console.log('Sign in ', err);
    });
};

export const socialSignIn = (username) => {
  return signInAPI
    .post('/socialsignin', {
      email: username
    })
    .then((res) => {
      if (res.data.isVerified) {
        setCookie(res.data.token, username);
        return res.data;
      }
      return (res.data = false);
    });
};

export const onboardSetUser = (email) => {
  return signInAPI
    .post('/onboard?setUser=true', {
      email: email
    })
    .then((res) => {
      if (res.data.token) {
        // setCookie(res.data.token, email);
        setEmail(email);
        setApiToken(res.data.token);
        return true;
      }
      return false;
    });
};

const setCookie = (token, email) => {
  document.cookie = `yoorz-auth0=${token}`;
  document.cookie = `yoorz-email=${email}`;
  return;
};

export const getCookie = (name) => {
  let nameEQ = name + '=';
  let ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

export default signInAPI;
