import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, TextField, Button, Paper, ButtonGroup } from '@material-ui/core';
import { connect } from 'react-redux';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchSample from './SearchSample.json';
//import { postBackCheck } from './Request';
import { postBackCheck } from '../../API/page4BackgroundAPI';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4)
  },
  paperContainer: {
    width: '80%',
    marginTop: '10px',
    padding: '10px'
  },
  button: {
    textAlign: 'right'
  },
  disablePanel: {
    pointerEvents: 'none',
    opacity: 0.2
  },
  submit: {
    textAlign: 'center'
  },
  searchType: {
    margin: 'auto'
  },
  buttonSearchType: {
    width: '200px'
  }
}));

const UserVerifySearch = (props) => {
  const classes = useStyles();

  const [expand, setExpand] = React.useState(false);
  const [values, setValues] = React.useState({
    firstName: '',
    lastName: '',
    state: '',
    address: '',
    city: '',
    zip: '',
    ssn: '',
    email: '',
    phonenumber: ''
  });
  const [searchType, setSearchType] = React.useState('name');

  const onHandleSubmit = async () => {
    const search = await postBackCheck({
      firstName: values.firstName ? values.firstName : null,
      lastName: values.lastName ? values.lastName : null,
      state: values.state ? values.state : null,
      address: values.address ? values.address : null,
      city: values.city ? values.city : null,
      zip: values.zip ? values.zip : null,
      middleName: values.ssn ? values.ssn : null,
      ageMax: null,
      ageMin: null,
      phonenumber: values.phonenumber ? values.phonenumber : null
    });
    // props.setResult(SearchSample.result);
    console.log('Submit called', JSON.stringify(search));
    if (search.error && search.error.code) {
      props.setResultError(search.error);
    }
    if (search.length) {
      props.setResult(search);
    } else {
      props.setResultError({ code: 'No result', message: ' No result to display ' });
    }
  };

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };
  return (
    <Grid container direction="row" justify="center" alignItems="center">
      <Paper elevation={3} className={classes.paperContainer}>
        <Grid container>
          <Grid item className={classes.searchType}>
            <ButtonGroup color="primary">
              <Button
                className={classes.buttonSearchType}
                onClick={() => setSearchType('name')}
                variant={searchType === 'name' ? 'contained' : ''}
              >
                Name Based Search
							</Button>
              <Button
                className={classes.buttonSearchType}
                onClick={() => setSearchType('phone')}
                variant={searchType === 'phone' ? 'contained' : ''}
              >
                Phone Based Search
							</Button>
            </ButtonGroup>
          </Grid>
          <Grid item md={12}>
            <Grid container xs={12} spacing={2} direction="row" justify="center">
              {searchType !== 'phone' ? (
                <>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      required
                      autoComplete="off"
                      label="First Name"
                      margin="dense"
                      name="firstName"
                      onChange={handleChange}
                      value={values.firstName}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      required
                      autoComplete="off"
                      label="Last Name"
                      margin="dense"
                      name="lastName"
                      onChange={handleChange}
                      value={values.lastName}
                    />
                  </Grid>
                </>
              ) : (
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      autoComplete="off"
                      label="Phone"
                      margin="dense"
                      name="phonenumber"
                      onChange={handleChange}
                      value={values.phonenumber}
                    />
                  </Grid>
                )}
              <Grid item xs={12} className={classes.button}>
                <Button
                  color="primary"
                  onClick={() => setExpand(!expand)}
                  size="small"
                  endIcon={expand ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                >
                  Advanced Search
								</Button>
              </Grid>
              {expand && (
                <>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      autoComplete="off"
                      label="Adress"
                      margin="dense"
                      name="address"
                      onChange={handleChange}
                      value={values.address}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      autoComplete="off"
                      label="City"
                      margin="dense"
                      name="city"
                      onChange={handleChange}
                      value={values.city}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      autoComplete="off"
                      label="State"
                      margin="dense"
                      name="state"
                      onChange={handleChange}
                      value={values.state}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      autoComplete="off"
                      label="Zip Code"
                      margin="dense"
                      name="zip"
                      onChange={handleChange}
                      value={values.zip}
                    />
                  </Grid>
                  {searchType !== 'phone' && (
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        autoComplete="off"
                        label="Phone"
                        margin="dense"
                        name="phonenumber"
                        onChange={handleChange}
                        value={values.phonenumber}
                      />
                    </Grid>
                  )}
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      autoComplete="off"
                      label="SSN"
                      margin="dense"
                      name="ssn"
                      onChange={handleChange}
                      value={values.ssn}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      autoComplete="off"
                      label="Email"
                      margin="dense"
                      name="email"
                      onChange={handleChange}
                      value={values.email}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12} className={classes.submit}>
                <Button color="primary" variant="contained" onClick={onHandleSubmit}>
                  Submit
								</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default UserVerifySearch;
