import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, Card, Typography, List, ListItem, Avatar, ListItemText, ListItemAvatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Message } from '@material-ui/icons';
import { initializeChannel, initializeChat } from '../../../../streamAPI';

const useStyles = makeStyles({
	fullHeight: {
		height: '100%'
	},
	fullWidthHeight: {
		height: '100%',
		width: '100%'
	},
	green: {
		color: '#fff',
		backgroundColor: '#fb8846'
	},
	red: {
		color: '#fff',
		backgroundColor: '#e89723'
	},
	listRoot: {
		width: '100%',
		borderBottom: '1px solid #000'
	},
	listAvatar: {
		textAlign: 'left',
		marginRight: '5px'
	},
	listIcons: {
		textAlign: 'right'
	}
});

const UserList = (props) => {
	const classes = useStyles();

	const [ userList, setUserList ] = React.useState([]);
	const [ error, setError ] = React.useState(null);

	React.useEffect(() => {
		setUserList(props.users);
	}, []);

	const handleMessageOnClick = async (user) => {
		const data = [ `${props.userId}`, user._id ];
		const channel = await initializeChannel(data);
		props.setNewChannel(channel);
		props.onBack();
	};

	console.log(props.sasToken);
	const loadChat = () => (
		<List>
			{userList.map((user, index) => {
				return (
					<ListItem key={`user-list-${index}`} className={classes.listRoot}>
						<ListItemAvatar className={classes.listAvatar}>
							<Avatar
								alt={`${user.page1.firstName} ${user.page1.lastName}`}
								src={`${user.page5.imageUrl}?${props.sasToken}`}
							/>
						</ListItemAvatar>
						<ListItemText primary={`${user.page1.firstName} ${user.page1.lastName}`} />
						<ListItemAvatar className={classes.listIcons} onClick={() => handleMessageOnClick(user)}>
							<Avatar className={classes.red}>
								<Message />
							</Avatar>
						</ListItemAvatar>
					</ListItem>
				);
			})}
		</List>
	);

	return (
		<Card className={classes.fullHeight}>
			<Grid container className={classes.fullHeight} direction="column" justify="flex-start" alignItems="center">
				{error && userList.length ? (
					<Grid item>
						<Typography variant="h5">Error loading Chat</Typography>
					</Grid>
				) : (
					<Grid item className={classes.fullWidthHeight}>
						{loadChat()}
					</Grid>
				)}
			</Grid>
		</Card>
	);
};

UserList.prototype = {
	users: PropTypes.array.isRequired,
	userId: PropTypes.string.isRequired,
	onBack: PropTypes.func.isRequired,
	setNewChannel: PropTypes.func.isRequired
};

export default UserList;
