import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Button, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import {
  CloudUpload,
  ThumbUpAltOutlined,
  ThumbDownOutlined,
  ArrowBack
} from '@material-ui/icons';
import Loading from '../../Loading';
import { postUploadIdRndImage, postTestLiveness } from '../../../API/page5IdrndAPI';
import { userEmail } from '../../../UserInfo';
import { URLAVATAR } from '../../../api'


const useStyles = makeStyles({
  button: {
    marginTop: '10px'
  },
  imgSize: {
    width: '550px',
    height: '320px'
  }
});

const PreviewImage = props => {
  const classes = useStyles();

  const [logic, setlogic] = React.useState('');
  const [currentBlob, setCurrentBlob] = React.useState('');
  const [isUploadable, setIsUploadable] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    uploadImage();
  }, []);

  const uploadImage = () => {
    setIsLoading(true);
    fetch(props.currentImage)
      .then(res => res.blob())
      .then(async blob => {
        setCurrentBlob(blob);
        let dict = new FormData();
        dict.append('image', blob);
        dict.append('email', userEmail)

        const response = await postTestLiveness(dict)
        // console.log(response)

        // if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
        //       const response = JSON.parse(req.responseText);
        setlogic(response);
        setIsLoading(false);
        if (response.probability && response.probability * 100 > 90) {
          props.setIdrndToken(
            response,
            parseInt(response.probability * 100)
          );
          setIsUploadable(true);
        } else {
          setIsUploadable(false);
        }
      }).catch(error => {
        setIsLoading(false)
      });
  };

  const uploadProfileImage = () => {
    setIsLoading(true);
    let dict = new FormData();
    dict.append('avatar', currentBlob, userEmail);

    postUploadIdRndImage(dict)
      .then(res => {
        console.log(res);
        if (res.msg && res.file) {
          props.setOnFidoImageUrl(`${URLAVATAR}/${res.file}`);
          props.setIdrndUploadeStatus(true);
        }
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
      });
  };

  console.log(isLoading)

  return (
    <>
      <Grid
        container
        style={{
          alignContent: 'center',
          justifyContent: 'space-around',
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '900px'
        }}
      >
        <Grid
          item
          style={{
            border: '2px solid #3f51b5',
            marginTop: '5px',
            display: 'flex',
            flexDirection: 'column',
            width: 'auto',
            alignItems: 'center',
            margin: '20px'
          }}
        >
          <img
            className={classes.imgSize}
            src={props.currentImage}
            alt='idrnd image'
          />
          <Grid
            style={{ width: '100%', display: 'flex', flexDirection: 'row' }}
          >
            <Grid item style={{ width: '33%', textAlign: 'center' }}>
              {logic.probability && !logic.error_code && (
                <Typography
                  variant='h5'
                  style={{
                    marginTop: '5px',
                    color:
                      parseInt(logic.probability * 100) > 90 ? 'green' : 'red'
                  }}
                >
                  {`Image Liveliness: ${parseInt(logic.probability * 100)}%   `}
                  {(logic.probability * 100 > 90) ? (
                    <ThumbUpAltOutlined color='primary' />
                  ) : (
                    <ThumbDownOutlined color='secondary' />
                  )}
                </Typography>
              )}
            </Grid>
            <Grid item style={{ width: '33%', textAlign: 'center' }}>
              {logic.error ? (
                <IconButton
                  color='primary'
                  onClick={() => props.sendFile('')}
                  component='span'
                  style={{
                    border: '2px solid #3f51b5',
                    marginTop: '5px',
                    marginBottom: '5px'
                  }}
                >
                  <ArrowBack />
                </IconButton>
              ) : (
                <IconButton
                  color='primary'
                  onClick={uploadProfileImage}
                  component='span'
                  style={{
                    border: '2px solid #3f51b5',
                    marginTop: '5px',
                    marginBottom: '5px'
                  }}
                >
                  <CloudUpload />
                </IconButton>
              )}
            </Grid>
            <Grid item style={{ width: '33%', textAlign: 'center' }}>
              {logic.error && (
                <Typography
                  variant='h5'
                  style={{ marginTop: '20px', color: 'red' }}
                >
                  Image Small ! Retake
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={3}
        direction='row'
        justify='center'
        alignItems='center'
        style={{ marginBottom: '20px' }}
      >
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {!logic.error ? (
              <Grid item>
                <Button
                  onClick={() => props.sendFile('')}
                  className={classes.button}
                  variant='contained'
                  color='primary'
                  startIcon={<ArrowBack />}
                >
                  Retake
                </Button>
              </Grid>
            ) : null}
            <Grid item>
              <Button
                disabled={!isUploadable}
                onClick={uploadProfileImage}
                className={classes.button}
                variant='contained'
                color='primary'
              >
                Upload Image
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

PreviewImage.prototype = {
  sendFile: PropTypes.func.isRequired,
  setIdrndUploadeStatus: PropTypes.func.isRequired,
  setIdrndToken: PropTypes.func.isRequired,
  currentImage: PropTypes.string.isRequired,
  setOnFidoImageUrl: PropTypes.func.isRequired
};

export default PreviewImage;
