import React from 'react';
import { connect } from 'react-redux';
import { twitterLoginSuccess, twitterLoginFailure } from './actions';
import TwitterLogo from '../../views/SignIn/twitter.png';
import TwitterLogin from 'react-twitter-auth'

const TwitterLoginPage = props => {
  const responseTwitter = response => {
    props.twitterLoginSuccess(response);
  };

  const handleError = error => {
    this.setState({ error });
  };

  return (
    <TwitterLogin
      loginUrl="http://localhost:3000/api/v1/auth/twitter"
      onFailure={handleError}
      onSuccess={responseTwitter}
      requestTokenUrl="http://localhost:3000/api/v1/auth/twitter/reverse"
      showIcon={true}
    //customHeaders={customHeader}
    >
      <span>
        <img alt="f" height="30px" src={TwitterLogo} width="30px" />
      </span>
    </TwitterLogin>


  );
};

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => ({
  twitterLoginSuccess: response => dispatch(twitterLoginSuccess(response)),
  twitterLoginFailure: response => dispatch(twitterLoginFailure(response))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TwitterLoginPage);
