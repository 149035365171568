import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import moment from "moment";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Typography,
  Divider,
  Button,
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {},
  details: {
    display: "flex"
  },
  avatar: {
    marginLeft: "auto",
    height: 110,
    width: 100,
    flexShrink: 0,
    flexGrow: 0
  },
  progress: {
    marginTop: theme.spacing(2)
  },
  uploadButton: {
    marginRight: theme.spacing(2)
  }
}));

const AccountProfile = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const [isCamera, setIsCamera] = React.useState(true);

  //const handleCamera = event => {
  //  event.preventDefault();
  //  setIsCamera(!isCamera);
  //};

  const profileCard = () => (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent>
        <div className={classes.details}>
          <div>
            <Typography gutterBottom variant="h2">
              {`${props.firstName} ${props.lastName}`}
            </Typography>
            <Typography
              className={classes.locationText}
              color="textSecondary"
              variant="body1"
            >
              {props.userInfo.city}, {props.userInfo.country}
            </Typography>
            <Typography
              className={classes.dateText}
              color="textSecondary"
              variant="body1"
            >
              {moment().format("hh:mm A")} ({new Date().getTimezoneOffset()})
            </Typography>
          </div>
          <Avatar
            className={classes.avatar}
            src={props.imageUrl ? props.imageUrl : null}
          />
        </div>
      </CardContent>
      <Divider />
      <CardActions>
        <Button
          className={classes.uploadButton}
          color="primary"
          variant="text"
          onClick={() => props.imageCaptureMode("upload")}
        >
          Upload picture
        </Button>
        <Button onClick={() => props.imageCaptureMode("camera")} variant="text">
          Capture picture
        </Button>
      </CardActions>
    </Card>
  );

  // const loadIdRnd = () => (
  //   <Grid style={{ paddingBottom: "none !important" }}>
  //     <Card {...rest} className={clsx(classes.root, className)}>
  //       <Grid item>
  //         <IdRnD />
  //         <Divider />
  //         <CardActions
  //           style={{
  //             padding: "2px !important",
  //             justifyContent: "space-around"
  //           }}
  //         >
  //           <Button onClick={handleCamera} color="primary" variant="contained">
  //             Done
  //           </Button>
  //         </CardActions>
  //       </Grid>
  //     </Card>
  //   </Grid>
  // );
  // return <div>{isCamera ? profileCard() : loadIdRnd()}</div>;
  return profileCard();
};

AccountProfile.prototype = {
  userInfo: PropTypes.object.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  imageCaptureMode: PropTypes.func.isRequired
};

export default AccountProfile;
