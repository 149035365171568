import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, LinearProgress, Divider, Button } from '@material-ui/core';
import { List, ListItem, ListItemIcon, ListItemText, Modal } from '@material-ui/core';
import { Gavel, QueryBuilder, AccountBalance, ErrorOutline, HighlightOff } from '@material-ui/icons';
import HandCuff from '../Verification/assets/images/handcuff.png';
import { postUpdateSearch } from './Request';
import { BootstrapTooltip } from '../../utils';

const useStyle = makeStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: '#fff',
    height: '40%',
    width: '40%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '50px',
    border: '1px solid #000'
  },
  close: {
    textAlign: 'center'
  }
});

const UserVerifyResultModel = (props) => {
  const classes = useStyle();
  const [progress, setProgress] = useState(0);
  const [modalStatus, setModelState] = useState(true);

  useEffect(() => {
    postUpdateSearch(props.result);
    function count() {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          clearInterval(timer);
        }
        return oldProgress + 1;
      });
    }

    const timer = setInterval(count, 125);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const onClosehandle = async () => {
    props.setResult([]);
    props.getUserInfo();
    setModelState(false);
  };

  const returnStatus = (info) => {
    if (info.length) {
      return (
        <BootstrapTooltip title="Found records">
          <ErrorOutline style={{ color: 'red' }} />
        </BootstrapTooltip>
      );
    }
    return (
      <BootstrapTooltip title="No Records! Clean">
        <HighlightOff color="primary" />
      </BootstrapTooltip>
    );
  };
  return (
    <Modal
      open={modalStatus}
      aria-labelledby="server-modal-title"
      aria-describedby="server-modal-description"
      className={classes.modal}
      spacing={1}
    >
      <Grid item md={6} xs={12} className={classes.paper}>
        <Grid container xs={12} spacing={3} direction="column" justify="center">
          <Grid item>
            <List component="nav" aria-label="main mailbox folders">
              <ListItem button>
                <ListItemIcon>
                  <Gavel />
                </ListItemIcon>
                <ListItemText primary="Judgement Records" />
                {progress < 10 ? (
                  <QueryBuilder variant="determinate" color="secondary" />
                ) : (
                    returnStatus(props.result[0].criminal)
                  )}
              </ListItem>
              <Divider />
              <ListItem button>
                <ListItemIcon>
                  <img src={HandCuff} height="30px" />
                </ListItemIcon>
                <ListItemText primary="Criminal Background check" />{' '}
                {progress < 45 ? (
                  <QueryBuilder variant="determinate" color="secondary" />
                ) : (
                    returnStatus(props.result[0].judgment)
                  )}
              </ListItem>
              <Divider />
              <ListItem button>
                <ListItemIcon>
                  <AccountBalance />
                </ListItemIcon>
                <ListItemText primary="Bankruptcy Report" />
                {progress < 90 ? (
                  <QueryBuilder variant="determinate" color="secondary" />
                ) : (
                    returnStatus(props.result[0].bankruptcy)
                  )}
              </ListItem>
            </List>
          </Grid>
          <Grid item>
            <LinearProgress variant="determinate" value={progress} />
            <LinearProgress color="secondary" variant="determinate" value={progress} />
          </Grid>
          <Grid item className={classes.close}>
            <Button color="primary" variant="contained" onClick={onClosehandle}>
              Close
						</Button>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default UserVerifyResultModel;
