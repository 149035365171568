import { YoorzBlockAPI } from '../YoorzAPI';
import { blAddress, userEmail } from "../UserInfo";

export const postBlNotification = async (notificationType, value) => {
  try {
    let notification = null;
    switch (notificationType) {
      case 'email':
        notification = await YoorzBlockAPI().post(`/notification/setnotificationemail`, {
          address: blAddress,
          value: value
        });
      case 'text':
        notification = await YoorzBlockAPI().post(`/notification/setnotificationtext`, {
          address: blAddress,
          value: value
        });
      case 'call':
        notification = await YoorzBlockAPI().post(`/notification/setnotificationcall`, {
          address: blAddress,
          value: value
        });
      case 'push':
        notification = await YoorzBlockAPI().post(`/notification/setnotificationpush`, {
          address: blAddress,
          value: value
        });
    }
    if (notification.data.address !== "0x0000000000000000000000000000000000000000") {
      return true;
    }
    return false;
  } catch (err) {
    return false;
  }
};

export const getBlNotification = async () => {
  try {
    const notification = await YoorzBlockAPI().post(`/notification/getnotification`, {
      address: blAddress
    });
    if (notification.data.address !== "0x0000000000000000000000000000000000000000") {
      return notification.data;
    }
    return false;
  } catch (err) {
    return err;
  }
};

export const postBlChangePassword = async (oldPassword, newPassword) => {
  try {
    const update = await YoorzBlockAPI().post('/users/updatepassword', {
      address: blAddress,
      oldPassword: oldPassword,
      newPassword: newPassword
    });
    if (update.data.msg === 'Password updated successfully') {
      return true;
    }
    return false;
  } catch (err) {
    return false;
  }
};


export const postBlResetPassword = async (newPassword) => {
  try {
    const update = await YoorzBlockAPI().post('/users/resetpassword', {
      address: blAddress,
      email: userEmail,
      newpassword: newPassword
    });
    if (update.data.msg === 'Password updated successfully') {
      return true;
    }
    return false;
  } catch (err) {
    return false;
  }
};
