import { combineReducers } from 'redux';
import * as Constants from './constants';
import socialReducer from './components/SocialVerification/reducers';
import ruleReducer from './views/Verification/components/reducers';

const initialState = {
  login: false,
  phone: null,
  verify: false,
  loginError: null
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case Constants.LOGIN_SUCCESS:
      return { ...state, login: true, phone: action.phone, loginError: null };
    case Constants.LOGIN_FAILURE:
      return { ...state, login: false, loginError: action.error, phone: null };
    case Constants.VERIFY_PROCESS:
      return { ...state, verify: true, loginError: null };
    default:
      return state;
  }
};

const appReducer = combineReducers({
  social: socialReducer,
  rule: ruleReducer,
  login: loginReducer,
});

const resetState = appReducer({}, {}, {});

const rootReducer = (state, action) => {
  if (action.type === Constants.LOGOUT_SUCCESS) {
    state = resetState;
  }

  return appReducer(state, action);
};

export default rootReducer;
// export default combineReducers({
//   social: socialReducer,
//   rule: ruleReducer,
//   login: loginReducer
// });
