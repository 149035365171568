import moment from 'moment';

export const complexQuestionsUtil = (props) => {
  return [
    {
      QuestionType: 'FirstName',
      InputValue: props.firstName,
      IncludeOriginal: true,
      Options: props.userInfo.gender === "Don't Disclose" ? "" : props.userInfo.gender
    },
    {
      QuestionType: 'LastName',
      InputValue: props.lastName,
      IncludeOriginal: true,
      Options: props.userInfo.gender === "Don't Disclose" ? "" : props.userInfo.gender
    },
    {
      QuestionType: 'PhoneNumber',
      InputValue: props.phone && `${props.phone.substring(2, 5)} ${props.phone.substring(5, 8)} ${props.phone.substring(8)}`,
      IncludeOriginal: true,
      Options: ''
    },
    {
      QuestionType: 'BirthYear',
      InputValue: moment(props.selectedDate).format('YYYY'),
      IncludeOriginal: true,
      Options: ''
    },
    {
      QuestionType: 'BirthDate',
      InputValue: moment(props.selectedDate).format('L'),
      IncludeOriginal: true,
      Options: 'YYYY-MM-DD'
    },
    {
      QuestionType: 'ResidenceCity',
      InputValue: props.userInfo.city,
      IncludeOriginal: true,
      Options: ''
    },
    {
      QuestionType: 'ResidenceState',
      InputValue: props.userInfo.state,
      IncludeOriginal: true,
      Options: ''
    }
  ];
}