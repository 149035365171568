
import React from 'react';
import { GiftedChat, Time, Bubble } from 'react-web-gifted-chat';

class ChatWindow extends React.Component {

  constructor() {
    super();
    this.state = {
      pageSize: 30,
      isNewMsg: false,
      msgLoaded: false,
      lastEarliestMsgIndex: 0,
      messages: [],
      currChannel: null,
      inputDisabled: false,
    };
  }

  async componentDidMount() {
    const client = this.props.channelInit.chatClient;
    try {
      if (this.props.channel.key === 'general_1')
        this.setState({ inputDisabled: true });

      const channel = await client.getChannelByUniqueName(this.props.channel.key);
      this.state.currChannel = channel;
      const msgs = await channel.getMessages();

      this.messagesLoaded(msgs);
      await channel.setAllMessagesConsumed();
      this.setState({ msgLoaded: true });
      channel.on('messageAdded', this.messageAdded);


    } catch (err) {
      console.log(err);
      this.setState({ channelLoaded: false });   //[Error: Unable to connect: disconnected]

      if (err.message === 'request timeout') {
        console.log("request timeout handled in chatview");
      }
    }
  }

  messageAdded = (message) => {
    try {
      this.setState({ isNewMessage: true });
      this.setState(prevState => ({
        messages: GiftedChat.append(prevState.messages, this.convertMessage(message)),

      }));
      this.setState({ isNewMessage: false });
      this.state.currChannel.setAllMessagesConsumed();
    } catch (err) { }

  };

  convertMessage = (message) => {
    var user, user_id;
    if (this.state.inputDisabled) {
      user = `Yoorz`;
      user_id = message.author;
    } else {
      user = `${JSON.parse(message.author).firstName} ${JSON.parse(message.author).lastName}`;
      user_id = JSON.parse(message.author).user;
    }
    if (!this.state.isNewMessage)
      this.setState({ lastEarliestMsgIndex: message.index });

    return {
      _id: message.sid,
      text: message.body,
      user: { _id: user_id, name: user },
      createdAt: message.timestamp
    };
  };

  messagesLoaded = (messagePage) => {

    this.setState({
      messages: messagePage.items.map(this.convertMessage).reverse()
    });

  };

  sendMessage = async (message) => {
    if (this.state.currChannel) {
      try {
        let res = await this.state.currChannel.sendMessage(message[0].text);
      } catch (err) {
        console.log(err);
      }
      try {
        await this.state.currChannel.setAllMessagesConsumed();
      } catch (err) { }
    }
  };

  isCloseToTop({ layoutMeasurement, contentOffset, contentSize }) {
    const paddingToBottom = 10;
    return layoutMeasurement.height + contentOffset.y >=
      contentSize.height - paddingToBottom;
    // return contentOffset.y == 0
  }

  loadMoreChat = async () => {

    if (this.state.lastEarliestMsgIndex >= 30) {
      const anchor = this.state.lastEarliestMsgIndex - this.state.pageSize;
      try {
        const msgs = await this.state.currChannel.getMessages(this.state.pageSize, anchor);
        this.setState(prevState => ({
          messages: GiftedChat.prepend(prevState.messages, msgs.items.map(this.convertMessage).reverse()),

        }));
      } catch (err) {
        console.log(err);
      }
    }
  };

  render() {
    return (
      <GiftedChat
        messages={this.state.messages}
        scrollToBottom
        // inverted={false}
        renderInputToolbar={this.state.inputDisabled ? (() => null) : undefined}
        onSend={(message) => this.sendMessage(message)}
        renderAvatar={() => null}
        renderTime={(props) =>
          <Time
            {...props}
            timeTextStyle={{
              left: {
                color: 'white',
              },
            }}
          />}
        // renderAvatar={()=>
        //   <Avatar 
        //     rounded
        //     source={{
        //       uri:
        //         `${this.props.channel.image}`
        //       }}
        //   />
        // }
        renderBubble={(props) =>
          <Bubble
            {...props}
            textStyle={{
              left: { color: 'white', }
            }}
            containerToNextStyle={{
              left: {
                left: -35,
              }
            }}
            wrapperStyle={{
              left: {
                backgroundColor: '#6348fa',
              },
            }}
          />
        }
        user={{
          _id: this.props.user.user, //change this to user address
          name: `${this.props.user.firstName} ${this.props.user.lastName}`,
          //avatar:''
        }}
        listViewProps={
          {

            //scrollEventThrottle: 400,
            // onScroll:({ nativeEvent }) => {
            //   if (this.isCloseToTop(nativeEvent) ) {
            //     this.loadMoreChat();
            //   }
            // },
            onEndReached: () => {
              this.loadMoreChat();
            },
            onEndReachedThreshold: 0.2
            // ref:(ref)=>{
            //   if(ref!=null){
            //     this.viewref=ref
            //     // this.viewref._messageContainerRef.current.scrollToEnd();
            //   }
            // },

          }
        }
      />
    );
  }
}

export default ChatWindow;