import React from 'react'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { googleLoginSuccess, googleLoginFailure } from './actions'
import GoogleLogin from 'react-google-login'
import { setAllValidItems } from '../../views/Verification/components/actions'
import { socialSignIn } from '../../api'
import { loginSuccess, loginFailure, verifyProcess } from '../../actions'
import * as Request from '../../views/Verification/Request'

const GoogleLoginPage = props => {
  const responseGoogle = async response => {
    console.log('google response', response.profileObj)
    try {
      if (response.profileObj) {
        props.googleLoginSuccess(response)
        const email = response.profileObj.email
        const firstName = response.profileObj.givenName
        const lastName = response.profileObj.familyName
        props.setAllValidItems(firstName, lastName, email, null)
        console.log(' email is ', email)
        if (email) {
          const { isVerified, phone } = await socialSignIn(email)
          console.log('Social verification ', isVerified, phone)
          if (isVerified) {
            props.loginSuccess(phone)
            return props.history.push('/initialize')
          }
        }
        props.loginFailure()
        props.verifyProcess()
        return props.history.push('/initialize')
      }
    } catch (error) {
      props.loginFailure()
      props.verifyProcess()
      props.googleLoginFailure(response)
      return props.history.push('/initialize')
    }
  }
  //old clientId="253734777501-mnn2p53a949rmt5fjnb3vnanrgcndnhb.apps.googleusercontent.com"
  return (
    <GoogleLogin
      clientId='877488202023-71mvom4pdglk5ors44vrcck31usfa96m.apps.googleusercontent.com'
      onSuccess={responseGoogle}
      onFailure={responseGoogle}
      render={renderProps => (
        <div
          className='login-page__button login-page__button--google'
          onClick={renderProps.onClick}
          disabled={renderProps.disabled}
        >
          <img
            alt='g'
            height='30px'
            src='https://img.icons8.com/color/48/000000/google-logo.png'
            style={{ marginTop: '7px' }}
          />
        </div>
      )}
    />
  )
}

GoogleLoginPage.propTypes = {
  setAllValidItems: PropTypes.func,
  loginSuccess: PropTypes.func,
  loginFailure: PropTypes.func,
  googleLoginSuccess: PropTypes.func,
  googleLoginFailure: PropTypes.func
}

const mapStateToProps = state => ({
  ...state,
  social: state.social
})

const mapDispatchToProps = dispatch => ({
  setAllValidItems: (firstName, lastName, email, phone) =>
    dispatch(setAllValidItems(firstName, lastName, email, phone)),
  googleLoginSuccess: response => dispatch(googleLoginSuccess(response)),
  googleLoginFailure: err => dispatch(googleLoginFailure(err)),
  loginSuccess: phone => dispatch(loginSuccess(phone)),
  loginFailure: () => dispatch(loginFailure()),
  verifyProcess: () => dispatch(verifyProcess())
})

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(GoogleLoginPage)
