import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';
import { Sidebar, Topbar, Footer } from './components';
import Loading from '../../views/Loading';
import {
  setAllValidItems,
  setConverusInfo,
  setBackgroundCheck,
  setSelectedDate,
  setExamUrl,
  setExamId,
  setOnfidoId,
  setOnFidoImageUrl,
  setImageCaptureDate
} from '../../views/Verification/components/actions';
import { getBlPage1 } from '../../API/page1PersonalAPI';
import { getBlPage2 } from '../../API/page2AddressAPI';
import { getBlPage4 } from '../../API/page4BackgroundAPI';
import { getBlPage5 } from '../../API/page5IdrndAPI';
import { userEmail } from '../../UserInfo';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 56,
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64
    }
  },
  shiftContent: {
    paddingLeft: 240
  },
  content: {
    flex: '1 0 auto',
    padding: '20px'
  },
  footer: {
    flexShrink: '0',
    backgroundColor: '#fff',
    height: '45px'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 80px)',
    marginTop: '9px'
  }
}));

const Main = (props) => {
  const { children } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  });

  const [openSidebar, setOpenSidebar] = useState(false);
  const [loading, setLoading] = React.useState(true);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const shouldOpenSidebar = isDesktop ? true : openSidebar;

  React.useEffect(() => {
    setLoading(false);

    //const email = getCookie('yoorz-email');

    getBlPage1()
      .then((res) => {
        if (res) {
          const { firstname, lastname, phonenumber } = res;
          props.setAllValidItems(firstname, lastname, userEmail, phonenumber);
        }
      })
      .catch((err) => {
        props.history.push('/signin');
      });

    getBlPage2()
      .then((res) => {
        if (res) {
          const { streetaddress, city, country, gender, martialstatus, stateandzip, dob } = res;
          const stateZip = stateandzip.split('-');
          const add = streetaddress.split(',');
          props.setConverusInfo({
            address1: add[0],
            address2: add[1] ? add[1] : null,
            city: city,
            country: "USA",
            gender: gender,
            marritalStatus: martialstatus,
            state: stateZip[0],
            zip: stateZip[1]
          });
          props.setSelectedDate(new Date(dob.replaceAll('/', ',')));
        }
      })
      .catch((err) => {
        props.history.push('/signin');
      });

    getBlPage5()
      .then((res) => {
        if (res) {
          props.setOnFidoImageUrl(res.imageurl);
          props.setImageCaptureDate(res.onfidoid);
        }
      })
      .catch((err) => {
        props.history.push('/signin');
      });

    getBlPage4()
      .then((res) => {
        if (res) {
          props.setBackgroundCheck({
            criminal: res.hasCriminalOffense === "none" ? null : true,
            bankruptcy: res.hasOffense === "none" ? null : true,
            judgment: res.hasSexOffense === "none" ? null : true,
            tracking: res.tracking
          });
        }
      })
      .catch((err) => {
        props.history.push('/signin');
      });

    setLoading(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const container = () => (
    <div>
      <Topbar onSidebarOpen={handleSidebarOpen} />
      <Sidebar
        onClose={handleSidebarClose}
        open={shouldOpenSidebar}
        variant={isDesktop ? 'persistent' : 'temporary'}
      />
      <div className={classes.container}>
        <main className={classes.content}>{children}</main>
        <footer className={classes.footer}>
          <Footer />
        </footer>
      </div>
    </div>
  );
  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: isDesktop
      })}
    >
      {loading ? container() : <Loading />}
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node,
  email: PropTypes.string,
  setAllValidItems: PropTypes.func,
  setConverusInfo: PropTypes.func,
  setBackgroundCheck: PropTypes.func,
  setSelectedDate: PropTypes.func,
  setExamUrl: PropTypes.func,
  setExamId: PropTypes.func,
  setOnfidoId: PropTypes.func,
  setOnFidoImageUrl: PropTypes.func,
  setImageCaptureDate: PropTypes.func,
  history: PropTypes.func
};

const mapStateToProps = (state) => {
  return {
    email: state.rule.page1.email
  };
};

const mapDispatchToProps = (dispatch) => ({
  setAllValidItems: (firstName, lastName, email, phone) =>
    dispatch(setAllValidItems(firstName, lastName, email, phone)),
  setConverusInfo: (info) => dispatch(setConverusInfo(info)),
  setBackgroundCheck: (info) => dispatch(setBackgroundCheck(info)),
  setSelectedDate: (info) => dispatch(setSelectedDate(info)),
  setExamUrl: (url) => dispatch(setExamUrl(url)),
  setExamId: (id) => dispatch(setExamId(id)),
  setOnfidoId: (id, face) => dispatch(setOnfidoId(id, face)),
  setOnFidoImageUrl: (url) => dispatch(setOnFidoImageUrl(url)),
  setImageCaptureDate: (date) => dispatch(setImageCaptureDate(date))
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Main);
