export const answerOptions = {
    "yes": {
        id: "83",
        value: "1"
    },
    "no": {
        id: "76",
        value: "0"
    },
    "none": {
        value: "-1"
    }
};