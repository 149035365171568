const transform = data => {
  let firstName, lastName, email, phone;
  if (data.DID && data.DID.name) {
  } else if (data.googleResponse && data.googleResponse.El) {
    email = data.googleResponse.w3.U3;
    firstName = data.googleResponse.w3.ofa;
    lastName = data.googleResponse.w3.wea;
  }
  return { firstName, lastName, email, phone };
};

export default transform;
