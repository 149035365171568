const TERMS = [
  {
    title: "Terms & Conditions",
    text: [
      'Yoorz, Inc. respects your right to privacy and we are committed to protecting it. This statement discloses the information practices for the web pages associated with the domain Yoorz.me (the "Website"), including what type of personal information we gather and track, how we use this personal information, and with whom we share the personal information.'
    ]
  },
  {
    title: "Personal Information",
    text: [
      "In general, you can visit us on the Internet without telling us who you are and without giving any personal information about yourself. However, you may choose to give us personal information in a variety of situations. For example, you may want to give us information such as your name and address, phone number or e-mail address so we may correspond with you, process an order, or provide you with a subscription or service. You may give us a recent photograph or yourself and a picture of your driver’s license You may give us your credit card details to buy a product or service from us, or provide us a description of your education and work experience in connection with a job opening at Yoorz, Inc. for which you wish to be considered. The purpose of this Policy is to inform you of how we will use your information, before you provide it. If you tell us that you do not want us to use this information in this manner or to make further contact with you beyond fulfilling your requests, we will respect your wishes. If you give us personal information about somebody else, such as a spouse or associate, we will assume that you have their permission to do so."
    ]
  },
  {
    title:
      "How to Remove your Information from the Yoorz, Inc. Public Records Databases",
    text: [
      "Public records are available from the official public records custodian or repository to anyone who requests them. For any database of public records to be useful, the databases must contain all the information in the public records offices. If you have a compelling privacy or security issue, youmay wish to contact the official custodians of those public records that contain sensitive information about you, such as your county's land records office, to determine how to remove your information from the public record. (The process of having public records sealed typically requires a court order.) This process will ensure that the information is not available to the public, to us, or to any other public records information provider.",
      "In addition to public records, personal information may be publicly or commercially available. Publicly vailable information consists of online and offline information that is generally available but is not maintained by a government agency, such as names, addresses and telephone numbers of individuals and businesses, professional licensing and trade organization information, press releases and newspaper articles and content from blogs or social networking sites. Commercial records consist of information that is maintained by enterprises and is available for purchase, such as marketing and telemarketing lists, phone connect and disconnect information, and business profile data.  As a courtesy we allow you to opt out your personal information from our Website.  What this means is that your name as it appears in a record and the associated identifying information such as your address and phone number will be suppressed if you request this in the manner described below.",
      'However, please note that any time your identifying information appears in a public record or in a publicly or commercially available manner, in a way that is different from the record you opted out, it will again appear on our Website. For example, if your address or area code changes, your new information -- including other associated identifying information -- will again appear unless you opt out the new record. Similarly, if the way in which your name or address appears in a record differs from a record you opted out (e.g., "Richard" instead of "Rick," or "46 Germain AVE NE" instead of "Fourty Six, Germain Avenue Northeast"), we may include the differing record. Please also note that there may be more than one record on our Website associated with your personal information.  Opting out one record will not opt out other record(s). In addition to this Website, there are many other companies offering public records search services, and your request that we opt out your information from this Website will not prevent your information from appearing on these other services.',
      "For us to suppress or opt out your personal information from appearing on our Website, we need to verify your identity. To do this, we require mobile text photo ID proof of identity. Proof of identity can be a state issued ID card or driver's license. If you are uploading, or mailing a copy of your driver's license, we require that you cross out the photo and the driver's license number. We only need to see the name, address and date of birth. We will only use this information to process your opt out request. To submit your opt out request online please send to: Privacy c/o Yoorz,  Inc.,  1600 Dove Street, Ste. 480 , Newport Beach, CA 92660."
    ]
  },
  {
    title: "Consumer Care",
    text: [
      "Allow 4 to 6 weeks to process your request. We will only process opt out requests received by online , text message or mail and no request will be processed without complete information (i.e., name, address and date of birth). Requests for opt out will not be processed over the phone or via email."
    ]
  },
  {
    title: "How we Use and Share Personal Information",
    text: [
      "The following paragraphs describe in more detail how we may use the personal information you provide to us and with whom we may share it.  Fulfilling your Transaction Request. If you request something from this Website, for example, a product or service, a callback, or specific marketing materials, we will use the information you provide to fulfill your request. To help us do this, we will share information with service providers to help us bring you the products and services we offer, either directly as the provider of such product or service, or indirectly such as financial institutions for credit card processing, email service providers, outsourced customer service providers, survey providers, or postal service providers. These third parties are prohibited from using your personally identifiable information for any other purpose. We also require these third parties to maintain your personal information in the strictest confidence. In addition, we will give the information you provide to our advertisers from whom you have indicated you want to purchase a product or service."
    ]
  },
  {
    title: "Communications from Us",
    text: [
      "When you request something from this Website, we will send you transactional emails related to your service request. You cannot opt out of receiving these communications. However, you can opt in to receive promotional and newsletter emails from us. If you later choose to opt out of receiving these emails, you can unsubscribe by clicking on the link included in the email or logging into your account and changing your preferences."
    ]
  },
  {
    title: "Marketing Use",
    text: [
      "The information you provide to us on this Website will also be used, with your permission, by us and selected third parties for marketing purposes. Before we use it, we will offer you the opportunity to choose whether to have your information used for this purpose. Otherwise, we do not share, sell, rent, or trade your personal information with third parties for their promotional purposes. In addition, like many websites, we gather certain information automatically (including cookies and log files). This information includes internet protocol (IP) addresses, browser type, internet service provider (ISP), referring/exit pages, operating system, date/time stamp and click stream data. We use this information, which does not identify individual users, to analyze trends, to administer this Website, to track users' movements around this Website and to gather demographic information about our user base."
    ]
  },
  {
    title: "Third Party Monitoring",
    text: [
      "In addition, service providers and other third parties such as advertisers may also use information gathering technologies on this Website (including cookies and beacons) in connection with their services to collect anonymous information. Shared and/or collected information, as described above, is controlled by the relevant affiliated company or third party and is not controlled by us; specifically, (i) such information is governed by the privacy policy and terms of use of the relevant other party and (ii) we will in no way be responsible or liable to you for any data breach by such other party that compromises or otherwise affects your information. Yoorz,Inc. Human Resources.",
      "In connection with a job application or inquiry, whether advertised on this website or otherwise, you may provide us with information about yourself, such as a resume or curriculum vitae. We use this information throughout Yoorz, Inc. and our affiliates for the purpose of employment consideration or otherwise responding to your inquiry. Unless you request that we don't, we may keep the information for future consideration."
    ]
  },
  {
    title: "Cookies and Other Web Technologies",
    text: [
      'We collect non-identifiable information from visits to our Web sites to help us provide better customer service. For example, we keep track of the domains from which people visit, and we also measure visitor activity on Yoorz, Inc. Web sites, but we do so in ways that keep our visitors\' identities anonymous Yoorz, Inc. uses this data to analyze trends and statistics and to help us provide better customer service. We collect the information we mentioned in the previous paragraphs through the use of various technologies, including one called "cookies". A cookie is a piece of data that a Web site can send to your browser, which is then be stored on your computer as an anonymous tag that identifies your computer but not you. Some Yoorz, Inc. pages use cookies, sent by Yoorz, Inc. or its third party vendors, or other technologies to better serve you when you return to the Web site. You can set your browser to notify you before you receive a cookie, giving you the chance to decide whether to accept it.',
      "You also can set your browser to turn off cookies. If you do so, however, some Web sites may not work properly. Our Yoorz, Inc. cookies are linked to personal information; however, our third party vendor cookies only collect data in the aggregate."
    ]
  },
  {
    title: "Online Advertising",
    text: [
      "Certain companies help us deliver interactive on-line advertising. These companies collect and use information about customers to help us better understand the offers, promotions, and types of advertising that are most appealing to our customers. After the information is collected, it is aggregated so, it is not identifiable to a specific individual."
    ]
  },
  {
    title: "Other Voluntary Information",
    text: [
      "If you use a blog or submit to a testimonial on our website, you should be aware that any personally identifiable information you submit there can be read, collected, or used by other users of these forums, and could be used to send you unsolicited messages. We are not responsible for the personally identifiable information you choose to submit in these forums."
    ]
  },
  {
    title: "Disclosures Required By Law",
    text: [
      "We reserve the right to disclose your personally identifiable information as required by law and when we believe that disclosure is necessary to protect our rights and/or comply with a judicial proceeding, court order or legal process served on our website."
    ]
  },
  {
    title: "Links to Non- Yoorz, Inc.  websites",
    text: [
      "This website contains links to other third-party websites. We are not responsible for the privacy practices or the content of those other websites."
    ]
  },
  {
    title: "Access to your Account",
    text: [
      "If you would like to update your personal information in your account such as your name, address or email address, you can do so by logging into your account and updating your personal information. Yoorz, Inc. "
    ]
  },
  {
    title: "Screening Solutions",

    text: [
      "In connection with an authorized employment or tenant screening of an applicant, we collect personally identifiable information about the applicant. We use this information only for the purpose of performing the authorized screening, and conduct such screening in accordance with the Fair Credit Reporting Act. We do not use this information for any other purpose."
    ]
  },
  {
    title: "Information Security",
    text: [
      "The security of your personal information is important to us. When you enter sensitive information (such as credit card number and/or social security number) on our registration or order forms, we encrypt that information using secure socket layer technology (SSL).  We follow generally accepted industry standards to protect the personal information submitted to us, both during transmission and once we receive it. No method of transmission over the Internet or method of electronic storage is 100% secure, however. ",
      "Therefore, while we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security. Enforcement. We regularly review our compliance with this Policy. Please feel free to direct any questions or concerns regarding this Policy to us by sending an email to our Chief Privacy Officer at  Or by writing us at: Privacy c/o Yoorz,  Inc.,  1600 Dove Street, Ste. 480 , Newport Beach, CA 92660."
    ]
  },
  {
    title: "Changes to this Policy",
    text: [
      "If we decide to change this Policy, we will post the changes to this Policy, the home page link, and other places we deem appropriate so that you are aware of what information we collect, how we use it, and under what circumstances, if any, we disclose it. We reserve the right to modify this Policy at any time, so please review it frequently. If we make material changes to this policy, we will notify you here, or by means of a notice on our home page."
    ]
  },
  {
    title: "Mobile End User Agreement",
    text: []
  },
  {
    title: "1.RESTRICTED LICENSE.",
    text: [
      "Yoorz,Inc. hereby grants to Customer (You) a restricted license to use the Yoorz,Inc.  Services and any data contained therein, subject to the restrictions and limitations set forth below:"
    ]
  },
  {
    title: "(i) Generally.",

    text: [
      "Yoorz,Inc. grants to Customer a restricted license to use the Yoorz,Inc. Services solely for Customer’s own personal use. Customer represents and warrants that its use of the Yoorz,Inc. Services shall be for lawful personal use and not for any other purpose, including, without limitation, commercial, collections, governmental, marketing or advertising. Customer shall not use the Yoorz,Inc. Services for marketing purposes or resell or broker the Yoorz,Inc. Services to any third party. Customer agrees that if Yoorz,Inc. or Yoorz,Inc. third party data providers (“Third Party Data Providers”) determine or reasonably suspects that Customer is engaging in commercial, marketing activities, or reselling or brokering the Yoorz,Inc. Services' information, programs, computer applications, or data, or is otherwise violating any provision of this Agreement, or any of the laws, regulations, or rules described herein, Yoorz,Inc.or Third Party Data Providers may take immediate action, including without limitation, legal action, terminating the delivery of, and the license to use, the Yoorz,Inc."
    ]
  },
  {
    title: "Services. ",
    text: [
      "Customer expressly acknowledges that Yoorz,Inc. Third Party Data  Provider’s shall be third party beneficiaries of this Agreement and shall have all rights to enforce the terms and conditions  of this Agreement against me. Customer shall not access the Yoorz,Inc. Services from Internet Protocol addresses  located outside of the United States and its territories. Customer may not use any of the data contain in the Yoorz,Inc.  Services to create a competing product. Customer shall comply with all laws, regulations and rules which may, in   Yoorz,Inc. opinion, govern the use of the Yoorz,Inc. services and information provided therein.",

      "(ii) Public Records Products Services. The Yoorz,Inc Services are not provided by “consumer reporting agencies,” as that term is defined in the federal Fair Credit Reporting Act, (15 U.S.C. 1681, et seq.), (the “FCRA”), and do not constitute Consumer Reports. Accordingly, the Yoorz,Inc. Services may not be used in whole or in part as a factor in determining eligibility for credit, insurance, employment or another purpose in connection with which a consumer report may be used under the FCRA. Further, Yoorz,Inc. certifies that it will not use any of the information it receives through the Yoorz,Inc.  Services to determine, in whole or in part an individual’s eligibility for any of the following products, services or transactions:",

      "(1) credit or insurance to be used primarily for personal, family or household purposes; (2) employment purposes; (3) a license or ",

      "(2) other benefit granted by a government agency; or (4) any other product, service or transaction in connection with",

      "(3)  which a consumer report may be used under the FCRA or any similar state statute, including without limitation apartment rental, check- cashing, or the opening of a deposit or transaction account."
    ]
  },
  {
    title: "2. SECURITY",
    text: [
      "Customer acknowledges that the information available through the Yoorz,Inc. Services may include personally, identifiable information and it is Customer’s obligation to keep all such accessed information confidential and secure. Customer shall purge all information received through the Yoorz,Inc. Services and stored electronically or on hard copy by Customer within ninety (90) days of initial receipt of such data. Additionally,  Yoorz, Inc. Products may not be used for any of the following purposes:",

      "a. To physically or emotionally injure or harm any person or entity (e.g. stalking, harassment, defaming, libeling, threatening, providing obscene or indecent materials, fraud, identity theft, violation of privacy rights, and criminal activity).",

      "b. To promote or provide instructional information about illegal activities or advance physical harm or injury against any group or individual.",

      "c. To use Yoorz, Inc. Products or information derived from  Yoorz, Inc. Products in combination with any purpose or personal information covered under such federal statutes as the FCRA, Gramm-Leach-Bliley Act, Health Insurance",

      "Portability and Accountability Act (HIPAA), Driver’s Privacy Protection Act, the Children’s Online Privacy Protection Act (COPPA), and/or all similar laws on the state level.",

      "d. To seek information about, locate, or harm individuals under the age of 18 years.",

      "e. To obtain personal information pertaining to famous people, government officials, or election candidates.",

      "f.  In connection with credit repair or credit counseling services.",

      "g. For marketing purposes or for commercial credit origination.",

      "h. To use or otherwise export or re-export Content in violation of the export control laws and regulations of the United States of America."
    ]
  },
  {
    title: "3. PERFORMANCE.",
    text: [
      'Customer accepts all information "AS IS." Customer acknowledges and agrees that Yoorz,Inc. obtains their data from third-party sources, which may or may not be completely thorough and accurate, and that Customer shall not rely on Yoorz,Inc. for the accuracy or completeness of information supplied through the Yoorz,Inc. Services. Customer understands that Customer may be restricted from accessing certain Yoorz,Inc. Services which may be otherwise available. Yoorz,Inc. reserves the right to add materials and features to, and to discontinue offering any of the materials and features that are currently a part of, the Yoorz,Inc. Services. If any entity within Yoorz, Inc. discontinues a material portion of the materials and features that Customer regularly uses in the ordinary course of its business, and such materials and features are part of a flat fee subscription plan to which Customer has subscribed, such Yoorz,Inc. entity will, at Customer’s option, terminate this Agreement.'
    ]
  },
  {
    title: "4. ACCESS.",
    text: [
      "Access by the Customer to  Yoorz, Inc. Products whether Mobile or Web is authorized as follows;",

      "a. Neither this Agreement nor the license granted to Customer may be assigned, transferred, or sub-licensed by Customer in whole or in part.",

      "b. Customer must not share their username and/or password.  Yoorz, Inc. Products are intended for use by Registered Customer only and may not be Delivered to, or Filed with, or Shared, with third parties.",

      "c. Customer will not access, retrieve any data from, or otherwise perform any other activities on or through Yoorz, Inc. Websites or Mobile Apps using any type of software or other automated process (e.g., scripts, robots, scrapers, crawlers, or spiders)."
    ]
  },
  {
    title: "5. WARRANTIES/LIMITATION OF LIABILITY.",
    text: [
      "Neither any entity of Yoorz,Inc., nor their subsidiaries and affiliates, nor any   Third Party Data provider (for purposes of indemnification, warranties, and limitations on liability, Yoorz,Inc, their   subsidiaries and affiliates, and their Third Party Data providers are hereby collectively referred to as “I Yoorz,Inc.”) shall  be liable to Customer (or to any person claiming through Customer to whom Customer may have provided data from the  Yoorz,Inc. Services) for any loss or injury arising out of or caused in whole or in part by Yoorz,Inc. acts or omissions  in procuring, compiling, collecting, interpreting, reporting, communicating, or delivering the Yoorz,Inc. Services."
    ]
  },
  {
    title: "6. INDEMNIFICATION.",
    text: [
      "Customer hereby agrees to protect, indemnify, defend, and hold harmless Yoorz, Inc. from and against any and all costs, claims, demands, damages, losses, and liabilities (including attorneys' fees and costs) arising from or in any way related to use of information received by Customer (or any third party receiving such information from or through Customer) furnished by or through Yoorz,Inc."
    ]
  },
  {
    title: "7. LIQUIDATED DAMAGES.",
    text: [
      "Customer agrees that if it improperly uses or discloses any of the data contained in the Yoorz,Inc. Services it would be impracticable or extremely difficult to fix the actual damages resulting to Yoorz,Inc. and its Third-Party Data Providers.  Therefore, Customer agrees to pay to the Yoorz,Inc. and/or its Third-Party Data Provider the sum of One Hundred Dollars ($100.00) per record contained in the Yoorz,Inc. Services that I improperly uses or disclose in violation of the terms and conditions contained in this Agreement."
    ]
  },
  {
    title: "8. AGREEMENT.",
    text: [
      "By submitting this page Customer is “signing” this Agreement, as such the Customer agrees that he or she has: (a) fully read the Terms Agreement in its entirety; (b) understands all of the terms and conditions of the Terms Agreement; (c) honestly and fully answered all questions asked of Yoorz,Inc.; and (d) agrees to be fully bound by the Terms Agreement."
    ]
  }
];

export default TERMS;
